import { ReactNode } from "react";
import { string } from "yup";
import { TCarbonComponentSizeProps } from "../../interfaces-and-types";
import { View } from "./View";

interface ISwitchAppContentSwitcherProps<T> {
  size?: TCarbonComponentSizeProps;
  options: { key: ReactNode; value: T }[];
  selectedValue: T;
  onChange: ({ index, name }: { index: number; name: T }) => void;
}

export function SwitchAppContentSwitcher<T = string>(
  props: ISwitchAppContentSwitcherProps<T>
) {
  const selectedIndex = props.options.findIndex(
    (el) => el.value === props.selectedValue
  );

  return <View {...props} selectedIndex={selectedIndex} />;
}
