import { useEffect } from "react";
import * as yup from "yup";

import { View } from "./View";
import {
  useApiErrors,
  useHookForm,
  useAdminIdentityMutation,
  useQueryString,
} from "../../hooks";
import { IResetPasswordRequest } from "../../interfaces-and-types";
import { getApiErrorsByFieldName, globalHandlerUtils } from "../../utils";
import { useNavigate } from "react-router-dom";
import { forgotPasswordConstants } from "../../constants";

export const ResetPassword = () => {
  const navigate = useNavigate();

  // get the form values from the url
  const query = useQueryString();
  const emailQueryString = query.get(
    forgotPasswordConstants.emailQueryStringName
  );
  const reset_password_tokenQueryString = query.get(
    forgotPasswordConstants.reset_password_tokenQueryStringName
  );

  const {
    isLoading,
    isSuccess,
    isError,
    data: apiResponse,
    mutate: resetPassword,
  } = useAdminIdentityMutation<IResetPasswordRequest, void>(
    "resetPassword"
  ).usePostMutation;

  useEffect(() => {
    if (isSuccess) {
      setTimeout(redirectToLogin, 4000);
    }
  }, [isSuccess]);

  const { apiErrors } = useApiErrors();

  const redirectToLogin = () => navigate("/login");

  const initialFormValues: IResetPasswordRequest = {
    email: "",
    reset_password_token: "",
    new_password: "",
    new_password_confirmation: "",
  };

  const confirmEmailSchema: yup.SchemaOf<IResetPasswordRequest> = yup
    .object({
      email: yup.string().email().required(),
      reset_password_token: yup.string().required(),
      new_password: yup.string().required(),
      new_password_confirmation: yup
        .string()
        .oneOf(
          [yup.ref("new_password"), null],
          "Password and confirmation must match"
        )
        .required("Password confirmation is required"),
    })
    .required();

  const handleApiSubmit = (data: IResetPasswordRequest) => {
    resetPassword(data);
  };

  const handleError = (errors: any) => globalHandlerUtils.handleError(errors);

  const { onFormSubmit, errors, getValues, register, reset } = useHookForm({
    initialFormValues,
    validationSchema: confirmEmailSchema,
    onValid: handleApiSubmit,
    onInvalid: handleError,
  });

  useEffect(() => {
    const allQueryStringValuesRetrieved =
      emailQueryString && reset_password_tokenQueryString;

    const allFormValuesAllInitialized =
      getValues(forgotPasswordConstants.emailQueryStringName) &&
      getValues(forgotPasswordConstants.reset_password_tokenQueryStringName);

    if (allQueryStringValuesRetrieved && !allFormValuesAllInitialized) {
      reset({
        email: emailQueryString,
        reset_password_token: reset_password_tokenQueryString,
      });
    }
  }, [emailQueryString, reset_password_tokenQueryString]);

  const errorMessage =
    getApiErrorsByFieldName(
      forgotPasswordConstants.emailQueryStringName,
      apiErrors
    ) ||
    getApiErrorsByFieldName(
      forgotPasswordConstants.reset_password_tokenQueryStringName,
      apiErrors
    ) ||
    "";

  return (
    <View
      passwordResetSuccessful={isSuccess}
      redirectToLogin={redirectToLogin}
      handleSubmit={onFormSubmit}
      register={register}
      errors={errors}
      errorMessage={errorMessage}
    />
  );
};
