import { View } from "./View";

export interface IErrorOccuredViewProps {
  message?: string;
  className?: string;
  noPadding?: boolean;
}

export const ErrorOccuredView = (props: IErrorOccuredViewProps) => {
  const { message = "An error occured...Please retry" } = props;

  return <View {...props} message={message} />;
};
