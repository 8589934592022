import { ISwitchAppIconButton } from "./SwitchAppIconButton";

export const View = ({
  children,
  className = "",
  onClick,
  ...rest
}: ISwitchAppIconButton) => {
  return (
    <button
      onClick={onClick}
      {...rest}
      className={`${className} switchapp-icon--button`}
    >
      {children}
    </button>
  );
};
