export enum subscriptionPeriod {
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly",
  every_3_months = "every3months",
  quarterly = "quarterly",
  every_6_months = "every6months",
  yearly = "yearly",
  custom = "custom",
}
