import { Button, InlineLoading } from "@carbon/react";

export const View = ({
  disabled = false,
  isLoading = false,
  children,
  className = "",
  ...rest
}) => {
  return (
    <Button
      disabled={disabled || isLoading}
      className={`switchapp-themed ${className}`}
      {...rest}
    >
      <div className="flex-container align-items--center">
        {!isLoading ? null : (
          <div>
            <InlineLoading description="" />
          </div>
        )}
        {children}
      </div>
    </Button>
  );
};
