import { ISwAppBalancesApi } from "../../interfaces-and-types";

type TBalancesUrls = {
  [key in keyof ISwAppBalancesApi]: string;
};

const rootBalancesUrl = "/balances";

export const balancesUrls: TBalancesUrls = {
  createBalance: `${rootBalancesUrl}`,
  getBalances: `${rootBalancesUrl}`,
  getBalanceById: `${rootBalancesUrl}/id`,
};
