import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  useHookForm,
  useAdminApiMutation,
  useReactRouterLink,
} from "../../../hooks";
import {
  ICreateInvitationCode,
  IFormWithTableProps,
  IHookForm,
  IModalFormWithTableProps,
  IViewInvitationCode,
} from "../../../interfaces-and-types";
import { globalHandlerUtils } from "../../../utils";

import { View } from "./View";
import { createInvitationCodeSchema } from "./createInvitationCodeSchema";

export interface ICreateInvitationCodeFormViewProps
  extends IHookForm<ICreateInvitationCode>,
    IModalFormWithTableProps {}

export const CreateInvitationCodeForm = (
  props: IFormWithTableProps & {
    goBackOnClose: boolean; // NOTE: used for on-the-fly product creation eg while creating a payment link
  }
) => {
  const { navigate, navigateToRoutePreserveParams } = useReactRouterLink();
  const location = useLocation();

  const closeModal = () => {
    if (props.goBackOnClose) navigate(-1);
    else navigateToRoutePreserveParams(location.pathname.replace("create", ""));
  };

  const { isSuccess: isSuccessSaveBankTransfer, mutate: saveBankTransfer } =
    useAdminApiMutation<ICreateInvitationCode, IViewInvitationCode>(
      "createInvitationCode",
      ["getInvitationCodes"]
    ).usePostMutation;

  useEffect(() => {
    if (isSuccessSaveBankTransfer) {
      reset(); // clear form
      props.refreshTable();

      closeModal();
    }
  }, [isSuccessSaveBankTransfer]);

  const initialFormValues: ICreateInvitationCode = {
    description: "",
  };

  const handleApiSubmit = (data: ICreateInvitationCode) => {
    saveBankTransfer(data);
  };

  const handleError = (errors: any) => globalHandlerUtils.handleError(errors);

  const { onFormSubmit, errors, register, reset } = useHookForm({
    initialFormValues,
    validationSchema: createInvitationCodeSchema,
    onValid: handleApiSubmit,
    onInvalid: handleError,
  });

  // const formValues = watch();

  return (
    <View
      {...props}
      isModalOpen
      closeModal={closeModal}
      // formValues={formValues}
      handleSubmit={onFormSubmit}
      register={register}
      errors={errors}
    />
  );
};
