import React from "react";
import { Badge } from "../../Badge";
import { CopyButton } from "../../CopyButton";
import { IModalTopSectionProps } from "./ModalTopSection";

export const View = (props: IModalTopSectionProps) => {
  return (
    <div className="modal-top-section flex-container col payment-details-modal">
      {/* <div className="flex-container justify-content--end">
        <svg
          onClick={props.closePaymentModal}
          className="closeIcon"
          id="close-circle"
          xmlns="http://www.w3.org/2000/svg"
          width="30.458"
          height="30.458"
          viewBox="0 0 30.458 30.458"
        >
          <path
            id="Vector"
            d="M25.382,12.691A12.691,12.691,0,1,1,12.691,0,12.691,12.691,0,0,1,25.382,12.691Z"
            transform="translate(2.538 2.538)"
            fill="#edebeb"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M5.885,4.54,8.8,1.621A.951.951,0,0,0,7.459.276L4.54,3.195,1.621.276A.951.951,0,0,0,.276,1.621L3.195,4.54.276,7.459a.958.958,0,0,0,0,1.345.95.95,0,0,0,1.345,0L4.54,5.885,7.459,8.8A.95.95,0,0,0,8.8,8.8a.958.958,0,0,0,0-1.345Z"
            transform="translate(10.689 10.689)"
            fill="#292d32"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0H30.458V30.458H0Z"
            fill="none"
            opacity="0"
          />
        </svg>
      </div> */}

      <div className="modal-top">
        <div className="flex-container row flex-break--small">
          <div className="flex-box">
            <div className="modal-header--title">{props.header}</div>
            <div className="modal-header--subtitle">{props.subHeader}</div>
          </div>

          {!props.copyText ? null : (
            <div className="flex-container col justify-content--end align-items--start">
              <div className="flex-container justify-content--start align-items--center">
                <div className="copy-button--container">
                  <CopyButton
                    className="copy-btn"
                    stringToCopy={props.copyContent}
                  />
                </div>
                <div className="transaction-id-text modal-header--title">
                  {props.copyText}
                </div>
              </div>
              <div className="float-down actual-transaction-id">
                {props.copyContent}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
