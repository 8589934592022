import {
  batchTransferStatus,
  invitationCodeStatus,
  paymentLinkStatus,
  productStatus,
  teamMemberStatus,
  transactionStatus,
  transferStatus,
} from "../enums";
import {
  IViewBatchTransfer,
  IViewPaymentLink,
  IViewProduct,
  IViewTeamMember,
  IViewTransactionListItem,
  IViewSingleTransfer,
  StatusColors,
  IViewInvitationCode,
} from "../interfaces-and-types";

export function getPaymentStatusIndicator(payment: IViewTransactionListItem) {
  const statusIndicator: { statusText: string; statusType: StatusColors } =
    payment.status === transactionStatus.failed
      ? { statusText: "failed", statusType: "danger" }
      : payment.status === transactionStatus.successful
      ? { statusText: "successful", statusType: "success" }
      : payment.status === transactionStatus.processing
      ? { statusText: "processing", statusType: "teal" }
      : payment.status === transactionStatus.abandoned
      ? { statusText: "abandoned", statusType: "secondary" }
      : { statusText: "refunded", statusType: "warning" };

  return statusIndicator;
}

export function getProductStatusIndicator(product: IViewProduct) {
  const statusIndicator: { statusText: string; statusType: StatusColors } =
    product.status === productStatus.active
      ? { statusText: "Active", statusType: "success" }
      : product.status === productStatus.staging
      ? { statusText: "Staging", statusType: "teal" }
      : { statusText: "Deactivated", statusType: "secondary" };

  return statusIndicator;
}

export function getPaymentLinkStatusIndicator(paymentLink: IViewPaymentLink) {
  const statusIndicator: { statusText: string; statusType: StatusColors } =
    paymentLink.status === paymentLinkStatus.active
      ? { statusText: "Active", statusType: "success" }
      : { statusText: "Deactivated", statusType: "secondary" };

  return statusIndicator;
}

export function getTeamMemberStatusIndicator(teamMember: IViewTeamMember) {
  const statusIndicator: { statusText: string; statusType: StatusColors } =
    teamMember.status === teamMemberStatus.active
      ? { statusText: "Active", statusType: "success" }
      : teamMember.status === teamMemberStatus.disabled
      ? { statusText: "Disabled", statusType: "danger" }
      : { statusText: "Pending", statusType: "secondary" };

  return statusIndicator;
}

export function getBatchTransferStatusIndicator(batch: IViewBatchTransfer) {
  const statusIndicator: { statusText: string; statusType: StatusColors } =
    batch.status === batchTransferStatus.allFailed ||
    batch.status === batchTransferStatus.otpDispatchFailed
      ? { statusText: "failed", statusType: "danger" }
      : batch.status === batchTransferStatus.allSuccessful
      ? { statusText: "successful", statusType: "success" }
      : batch.status === batchTransferStatus.partialSuccessful
      ? { statusText: "partial", statusType: "teal" }
      : { statusText: "processing", statusType: "secondary" };

  return statusIndicator;
}

export function getTransferStatusIndicator(transfer: IViewSingleTransfer) {
  const statusIndicator: { statusText: string; statusType: StatusColors } =
    transfer.status === transferStatus.failed
      ? { statusText: "failed", statusType: "danger" }
      : transfer.status === transferStatus.successful
      ? { statusText: "successful", statusType: "success" }
      : { statusText: "processing", statusType: "teal" };

  return statusIndicator;
}

export function getInvitationCodeStatusIndicator(
  invitationCode: IViewInvitationCode
) {
  const statusIndicator: { statusText: string; statusType: StatusColors } = {
    statusText: invitationCode.status,
    statusType:
      invitationCode.status === invitationCodeStatus.active
        ? "success"
        : invitationCode.status === invitationCodeStatus.used
        ? "secondary"
        : "danger",
  };

  return statusIndicator;
}
