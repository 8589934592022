import { Toggle } from "../Toggle";
import { IAccountModeSwitcherViewProps } from "./AccountModeSwitcher";

export const View = (props: IAccountModeSwitcherViewProps) => {
  return (
    <div className="account-mode--switcher">
      <Toggle
        name="accountModeSwitcher"
        label=""
        labelOff={
          <>
            <span
              title="You are in TEST mode. Click to turn on live mode"
              className="account-mode--test font-ctm--bold"
            >
              Test
            </span>{" "}
            Mode View
          </>
        }
        labelOn={
          <>
            <span
              title="You are in LIVE mode. Click to turn on test mode"
              className="account-mode--live font-ctm--bold"
            >
              Live
            </span>{" "}
            Mode View
          </>
        }
        size="sm"
        toggled={props.is_liveMode}
        onToggle={props.toggleAccountMode}
      />
    </div>
  );
};
