import { ISwAppCustomersApi } from "../../interfaces-and-types";

type TCustomerUrls = { [key in keyof ISwAppCustomersApi]: string };

const rootCustomerUrl = "/customers";

export const customerUrls: TCustomerUrls = {
  createCustomer: `${rootCustomerUrl}`,
  getCustomers: `${rootCustomerUrl}`,
  getCustomerById: `${rootCustomerUrl}/id`,
  editCustomer: `${rootCustomerUrl}/id`,
  deleteCustomer: `${rootCustomerUrl}/id`,
  blacklistCustomer: `${rootCustomerUrl}/blacklist`,
};
