import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState, useMemo } from "react";

import { loggedOutAuthState } from "../contexts";
import {
  IAuthState,
  IAppUser,
  IAppUserSettings,
} from "../interfaces-and-types";
import { useSwitchappSecureStore } from "./useSwitchappSecureStore";
import { appConfig } from "../configs";

export function useRehydrateEncryptedStorageData() {
  const {
    getAsync,
    setAsync,
    getEncryptedAsync,
    setEncryptedAsync,
    removeAsync,
  } = useSwitchappSecureStore(appConfig.APP_DATA_STORAGE_KEY);

  const [authState, setAuthState] = useState<IAuthState>(loggedOutAuthState);

  const queryClient = useQueryClient();

  // use effect to get authState from Encrypted Storage on app start
  useEffect(() => {
    const retrieveAuthState = async () => {
      try {
        const authStateString = await getEncryptedAsync();
        // const authStateString = await getAsync();
        const rehydratedAuthState =
          authStateString && JSON.parse(authStateString);

        // console.log(
        //   "rehydratedAuthState To SET in AuthContext: ",
        //   rehydratedAuthState
        // );

        rehydratedAuthState && setAuthState(rehydratedAuthState);
      } catch (error) {
        // console.error(error);
      }
    };

    retrieveAuthState();
  }, []);

  const persistAuthState = async (authStateToSet: IAuthState) => {
    try {
      await setEncryptedAsync(JSON.stringify(authStateToSet));
      // await setAsync(JSON.stringify(authStateToSet));
    } catch (error) {
      console.error(error);
    }
  };

  const memoedAuthContext = useMemo(() => {
    return {
      ...authState,

      signIn: (
        // staffDepartments: IStaffDepartment[],
        token: string,
        user: IAppUser
      ) => {
        setAuthState((prevAuthState) => {
          const newAuthState: IAuthState = {
            ...prevAuthState,
            is_logged_in: true,
            token,
            user,
          };

          persistAuthState(newAuthState);

          return newAuthState;
        });
      },
      signOut: () => {
        removeAsync();

        // reset queries
        queryClient.clear();
        queryClient.invalidateQueries();

        setAuthState((prevAuthState) => ({
          ...prevAuthState,
          is_logged_in: false,
          token: null,
          activeTenantId: null,
        }));
      },

      setAuthenticationState: (newAuthState: IAuthState) => {
        // console.log("newAuthState: ", newAuthState);
        setAuthState(newAuthState);
      },

      setToken: (token: string) =>
        setAuthState((prevAuthState) => ({
          ...prevAuthState,
          token,
        })),

      // setActiveTenantId: (active_tenant_id: string) => {
      //   setAuthState((prevAuthState) => {
      //     const newAuthState: IAuthState = {
      //       ...prevAuthState,

      //       active_tenant_id,
      //     };

      //     persistAuthState(newAuthState);

      //     // clear all queries and refetch
      //     queryClient.clear();
      //     queryClient.invalidateQueries();

      //     return newAuthState;
      //   });
      // },

      setUser: (user: IAppUser) => {
        setAuthState((prevState) => ({ ...prevState, user }));
      },

      setUserSettings: (userSettings: IAppUserSettings) =>
        setAuthState((prevState) => ({ ...prevState, userSettings })),
    };
  }, [authState, queryClient, persistAuthState]);

  return memoedAuthContext; // unmemoedAuthContext; // memoedAuthContext;
}
