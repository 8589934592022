import { SwitchAppButton } from "../SwitchAppButton";

export const ErrorOccuredTableDisplay = () => {
  const reloadWindow = () => {
    window.location.reload();
  };

  return (
    <div className="empty-table-display--container flex-container col justify-content--center align-items--center">
      <span className="text-danger mb-5">
        Error loading data. Please reload the page.
      </span>
      <SwitchAppButton className="mt-5" size="sm" onClick={reloadWindow}>
        Reload Page
      </SwitchAppButton>
    </div>
  );
};
