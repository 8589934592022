import { ICardProps } from "./Card";

export const View = (props: ICardProps) => {
  return (
    <div className={`card-container ${props.className}`}>
      <div className="card-header">{props.header}</div>
      <div className={`card-content ${props.contentContainerClassName}`}>
        {props.children}
      </div>
      <div className="card-footer">{props.footer}</div>
    </div>
  );
};
