import { ReactNode } from "react";
import { TCarbonComponentSizeProps } from "../../interfaces-and-types";

import { View } from "./View";

export interface ISwitchAppIconButton {
  onClick: () => void;
  children: ReactNode;
  disabled?: boolean;
  className?: string;
  kind?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "success"
    | "danger"
    | "warning";
  size?: TCarbonComponentSizeProps;
}

export const SwitchAppIconButton = (props: ISwitchAppIconButton) => {
  return <View {...props} />;
};
