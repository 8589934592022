import { ReactNode } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

interface IProtectedRouterProps {
  isAuthenticated: boolean;
  protectedRoutes: ReactNode;
  unprotectedRoutes?: ReactNode;
  redirectTo?: string;
}

export const ProtectedRouter = (props: IProtectedRouterProps) => {
  const { isAuthenticated, protectedRoutes, unprotectedRoutes, redirectTo } =
    props;

  return (
    <Routes>
      {isAuthenticated
        ? protectedRoutes
        : // ) : redirectTo ? (
          //   <Route path="*" element={<Navigate replace to={redirectTo} />} />
          unprotectedRoutes}
    </Routes>
  );
};
