import { ReactNode } from "react";
import { View } from "./View";

export interface ISectionSubheaderProps {
  title: ReactNode;
  subtitle?: string;
  infoIconDescription?: string;
  className?: string;
}

export const SectionSubheader = (props: ISectionSubheaderProps) => {
  return <View {...props} />;
};
