import { ReactNode } from "react";

import { View } from "./View";

export interface ILinkWithIconProps {
  iconLeft: ReactNode;
  iconRight?: ReactNode;
  title: ReactNode;
  subtitle?: ReactNode;
  hideDivider?: boolean;
  urlTarget: string;
  asButton?: boolean;
  disabled?: boolean;
  onButtonClick?: () => void;
  containerClassName?: string;
}

export const LinkWithIcon = (props: ILinkWithIconProps) => {
  return <View {...props} />;
};
