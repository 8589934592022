import moment from "moment";

import { Badge, CurrencyDisplay } from "../../components";
import { appConfig } from "../../configs";
import {
  IViewTransactionListItem,
  StatusColors,
} from "../../interfaces-and-types";
import { getPaymentStatusIndicator } from "../../utils";
import { transactionStatus } from "../../enums";

export const paymentDisplayFormatter = (item: IViewTransactionListItem) => {
  const statusIndicator: { statusText: string; statusType: StatusColors } =
    getPaymentStatusIndicator(item);

  const getCorrectMonnifyAppFee = () =>
    !item.internal__p_gw_app_fee
      ? 0
      : item.internal__p_gw_app_fee >= item.amount &&
        item.gateway_code === "monnify"
      ? Math.min(0.0165 * item.amount, 322.5)
      : item.internal__p_gw_app_fee;

  return {
    ...item,
    amount: (
      <CurrencyDisplay currencyOrSymbol={item.currency} amount={item.amount} />
    ),
    charged_amount: (
      <CurrencyDisplay
        currencyOrSymbol={item.currency}
        amount={item.charged_amount}
      />
    ),
    app_fee: (
      <CurrencyDisplay currencyOrSymbol={item.currency} amount={item.app_fee} />
    ),
    internal__p_gw_app_fee: !item.internal__p_gw_app_fee ? null : (
      <CurrencyDisplay
        currencyOrSymbol={item.currency}
        // amount={item.internal__p_gw_app_fee}
        amount={getCorrectMonnifyAppFee()}
      />
    ),
    status: (
      <Badge
        text={statusIndicator.statusText}
        statusColor={statusIndicator.statusType}
      />
    ),
    customer: item.customer.email,
    gateway: !item.gateway_code ? (
      "-"
    ) : (
      <img
        src={`${appConfig.merchantDashboardBaseUrl}/gateway-logos/${item.gateway_code}.svg`}
        alt="gateway logo"
        className="gateway-logo-full"
      />
    ),
    date: (
      <span className="date--oneline">
        {moment(
          item.status === transactionStatus.successful ||
            item.status === transactionStatus.partial
            ? item.paid_at
            : item.created_at
        ).format(appConfig.dateTimeFormat)}
      </span>
    ),
    // more: <OverflowMenuDropDown />,
  };
};
