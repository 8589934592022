import { Add, ColumnDelete, TrashCan } from "@carbon/icons-react";

import {
  ModalContainer,
  SectionHeader,
  StructuredList,
  SwitchAppButton,
} from "../../../../components";
import { SubmitButton } from "../../../../hook-form";
import { IManageSpecialPricingFormViewProps } from "./ManageSpecialPricing";
import { ManageVolumePricingEntry } from "./ManageVolumePricingEntry";

import { TableHeaderForVolumePricingOption } from "../TableHeaderForVolumePricingOption";
import {
  orderByVolumePricingThresholdParams,
  truncateStringWithEllipsis,
} from "../../../../utils";
import { EditPricingOptionForm } from "./EditPricingOptionForm";
import { Link, Route, Routes } from "react-router-dom";
import { AddVolumePricingOptionForm } from "./AddVolumePricingOptionForm/AddVolumePricingOptionForm";

export const View = ({
  isModalOpen,
  isLoadingModalData,
  isErrorModalData,
  closeModal,

  merchant,
  specialPricing,
  // navigateToAddSpecialVolumePricingOption,
  isLoadingDeleteSpecialPricing,
  deleteSpecialPricing,

  //  form mgmt
  formValues,
  setValue,

  errors,
  handleSubmit,
  register,
}: IManageSpecialPricingFormViewProps) => {
  return (
    <>
      <form onSubmit={handleSubmit} className="manage-special-pricing--form">
        <ModalContainer
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          fullscreen
          modalHeader={
            isLoadingModalData || !merchant || !specialPricing ? (
              <>Loading Details...</>
            ) : (
              <SectionHeader
                title={
                  <div className="flex-container row justify-content--between">
                    <span className="font-ctm--bold">
                      SPECIAL PRICING ({specialPricing.country})
                    </span>

                    <div>
                      <SwitchAppButton
                        size="sm"
                        className="mr-10"
                        kind="danger"
                        isLoading={isLoadingDeleteSpecialPricing}
                        onClick={deleteSpecialPricing}
                      >
                        <TrashCan size="20" />
                        DELETE SPECIAL PRICING
                      </SwitchAppButton>
                    </div>
                  </div>
                }
                subtitle={
                  <>
                    <span className="font-ctm--bold">
                      {truncateStringWithEllipsis(merchant.merchant_name, 50)}
                    </span>
                  </>
                }
                // subtitle="Set exactly how much a merchant is charged."
                renderRight={
                  <>
                    <SubmitButton
                      size="sm"
                      className="ml-10"
                      showChildrenWithLoadingIcon
                    >
                      <ColumnDelete size="20" className="mr-5" />
                      Save Changes
                    </SubmitButton>
                  </>
                }
              />
            )
          }
        >
          {isLoadingModalData || !merchant || !specialPricing ? (
            <>Loading Special Pricing Details...</>
          ) : (
            <>
              <div className="pricing-subsection--container payments-pricing--subsection">
                <EditPricingOptionForm
                  pricingOptionType="PAYMENT"
                  defaultPricingOptionKey="payment_default_pricing_option"
                  isVolumePricingEnabledKey="is_payment_volume_pricing_enabled"
                  formValues={formValues}
                  setValue={setValue}
                  errors={errors}
                  handleSubmit={handleSubmit}
                  register={register}
                />
                {/* Payments */}
                {!formValues.is_payment_volume_pricing_enabled ? null : (
                  <div className="volume-pricing--subsection">
                    <h5 className="pricing-subsection--header font-ctm--semibold pt-5 flex-container row justify-content--between">
                      <span>PAYMENT VOLUME PRICING</span>
                      <Link
                        to={`add-payment-volume-pricing`}
                        className="switchapp-themed  cds--btn cds--btn--sm cds--btn--success mr-5"
                      >
                        <Add size="20" />
                        Add
                      </Link>
                    </h5>

                    <StructuredList
                      headers={<TableHeaderForVolumePricingOption />}
                      structuredListBodyClassName="text--right font-ctm--semibold"
                      rows={orderByVolumePricingThresholdParams(
                        specialPricing?.payment_volume_pricing_options || []
                      ).map((volOption, index) => (
                        <ManageVolumePricingEntry
                          key={volOption.id}
                          index={index}
                          merchantId={merchant!.id}
                          pricingId={specialPricing!.id}
                          volumePricingOption={volOption}
                          volumePricingType="payment"
                        />
                      ))}
                    />
                  </div>
                )}
              </div>

              {/* Top-Ups */}
              <div className="pricing-subsection--container topups-pricing--subsection">
                <EditPricingOptionForm
                  pricingOptionType="TOP-UP"
                  defaultPricingOptionKey="top_up_default_pricing_option"
                  isVolumePricingEnabledKey="is_top_up_volume_pricing_enabled"
                  formValues={formValues}
                  setValue={setValue}
                  errors={errors}
                  handleSubmit={handleSubmit}
                  register={register}
                />

                {!formValues.is_top_up_volume_pricing_enabled ? null : (
                  <div className="volume-pricing--subsection">
                    <h5 className="pricing-subsection--header font-ctm--semibold pt-5 flex-container row justify-content--between">
                      <span>TOP-UPS VOLUME PRICING</span>
                      <Link
                        to={`add-topup-volume-pricing`}
                        className="switchapp-themed  cds--btn cds--btn--sm cds--btn--success mr-5"
                      >
                        <Add size="20" />
                        Add
                      </Link>
                    </h5>

                    <StructuredList
                      headers={<TableHeaderForVolumePricingOption />}
                      structuredListBodyClassName="text--right font-ctm--semibold"
                      rows={orderByVolumePricingThresholdParams(
                        specialPricing?.top_up_volume_pricing_options || []
                      ).map((volOption, index) => (
                        <ManageVolumePricingEntry
                          key={volOption.id}
                          index={index}
                          merchantId={merchant!.id}
                          pricingId={specialPricing!.id}
                          volumePricingOption={volOption}
                          volumePricingType="topup"
                        />
                      ))}
                    />
                  </div>
                )}
              </div>

              {/* Transfers */}
              <div className="pricing-subsection--container transfers-pricing--subsection">
                <EditPricingOptionForm
                  pricingOptionType="TRANSFER"
                  defaultPricingOptionKey="transfer_default_pricing_option"
                  isVolumePricingEnabledKey="is_transfer_volume_pricing_enabled"
                  formValues={formValues}
                  setValue={setValue}
                  errors={errors}
                  handleSubmit={handleSubmit}
                  register={register}
                />

                {!formValues.is_transfer_volume_pricing_enabled ? null : (
                  <div className="volume-pricing--subsection">
                    <h5 className="pricing-subsection--header font-ctm--semibold pt-5 flex-container row justify-content--between">
                      <span>TRANSFERS VOLUME PRICING</span>
                      <Link
                        to={`add-transfer-volume-pricing`}
                        className="switchapp-themed  cds--btn cds--btn--sm cds--btn--success mr-5"
                      >
                        <Add size="20" />
                        Add
                      </Link>
                    </h5>

                    <StructuredList
                      headers={<TableHeaderForVolumePricingOption />}
                      structuredListBodyClassName="text--right font-ctm--semibold"
                      rows={orderByVolumePricingThresholdParams(
                        specialPricing?.transfer_volume_pricing_options || []
                      ).map((volOption, index) => (
                        <ManageVolumePricingEntry
                          key={volOption.id}
                          index={index}
                          merchantId={merchant!.id}
                          pricingId={specialPricing!.id}
                          volumePricingOption={volOption}
                          volumePricingType="transfer"
                        />
                      ))}
                    />
                  </div>
                )}
              </div>

              {/* Payouts */}
              <div className="pricing-subsection--container payouts-pricing--subsection">
                <EditPricingOptionForm
                  pricingOptionType="PAYOUT"
                  defaultPricingOptionKey="payout_default_pricing_option"
                  isVolumePricingEnabledKey="is_payout_volume_pricing_enabled"
                  formValues={formValues}
                  setValue={setValue}
                  errors={errors}
                  handleSubmit={handleSubmit}
                  register={register}
                />

                {!formValues.is_payout_volume_pricing_enabled ? null : (
                  <div className="volume-pricing--subsection">
                    <h5 className="pricing-subsection--header font-ctm--semibold pt-5 flex-container row justify-content--between">
                      <span>PAYOUT VOLUME PRICING</span>
                      <Link
                        to={`add-payout-volume-pricing`}
                        className="switchapp-themed  cds--btn cds--btn--sm cds--btn--success mr-5"
                      >
                        <Add size="20" />
                        Add
                      </Link>
                    </h5>

                    <StructuredList
                      headers={<TableHeaderForVolumePricingOption />}
                      structuredListBodyClassName="text--right font-ctm--semibold"
                      rows={orderByVolumePricingThresholdParams(
                        specialPricing?.payout_volume_pricing_options || []
                      ).map((volOption, index) => (
                        <ManageVolumePricingEntry
                          key={volOption.id}
                          index={index}
                          merchantId={merchant!.id}
                          pricingId={specialPricing!.id}
                          volumePricingOption={volOption}
                          volumePricingType="payout"
                        />
                      ))}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </ModalContainer>
      </form>

      {/* Render outside of main form to prevent submitting main form */}
      {!(merchant && specialPricing) ? null : (
        <Routes>
          <Route
            path="add-payment-volume-pricing"
            element={
              <AddVolumePricingOptionForm
                merchantId={merchant.id}
                pricingId={specialPricing.id}
                volumePricingType="payment"
              />
            }
          />

          <Route
            path="add-payout-volume-pricing"
            element={
              <AddVolumePricingOptionForm
                merchantId={merchant.id}
                pricingId={specialPricing.id}
                volumePricingType="payout"
              />
            }
          />

          <Route
            path="add-topup-volume-pricing"
            element={
              <AddVolumePricingOptionForm
                merchantId={merchant.id}
                pricingId={specialPricing.id}
                volumePricingType="topup"
              />
            }
          />

          <Route
            path="add-transfer-volume-pricing"
            element={
              <AddVolumePricingOptionForm
                merchantId={merchant.id}
                pricingId={specialPricing.id}
                volumePricingType="transfer"
              />
            }
          />
        </Routes>
      )}
    </>
  );
};
