import {
  StatusColors,
  TCarbonComponentSizeProps,
} from "../../interfaces-and-types";

import { View } from "./View";

export interface IBadgeProps {
  statusColor: StatusColors;
  indicatorColor?: StatusColors;
  hideIndicator?: boolean;
  indicatorSize?: TCarbonComponentSizeProps;
  text?: string;
  largeFont?: boolean;
  className?: string;
}

export const Badge = (props: IBadgeProps) => {
  return <View {...props} />;
};
