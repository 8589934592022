import { ReactNode } from "react";
import {
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import { useApiErrors } from "../../hooks";
import { TCarbonComponentSizeProps } from "../../interfaces-and-types";
import { getApiErrorsByFieldName } from "../../utils";

import { View } from "./View";

interface ICheckboxProps {
  name: string;
  label: string;
  helperText?: string;
  infoIconText?: ReactNode;
  containerClassName?: string;
  className?: string;
  disabled?: boolean;
  size?: TCarbonComponentSizeProps;
  placeholder?: string;

  // controlled form props
  // value?: string | number | null;
  // onChange?: (value: any) => void;
}

interface IHookFormCheckboxProps<TFormValues extends FieldValues>
  extends ICheckboxProps {
  register: UseFormRegister<TFormValues>;
  errors: FieldErrorsImpl<DeepRequired<TFormValues>>;
}

export function HookFormCheckbox<TFormValues extends FieldValues>(
  props: IHookFormCheckboxProps<TFormValues>
) {
  const { errors, ...rest } = props;

  const { apiErrors } = useApiErrors();

  const apiErrorMessage = getApiErrorsByFieldName(props.name, apiErrors);
  const hookFormErrorMessage =
    errors[props.name] && errors[props.name]?.["message"];

  const combinedErrors = hookFormErrorMessage || apiErrorMessage || "";

  return <View errorMessage={combinedErrors} {...rest} />;
}
