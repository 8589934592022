import { useState } from "react";
import { TSummaryPeriod } from "../../../constants";
import { useAdminApiQueryWithParams } from "../../../hooks";
import {
  IMerchantsOverview,
  IMerchantsSummary,
} from "../../../interfaces-and-types";
import { View } from "./View";
import { ISummarySectionProps, ISummarySectionViewProps } from "../Home";

export interface IMerchantsSummaryViewProps
  extends ISummarySectionViewProps<IMerchantsOverview> {}

export const MerchantsSummary = ({ activeCurrency }: ISummarySectionProps) => {
  const {
    isLoading: isLoadingMerchantsSummary,
    // isSuccess: isSuccessMerchantsSummary,
    // isError: isErrorMerchantsSummary,
    data: merchantsSummary,
  } = useAdminApiQueryWithParams<{}, IMerchantsSummary>(
    ["getMerchantsSummary", {}],
    {}
  );

  const isLoadingCustomPeriodSummary = false; // update after adding fetch logic for custom

  const [summaryPeriod, setSummaryPeriod] = useState<TSummaryPeriod>("today");

  const summaryToDisplay =
    summaryPeriod === "today"
      ? merchantsSummary?.today
      : summaryPeriod === "past week"
      ? merchantsSummary?.past_week
      : summaryPeriod === "past month"
      ? merchantsSummary?.past_month
      : // summaryPeriod === "custom" ? customPeriodTransactionsSummary :
        merchantsSummary?.all_time;

  const isLoadingSummary =
    summaryPeriod === "custom"
      ? isLoadingCustomPeriodSummary
      : isLoadingMerchantsSummary;

  return (
    <View
      activeCurrency={activeCurrency}
      isLoadingSummary={isLoadingSummary}
      summaryToDisplay={summaryToDisplay || null}
      summaryPeriod={summaryPeriod}
      setSummaryPeriod={setSummaryPeriod}
    />
  );
};
