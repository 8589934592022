import {
  ModalContainer,
  SectionHeader,
  SectionSubheader,
  SwitchAppButton,
} from "../../../components";
import { SubmitButton, TextArea, TextInput } from "../../../hook-form";
import { ICreateInvitationCodeFormViewProps } from "./CreateInvitationCodeForm";

export const View = ({
  isModalOpen,
  closeModal,

  // formValues,
  control,
  errors,
  handleSubmit,
  register,
}: ICreateInvitationCodeFormViewProps) => {
  return (
    <ModalContainer
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      fullscreen
      modalHeader={
        <SectionHeader
          title="Generate Invitation Code "
          subtitle="Invite a merchant to create a SwitchApp account."
        />
      }
    >
      <form onSubmit={handleSubmit}>
        <div className="flex-container flex-break--small">
          <div className="flex-box">
            {/* <TextArea
              name="description"
              label="Description (Required)"
              infoIconText="A description of the merchant or target of the invitation"
              register={register}
              errors={errors}
            /> */}
            <TextInput
              name="description"
              label="Description (Required)"
              infoIconText="A description of the merchant or target of the"
              type="text"
              register={register}
              errors={errors}
            />
          </div>
          {/* <div className="flex-box mr-md-5">xxxxxxxxxxxx</div> */}
        </div>
        <div className="flex-container justify-content--end mt-5">
          <div
            id="md_alignment_helper"
            className="flex-box flex-1_5 d-none d-md-flex"
          />
          <div className="flex-box flex-container">
            <SwitchAppButton kind="secondary" onClick={closeModal} size="sm">
              Cancel
            </SwitchAppButton>

            <SubmitButton size="sm" className="ml-5">
              Create
            </SubmitButton>
          </div>
        </div>
      </form>
    </ModalContainer>
  );
};
