import { Route, Routes } from "react-router-dom";
import { Settings } from "../../pages";

export const SettingsLayout = () => {
  return (
    <Routes>
      <Route index element={<Settings />} />
    </Routes>
  );
};
