import { ISwAppDashboardApi } from "../../interfaces-and-types";

type TDashboardUrls = { [key in keyof ISwAppDashboardApi]: string };

const rootDashboardUrl = "/dashboard";

export const dashboardUrls: TDashboardUrls = {
  getCustomersSummary: `${rootDashboardUrl}/customers-summary`,
  getMerchantsSummary: `${rootDashboardUrl}/merchants-summary`,
  getTransactionsSummary: `${rootDashboardUrl}/transactions-summary`,
  getTopUpsSummary: `${rootDashboardUrl}/topups-summary`,
  getPayoutsSummary: `${rootDashboardUrl}/payouts-summary`,
  getRefundsSummary: `${rootDashboardUrl}/refunds-summary`,
  getTransfersSummary: `${rootDashboardUrl}/transfers-summary`,
  getCustomersByPeriod: `${rootDashboardUrl}/customers-by-period`,
  getTodaysTransactions: `${rootDashboardUrl}/txs-today`,
  getTransactionsByPeriod: `${rootDashboardUrl}/txs-by-period`,
  getTransactionsByGatewayByPeriod: `${rootDashboardUrl}/txs-by-gateway`,
  // xxxxxx: `${rootDashboardUrl}/xxxxxxxxx`,
  // xxxxxx: `${rootDashboardUrl}/xxxxxxxxx`,
  // xxxxxx: `${rootDashboardUrl}/xxxxxxxxx`,

  // set dashboard preferences
  // saveOnboardingStep1: `${rootDashboardUrl}/step1`,
  // saveOnboardingStep2: `${rootDashboardUrl}/step2`,
};
