import { IPageTabNavLink } from "../interfaces-and-types";

export const transactionsNavLinks: IPageTabNavLink[] = [
  {
    name: "Transactions",
    route: "",
  },
  // {
  //   name: "Top-Ups",
  //   route: "top-ups",
  // },
];
