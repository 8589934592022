import { AesUtils } from "../utils/crypto-utils";

export const useSwitchappSecureStore = (storageKey: string) => {
  const storageKeyFormatted = storageKey.toUpperCase();
  const encryptedStorageDataKey = `${storageKeyFormatted}_DXTD`;

  const getAsync = async (): Promise<string | null> => {
    return new Promise(async (resolve, reject) => {
      try {
        let value = await sessionStorage.getItem(storageKeyFormatted);

        // console.log("retrieved Data: ", value);

        resolve(value);
      } catch (e) {
        reject(e);
      }
    });
  };

  const setAsync = async (value: string): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        await sessionStorage.setItem(storageKeyFormatted, value);

        resolve();
      } catch (e) {
        reject(e);
      }
    });
  };

  const getEncryptedAsync = async (): Promise<string | null> => {
    return new Promise(async (resolve, reject) => {
      try {
        let value = null;
        const aesKey = await sessionStorage.getItem(storageKeyFormatted);

        if (aesKey) {
          const encryptedAsyncStorageDataString = await sessionStorage.getItem(
            encryptedStorageDataKey
          );

          if (encryptedAsyncStorageDataString) {
            value = AesUtils.decrypt(encryptedAsyncStorageDataString, aesKey);
          }
        }

        // console.log("aesKey: ", aesKey);
        // console.log("dcrypted Data: ", value);

        resolve(value);
      } catch (e) {
        reject(e);
      }
    });
  };

  const setEncryptedAsync = async (value: string): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        const existingAesKey = await sessionStorage.getItem(
          storageKeyFormatted
        );

        const { encryptionKey, encryptedData } = !existingAesKey
          ? AesUtils.encryptWithRandomKey(value)
          : AesUtils.encryptWithKey(value, existingAesKey);

        // console.log("encryptionKey: ", encryptionKey);
        // console.log("encryptedData: ", encryptedData);

        await sessionStorage.setItem(storageKeyFormatted, encryptionKey);
        await sessionStorage.setItem(encryptedStorageDataKey, encryptedData);

        resolve();
      } catch (e) {
        reject(e);
      }
    });
  };

  const removeAsync = async (): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        await sessionStorage.removeItem(storageKeyFormatted);

        resolve();
      } catch (e) {
        reject(e);
      }
    });
  };

  return {
    getAsync,
    setAsync,
    getEncryptedAsync,
    setEncryptedAsync,
    removeAsync,
  };
};
