import { forwardRef, ReactNode, RefObject } from "react";
import { TCarbonComponentSizeProps } from "../../interfaces-and-types";
import { View } from "./View";

export interface ISearchBarProps {
  name: string;
  value?: ReactNode | null; // this should remain uncontrolled when its value is not being set directly.
  label?: string | null;
  className?: string | null;
  onChange: (value: string) => void;
  onClear: () => void;
  // autoComplete?: "off" | "";
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  renderIcon?: ReactNode;
  size?: TCarbonComponentSizeProps;
  // type?: "text" | "";

  onFocus?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onBlur?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
}

export const SearchBar = forwardRef((props: ISearchBarProps, ref) => {
  return <View ref={ref} label={props.label} {...props} />;
});
