import { ReactNode } from "react";
import { View } from "./View";

export interface TopDownFlexedProps {
  key_id: ReactNode;
  value: ReactNode;
}

export const TopDownFlexedContainer = (props: TopDownFlexedProps) => {
  return <View {...props} />;
};
