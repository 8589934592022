import { useMutation, useQueryClient } from "@tanstack/react-query";

import { axiosBaseConfig } from "../../../configs";
import { authUrls, adminIdentityApiBaseUrl } from "../../../constants";
import { ISwAppAdminAuthApi } from "../../../interfaces-and-types";
import { logDataToConsole } from "../../../utils";
import { useActivityBlocker } from "../../useActivityBlocker";
import {
  ISwitchappAxiosClientConfig,
  useSwitchappAxiosClient,
} from "../useSwitchappAxiosClient";

// type to enable muxing post, put, patch, and delete mutations for the template
// type TMutationFn<TRequestData, TResponseData> =
//   | ((id?: string, data?: TRequestData | null) => Promise<TResponseData>)
//   | ((data?: TRequestData | null) => Promise<TResponseData>);

export function useAdminIdentityMutation<TRequestData, TResponseData>(
  queryKey: keyof ISwAppAdminAuthApi,
  refetchQueryKeys: (keyof ISwAppAdminAuthApi)[] | null = null,
  axiosConfig: ISwitchappAxiosClientConfig<TRequestData> | undefined = undefined // to set multipart/form-data
) {
  const baseURL = adminIdentityApiBaseUrl;
  const axiosClient = useSwitchappAxiosClient({
    baseURL,
    ...axiosBaseConfig,
    ...axiosConfig,
  });

  const queryClient = useQueryClient();
  const { clearBlocked, setBlocked } = useActivityBlocker();

  function postMutation(data: TRequestData | null = null) {
    const url = authUrls[queryKey];

    return axiosClient
      .post<TResponseData>(url, data)
      .then((response) => response.data); // react query catches and handles our errors
  }

  function putMutation(id: string, data: TRequestData | null = null) {
    const url = `${authUrls[queryKey]}/${id}`;
    return axiosClient
      .put<TResponseData>(url, data)
      .then((response) => response.data); // react query catches and handles our errors
  }

  function deleteMutation(id: string) {
    const url = `${authUrls[queryKey]}/${id}`;
    return axiosClient
      .delete<TResponseData>(url)
      .then((response) => response.data); // react query catches and handles our errors
  }

  const usePostMutation = useMutation([queryKey], postMutation, {
    onSuccess: (data) => {
      clearBlocked();
      // logDataToConsole(data, "useSwitchAppMutation onSuccess data");

      // invalidate all queries linked to this mutation
      refetchQueryKeys &&
        refetchQueryKeys.length > 0 &&
        refetchQueryKeys.map((qK) => queryClient.invalidateQueries([qK]));
    },
    onError: (errorMessage) => {
      clearBlocked();
      // logDataToConsole(
      //   errorMessage,
      //   "useSwitchAppMutation onError errorMessage"
      // );
    },
    onMutate: (data) => {
      setBlocked();
    },
  });

  const usePutMutation = useMutation([queryKey], putMutation, {
    onSuccess: (data) => {
      clearBlocked();
      logDataToConsole(data, "useSwitchAppMutation onSuccess data");

      // invalidate all queries linked to this mutation
      refetchQueryKeys &&
        refetchQueryKeys.length > 0 &&
        refetchQueryKeys.map((qK) => queryClient.invalidateQueries([qK]));
    },
    onError: (errorMessage) => {
      clearBlocked();
      logDataToConsole(
        errorMessage,
        "useSwitchAppMutation onError errorMessage"
      );
    },
    onMutate: (data) => {
      setBlocked();
    },
  });

  const useDeleteMutation = useMutation([queryKey], deleteMutation, {
    onSuccess: (data) => {
      clearBlocked();
      logDataToConsole(data, "useSwitchAppMutation onSuccess data");

      // invalidate all queries linked to this mutation
      refetchQueryKeys &&
        refetchQueryKeys.length > 0 &&
        refetchQueryKeys.map((qK) => queryClient.invalidateQueries([qK]));
    },
    onError: (errorMessage) => {
      clearBlocked();
      logDataToConsole(
        errorMessage,
        "useSwitchAppMutation onError errorMessage"
      );
    },
    onMutate: (data) => {
      setBlocked();
    },
  });

  return {
    useDeleteMutation,
    usePostMutation,
    usePutMutation,
  };
}
