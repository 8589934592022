import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { adminApiBaseUrl, swAppCombinedUrls } from "../../../constants";
import { useSwitchappAxiosClient } from "../useSwitchappAxiosClient";
import {
  IAdminApi,
  ISwAppServiceMetaApi,
  NoMeta,
} from "../../../interfaces-and-types";
import { appConfig, axiosBaseConfig } from "../../../configs";
import { useAuth } from "../../useAuth";
import { convertToQueryString } from "../../../utils";

// type to enable dynamic query config
type TQueryObserverOption<TData> = UseQueryOptions<
  TData,
  unknown,
  TData,
  any
> & {};

export function useAdminApiQuery<TResponseData, TMeta = NoMeta>(
  queryKey: keyof IAdminApi,
  queryConfig: TQueryObserverOption<
    ISwAppServiceMetaApi<TResponseData, TMeta>
  > | null = null,
  getQueryUrl: (templateQueryUrl: string) => string = (val) => val
) {
  return useAdminApiQueryWithParams<never, TResponseData, TMeta>(
    [queryKey, null],
    queryConfig,
    getQueryUrl
  );
}

export function useAdminApiQueryWithParams<
  TRequestData,
  TResponseData,
  TMeta = NoMeta
>(
  queryKey: [keyof IAdminApi, TRequestData | null],
  queryConfig: TQueryObserverOption<
    ISwAppServiceMetaApi<TResponseData, TMeta>
  > | null = null,
  getQueryUrl: (templateQueryUrl: string) => string = (val) => val
) {
  const baseURL = adminApiBaseUrl;
  const axiosClient = useSwitchappAxiosClient({
    baseURL,
    ...axiosBaseConfig,
  });

  // console.log(
  //   `running useAdminApiQueryWithParams; tenantId: ${activeTenantId}`
  // );

  // const useGetQueryTemplate = (id?: string) =>
  const useGetQuery = useQuery(
    queryKey,
    ({ queryKey: queryKeyAndParamsArray }) => {
      const [queryKey, queryParams] = queryKeyAndParamsArray;

      // NOTE: this will retrieve the url template from swAppCombinedUrls
      // and return it to the calling function to apply the necessary replacements
      const url = getQueryUrl(swAppCombinedUrls[queryKey]);

      // console.log(
      //   "serialized queryParams objects: ",
      //   queryParams && convertToQueryString(queryParams)
      // );

      // console.log(
      //   "serialized queryParams objects: ",
      //   queryParams &&
      //     Object.entries(queryParams)
      //       .filter(
      //         ([key, value], i) => typeof value === "object" && value !== null // key=filter, value={ currency: 'NGN' }
      //       )
      //       .map(([key, value], i) =>
      //         Object.entries(value as object).map(([innerKey, innerValue]) => ({
      //           [`${key}.${innerKey}`]: innerValue,
      //         }))
      //       )
      //       .reduce((acc, obj) => ({ ...acc, ...obj }), {})
      // );

      return (
        axiosClient
          .get<
            TRequestData,
            { data: ISwAppServiceMetaApi<TResponseData, TMeta> }
          >(url, {
            params: {
              ...queryParams,
              // ...(queryParams &&
              //   Object.entries(queryParams)
              //     .filter(([key, value], i) => typeof value === "object")
              //     .map(([key, value], i) => recurseObjectParams(key, value))),
            },
          })
          // (url + `?${new URLSearchParams(queryParams).toString()}`) // serializing objects to dot notation before passing to axios
          .then((response) => response.data)
          .catch((error) => {
            throw error;
          })
      );
    },
    {
      // enabled: false, // for query testing only
      // refetchOnMount: false,
      retry: appConfig.queryRetryEnabled,
      refetchOnWindowFocus: false,

      refetchInterval: 1000 * 60 * appConfig.queryCacheIntervalInMins,
      cacheTime: 1000 * 60 * appConfig.queryCacheIntervalInMins,
      // select: (data) => data, // format data into desired shape
      ...queryConfig,
    }
  );

  // const useDownloadQuery = useQuery(
  //   queryKey,
  //   ({ queryKey: queryKeyAndParamsArray }) => {
  //     const [queryKey, queryParams] = queryKeyAndParamsArray;
  //     const url = swAppCombinedUrls[queryKey];

  //     console.log(
  //       `creating useGetQuery in useAdminApiQueryWithParams;
  //       url: ${url}
  //       queryKey: ${queryKey}
  //       queryParams: ${queryParams}
  //       tenantId: ${tenantId}`
  //     );

  //     return (
  //       axiosClient
  //         .get<TRequestData, { data: TResponseData }>(url)
  //         // .post<TRequestData, { data: TResponseData }>(url, queryParams)
  //         .then((response) => {

  //       if (response.status === 200 || response.status === 201) {
  //         const headers = response.headers;
  //         const contentType = headers["content-type"];
  //         const contentDisposition = headers["content-disposition"];
  //         const fileDownloadName = contentDisposition
  //           .split(";")[1]
  //           .split("=")[1]
  //           .replace('"', "")
  //           .replace('"', "");

  //         const downloadUrl = window.URL.createObjectURL(
  //           new Blob([response.data], { type: contentType })
  //         );

  //         const link = document.createElement("a");

  //         link.href = downloadUrl;
  //         link.setAttribute("download", fileDownloadName); // any other extension
  //         document.body.appendChild(link);
  //         link.click();
  //         link.remove();
  //       }
  //         })
  //         .catch((error) => {
  //           throw error;
  //         })
  //     );
  //   },
  //   {
  //     // enabled: false, // for query testing only
  //     // refetchOnMount: false,
  //     refetchOnWindowFocus: false,
  //     refetchInterval: 1000 * 60 * appConfig.queryCacheIntervalInMins,
  //     // select: (data) => data, // format data into desired shape
  //     ...queryConfig,
  //   }
  // );

  // const useGetListQuery = useGetQueryTemplate();
  // const useGetByIdQuery = useGetQueryTemplate(putMutation);

  // return {
  //   useGetListQuery,
  //   useGetByIdQuery,
  // };

  return {
    ...useGetQuery,
    data: useGetQuery.data?.data,
    meta: useGetQuery.data?.meta,
    message: useGetQuery.data?.message,
    status: useGetQuery.data?.Status,
  };
}
