import { Link, NavLink } from "react-router-dom";
import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderMenuButton,
  HeaderMenuItem,
  SkipToContent,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
} from "@carbon/react";
import { NotificationView } from "./NotificationView";
import { UserIconView } from "./UserIconView";

import { mainNavLinks } from "../../routes";
import { HeaderMenuDropDown } from "../HeaderMenuDropDown";
import departmentIcon from "../../assets/icons/shop.svg";
import notificationCircle from "../../assets/icons/notificationCircle.svg";
import userTransparent from "../../assets/icons/userTransparent.svg";
import help from "../../assets/icons/help.svg";
import { HelpView } from "./HelpView";
import { GeneralSearchBar } from "../GeneralSearchBar";
import { SearchBar } from "../SearchBar";
import { Select } from "../Select";
import { getCurrencySymbol } from "../../utils";
import { useRef } from "react";
import { DepartmentView } from "./DepartmentView";
import { SwitchAppButton } from "../SwitchAppButton";

export const View = ({
  navigateToProfile,
  signOut,

  // currencies
  isLoadingSupportedCurrencies,
  isErrorSupportedCurrencies,
  supportedCurrencies,
  setActiveCurrency,
  activeCurrency,

  // selected merchant account
  isLoadingMerchants,
  isErrorMerchants,
  merchants,
  activeTenant,
  setActiveTenant,
  filterTenantsSearchTerm,
  setFilterTenantsSearchTerm,

  // dropdown helper
  openTenantsDropDown,
  setOpenTenantsDropDown,
}) => {
  const inputRef = useRef(null);
  const focusOnInput = () => inputRef.current?.focus();

  return (
    <>
      <HeaderContainer
        render={({ isSideNavExpanded, onClickSideNavExpand }) => (
          <Header
            className="header-transparency-cover"
            aria-label="switchapp header wrapper"
          >
            <Header aria-label="switchapp header">
              <SkipToContent />
              <HeaderMenuButton
                aria-label="Open menu"
                onClick={onClickSideNavExpand}
                isActive={isSideNavExpanded}
              />
              <HeaderName prefix="" element={Link} to="/">
                <img
                  src={`${process.env.PUBLIC_URL}/switchapp-logo.svg`}
                  alt="switchapp logo"
                  className="app-logo"
                />
                <h4 className="font-ctm--bold text-warning">ADMIN</h4>
              </HeaderName>

              <div
                className="nav-section-desktop"
                aria-label="Account Switching"
              >
                <HeaderMenuDropDown
                  headerContainerClass="header-menu-dropdown--container"
                  headerDropdownContentClass="header-menu-dropdown--content user-businesses-dropdown align-self--end"
                  className="header-menu-trigger--button"
                  items={merchants}
                  onSelect={(item) => {
                    setActiveTenant(item);
                    setFilterTenantsSearchTerm("");
                  }}
                  ignoreClickOutside
                  open={openTenantsDropDown}
                  setOpen={setOpenTenantsDropDown}
                  renderItem={(item) => <DepartmentView {...item} />}
                  renderInButton={
                    <div
                      title={activeTenant?.merchant_name || "..."}
                      className="account-select--value flex-container align-items--start"
                    >
                      <img
                        src={activeTenant?.merchant_logo || departmentIcon}
                        alt="merchant"
                        className="department-icon"
                      />
                      <span className="active-department-name truncate-text--ellipsis">
                        {activeTenant?.merchant_name || "Select a merchant..."}
                      </span>
                    </div>
                  }
                  renderBefore={
                    <SearchBar
                      name="search-businesses"
                      placeholder="Search all merchants..."
                      onChange={(e) => {
                        setFilterTenantsSearchTerm(e.target.value);
                        setTimeout(focusOnInput, 30);
                      }}
                      onClear={() => setFilterTenantsSearchTerm("")}
                      value={filterTenantsSearchTerm}
                      ref={inputRef}
                      key={"search-businesses"}
                    />
                  }
                  renderAfter={
                    !activeTenant ? null : (
                      <div className="flex-container justify-content--end align-items--end btn-container">
                        <SwitchAppButton
                          size="sm"
                          kind={"danger"}
                          className="mt-5 ml-5 mr-5 pl-5 pr-5"
                          onClick={() => setActiveTenant(null)}
                        >
                          Clear Filter
                        </SwitchAppButton>
                      </div>
                    )
                  }
                  headerMenuDropdownContentItemClass="flex-container header-menu-dropdown-content-item"
                  headerMenuDropdownButton={true}
                />

                <div className="flex-container col">
                  <Select
                    name="currencySelect"
                    label=""
                    title="Select Currency"
                    placeholder="Select Currency"
                    className="font-ctm--bold currency-select--header"
                    showSkeleton={isLoadingSupportedCurrencies}
                    hideSkeletonLabel
                    selectItems={supportedCurrencies.map((suppCurr) => ({
                      text: `${suppCurr.currency} (${getCurrencySymbol(
                        suppCurr.currency
                      )})`,
                      value: suppCurr.currency,
                    }))}
                    onChange={(e) => setActiveCurrency(e.target.value)}
                    value={activeCurrency}
                  />
                </div>

                <div className="header-search-input">
                  {/* <GeneralSearchBar /> */}
                </div>
              </div>

              <div
                className="nav-section-desktop between"
                aria-label="Account Switching"
              >
                {/* <HeaderMenuDropDown
                  headerContainerClass="header-menu-dropdown--container"
                  headerDropdownContent="middle-header-menu-dropdown--content align-self--end"
                  className="header-menu-trigger--button"
                  renderNonListOnOpen={<HelpView />}
                  renderInButton={
                    <div className="help-container">
                      <img aria-label="Notifications" src={help} />
                      <div>Help</div>
                    </div>
                  }
                  headerMenuDropdownContentItem="header-menu-dropdown-content-item"
                  headerMenuDropdownButton={true}
                /> */}

                <HeaderMenuDropDown
                  headerContainerClass="circle-header-menu-dropdown--container"
                  headerDropdownContentClass="circle-header-menu-dropdown--content align-self--end"
                  className="circle-header-menu-trigger--button"
                  renderNonListOnOpen={<NotificationView />}
                  renderInButton={
                    <img
                      aria-label="Notifications"
                      className="header-icon-right"
                      src={notificationCircle}
                    />
                  }
                  headerMenuDropdownContentItemClass="circle-header-menu-dropdown-content-item"
                  headerMenuDropdownButton={false}
                />

                <HeaderMenuDropDown
                  headerContainerClass="circle-header-menu-dropdown--container"
                  headerDropdownContentClass="circle-header-menu-dropdown--content align-self--end"
                  className="circle-header-menu-trigger--button"
                  headerMenuDropdownContentItemClass="circle-header-menu-dropdown-content-item"
                  headerMenuDropdownButton={false}
                  renderNonListOnOpen={
                    <UserIconView
                      navigateToProfile={navigateToProfile}
                      onSignOut={signOut}
                    />
                  }
                  renderInButton={
                    <img
                      aria-label="Notifications"
                      className="header-icon-right"
                      src={userTransparent}
                    />
                  }
                />
              </div>

              <SideNav
                className="side-nav--mobile-only"
                aria-label="Side navigation"
                expanded={isSideNavExpanded}
                isPersistent={false}
              >
                <SideNavItems>
                  <HeaderSideNavItems>
                    {mainNavLinks.map((link) => (
                      <HeaderMenuItem
                        key={link.route}
                        element={NavLink}
                        to={link.route}
                      >
                        {link.name}
                      </HeaderMenuItem>
                    ))}

                    <HeaderMenuItem
                      element={Link}
                      to={"/settings"}
                      className="pad-bottom"
                    >
                      Settings
                    </HeaderMenuItem>
                  </HeaderSideNavItems>
                </SideNavItems>
              </SideNav>
            </Header>
          </Header>
        )}
      />
    </>
  );
};
