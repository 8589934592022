import { QuickTable, QuickTableFilterTabs } from "../../../components";
import { customersDisplayFormatter } from "./customersDisplayFormatter";
import { ICustomerViewProps } from "./Customers";
import { CustomerDetailsModal } from "./CustomerDetailsModal";
import { Route, Routes } from "react-router-dom";

export const View = (props: ICustomerViewProps) => {
  return (
    <>
      <QuickTable
        id="customersTable"
        openDetailsModal={props.openDetailsModal}
        data={props.data}
        headers={props.headers}
        isLoading={props.isLoading}
        isError={props.isError}
        itemCount={props.totalCount}
        // renderDetail={(item) => item.customerTransactions} // get with another api call
        toolbarTabs={
          <QuickTableFilterTabs
            queryStringTabNavLinks={props.queryStringTabs}
          />
        }
        displayFormatter={customersDisplayFormatter}
        onExport={props.onExport}
        // openCreateNewModal={props.openCreateNewModal}
      />

      <Routes>
        {/* <Route
          path="create"
          element={
            <CreateCustomer
              refreshTable={props.refreshTable}
              goBackOnClose={false}
            />
          }
        /> */}

        <Route path=":customerId/details" element={<CustomerDetailsModal />} />

        {/* <Route
          path=":id/edit"
          element={<EditProductForm refreshTable={props.refreshTable} />}
        /> */}
      </Routes>
    </>
  );
};
