import { useEffect, useMemo, useState } from "react";
import {
  useDebounce,
  useAdminApiQuery,
  useAdminApiQueryWithParams,
} from "../hooks";

import {
  IGeneralSearchRequest,
  IGeneralSearchResponse,
  IGeneralSearchContext,
  NoMeta,
} from "../interfaces-and-types";
import { GeneralSearchContext } from "./GeneralSearchContext";

const searchKeyWords = [
  "amount:",
  "email:",
  "currency:",
  "country:",
  "status:",
  "date:",
  "last4:",
];

export function GeneralSearchProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [searchText, setSearchText] =
    useState<IGeneralSearchRequest["searchText"]>("");

  const [generalSearchRequest, setGeneralSearchRequest] =
    useState<IGeneralSearchRequest>({
      searchText: null,
      type: null,
      amount: null,
      email: null,
      currency: null,
      country: null,
      status: null,
      date: null,
      last4: null,
    });

  // console.log("generalSearchRequest: ", generalSearchRequest);

  const [isGeneralSearchTypeParsed, setGeneralSearchTypeParsed] =
    useState(false);

  const inputDebouncePeriodElapsed = useDebounce(1500, searchText);

  var isInvalidSearchText =
    searchText === "" ||
    searchText === null ||
    searchText.length <= 1 ||
    searchKeyWords.find((keyW) => keyW === searchText) !== undefined;

  const { isFetching, isLoading, isSuccess, data } = useAdminApiQueryWithParams<
    IGeneralSearchRequest,
    IGeneralSearchResponse,
    NoMeta
  >(
    [
      "generalSearchByText",
      {
        ...generalSearchRequest,
        searchText,
      },
    ],
    {
      enabled:
        inputDebouncePeriodElapsed &&
        isGeneralSearchTypeParsed &&
        !isInvalidSearchText,
    }
  );

  const memoedGeneralSearchContext = useMemo(() => {
    const unMemoedGeneralSearchContext: IGeneralSearchContext = {
      isLoading: isLoading && !isInvalidSearchText,

      searchText,
      setSearchText,

      data: isSuccess ? data ?? null : null,
    };

    return unMemoedGeneralSearchContext;
  }, [
    searchText,
    generalSearchRequest,
    isSuccess,
    isLoading,
    inputDebouncePeriodElapsed,
    isGeneralSearchTypeParsed,
  ]);

  const splitToKeyValuePairByRegex = (
    text: string | null,
    regexSplitKey: string,
    regexSplitDelimiter: string = " "
  ): { key: string; value: string | null } => {
    const splitWithData = text?.split(regexSplitKey);

    // console.log("splitWithData: ", splitWithData);

    const dataOnly =
      !!splitWithData && splitWithData.length > 1
        ? splitWithData[1].trim()?.split(regexSplitDelimiter)[0]
        : null;

    // console.log("dataOnly: ", dataOnly);

    return { key: regexSplitKey, value: dataOnly };
  };

  const parseSearchTextAndSetGeneralSearchType = () => {
    const generalSearchTypeKeys = Object.keys(generalSearchRequest);

    let newGeneralSearchRequestToSet = {
      ...generalSearchRequest,
    };

    generalSearchTypeKeys.forEach((objectKey) => {
      // regex split b/w the searchKey and next occurence of whiteSpace
      // eg anytext_amount:150 email:haxkalibrr otherText => {amount: 150} {email: haxkalibrr}
      const searchKey = `${objectKey}:`;
      const keyValuePair = splitToKeyValuePairByRegex(searchText, searchKey);

      newGeneralSearchRequestToSet = {
        ...newGeneralSearchRequestToSet,
        [objectKey]: keyValuePair.value,
      };
    });

    setGeneralSearchRequest(newGeneralSearchRequestToSet);

    setGeneralSearchTypeParsed(true);
  };

  // find any occurences of generalSearchTypeKeys in the searchString and set the appropriate value in generalSearchTypes
  useEffect(() => {
    setGeneralSearchTypeParsed(false);

    if (inputDebouncePeriodElapsed) parseSearchTextAndSetGeneralSearchType();
  }, [searchText, inputDebouncePeriodElapsed]);

  return (
    <GeneralSearchContext.Provider value={memoedGeneralSearchContext}>
      {children}
    </GeneralSearchContext.Provider>
  );
}
