import { filterConstants } from "../../constants";
import {
  useApiPagedMetaSync,
  useGetPageQueryParams,
  useAdminApiQueryWithParams,
  useQueryString,
  useReactRouterLink,
} from "../../hooks";
import {
  IQueryStringNavLinks,
  ITableHeader,
  IPageAndSearchRequest,
  PagedMeta,
  IViewTopUpListItem,
} from "../../interfaces-and-types";
import { View } from "./View";

interface ITopUpQueryMeta {
  status: string;
}

export interface ITopUpViewProps {
  data: IViewTopUpListItem[];
  headers: ITableHeader[];
  isLoading: boolean;
  isError: boolean;
  totalCount: number;
  queryStringTabs: IQueryStringNavLinks;

  // modals
  openDetailsModal: (id: any) => void;

  // extra buttons
  onExport: () => void;
}

export const TopUps = () => {
  const query = useQueryString();
  const status = query.get(filterConstants.statusKey);
  const { page, page_size, search } = useGetPageQueryParams();

  const { navigateToRoutePreserveParams } = useReactRouterLink();

  const {
    isLoading: isLoadingTopUps,
    isError: isErrorTopUps,
    data: topUps,
    meta: topUpsMeta,
  } = useAdminApiQueryWithParams<
    IPageAndSearchRequest & ITopUpQueryMeta,
    IViewTopUpListItem[],
    PagedMeta
  >([
    "getTopUps",
    {
      page: (page && Number(page)) || 1,
      page_size: (page_size && Number(page_size)) || 10,
      search,
      status: status || "successful",
    },
  ]);

  useApiPagedMetaSync(topUpsMeta?.page_meta);

  const headers: ITableHeader[] = [
    {
      key: "amount",
      header: "Amount",
    },
    {
      key: "currency",
      header: "Currency",
    },
    {
      key: "status",
      header: "Status",
    },
    // {
    //   key: "description",
    //   header: "Description",
    // },
    {
      key: "gateway",
      header: "Provider",
    },
    {
      key: "date",
      header: "Date",
    },
    {
      key: "more",
      header: "",
    },
  ];

  const queryStringTabs: IQueryStringNavLinks = {
    query: filterConstants.statusKey,
    options: [
      {
        key: "Successful",
        value: "successful",
      },
      {
        key: "Processing",
        value: "processing",
      },
      // {
      //   key: "Refunded",
      //   value: "refunded",
      // },
      {
        key: "Failed",
        value: "failed",
      },
    ],
  };

  const onExport = () => {
    alert("exporting data...");
  };

  const openDetailsModal = (id: any) =>
    navigateToRoutePreserveParams(`${id}/details`);

  return (
    <View
      data={topUps || []}
      headers={headers}
      isLoading={isLoadingTopUps}
      isError={isErrorTopUps}
      totalCount={topUpsMeta?.page_meta.item_count || 0}
      queryStringTabs={queryStringTabs}
      onExport={onExport}
      openDetailsModal={openDetailsModal}
    />
  );
};
