import { useMutation, useQueryClient } from "@tanstack/react-query";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { axiosBaseConfig } from "../../../configs";
import { adminApiBaseUrl, swAppCombinedUrls } from "../../../constants";
import { IAdminApi, ISwAppServiceApi } from "../../../interfaces-and-types";
import { logDataToConsole } from "../../../utils";
import { useActivityBlocker } from "../../useActivityBlocker";
import {
  ISwitchappAxiosClientConfig,
  useSwitchappAxiosClient,
} from "../useSwitchappAxiosClient";
import { useNotifications } from "../../useNotifications";

// type to enable muxing post, put, patch, and delete mutations for the template
// type TMutationFn<TRequestData, TResponseData> =
//   | ((id?: string, data?: TRequestData | null) => Promise<TResponseData>)
//   | ((data?: TRequestData | null) => Promise<TResponseData>);

export function useAdminApiMutation<TRequestData, TResponseData>(
  queryKey: keyof IAdminApi,
  refetchQueryKeys: (keyof IAdminApi)[] | null = null,
  axiosConfig:
    | ISwitchappAxiosClientConfig<TRequestData>
    | undefined = undefined, // to set multipart/form-data,
  getQueryUrl: (templateQueryUrl: string) => string = (val) => val
) {
  const baseURL = adminApiBaseUrl;
  const axiosClient = useSwitchappAxiosClient({
    baseURL,
    ...axiosBaseConfig,
    ...axiosConfig,
  });
  const queryClient = useQueryClient();
  const { clearBlocked, setBlocked } = useActivityBlocker();

  const notificationsContext = useNotifications();

  function displaySuccessMessage(title: string, message: string) {
    logDataToConsole(message, title);

    notificationsContext.showNotification({
      ariaLabel: "success notification",
      title: title,
      kind: "success",
      message: message,
      statusIconDescription: "success",
      timeout: 6000,
    });
  }

  function postMutation(data: TRequestData | null = null) {
    const url = getQueryUrl(swAppCombinedUrls[queryKey]);

    return axiosClient
      .post<ISwAppServiceApi<TResponseData>>(url, data)
      .then((response) => response.data); // NOTE: react query catches and handles our errors
  }

  function putMutation(data: TRequestData | null = null) {
    const url = getQueryUrl(swAppCombinedUrls[queryKey]);

    return axiosClient
      .put<ISwAppServiceApi<TResponseData>>(url, data)
      .then((response) => response.data);
  }

  function deleteMutation() {
    const url = getQueryUrl(swAppCombinedUrls[queryKey]);

    return axiosClient
      .delete<ISwAppServiceApi<TResponseData>>(url)
      .then((response) => response.data);
  }

  // HOOK IMPLEMENTATIONS
  const usePostMutation = useMutation([queryKey], postMutation, {
    onSuccess: (data) => {
      clearBlocked();

      displaySuccessMessage("Message", data.message);

      // invalidate all queries linked to this mutation
      refetchQueryKeys &&
        refetchQueryKeys.length > 0 &&
        refetchQueryKeys.map((qK) => queryClient.invalidateQueries([qK]));
    },
    // onError: (error: { data: ISwAppServiceApi<any | null> } | null) => {
    onError: (error: { data: any | null } | null) => {
      clearBlocked();

      // console.log("error?.data in post Mutation: ", error?.data);
      return error?.data;
    },
    onMutate: (data) => {
      setBlocked();
    },
  });

  const usePutMutation = useMutation([queryKey], putMutation, {
    onSuccess: (data) => {
      clearBlocked();

      displaySuccessMessage("Message", data.message);

      refetchQueryKeys &&
        refetchQueryKeys.length > 0 &&
        refetchQueryKeys.map((qK) => queryClient.invalidateQueries([qK]));
    },
    onError: (error: { data: any | null } | null) => {
      clearBlocked();

      return error?.data;
    },
    onMutate: (data) => {
      setBlocked();
    },
  });

  const useDeleteMutation = useMutation([queryKey], deleteMutation, {
    onSuccess: (data) => {
      clearBlocked();

      displaySuccessMessage("Message", data.message);

      refetchQueryKeys &&
        refetchQueryKeys.length > 0 &&
        refetchQueryKeys.map((qK) => queryClient.invalidateQueries([qK]));
    },
    onError: (error: { data: any | null } | null) => {
      clearBlocked();

      return error?.data;
    },
    onMutate: (data) => {
      setBlocked();
    },
  });

  return {
    useDeleteMutation,
    usePostMutation,
    usePutMutation,
  };
}
