import moment from "moment";
import { appConfig } from "../../../configs";
import { IViewCustomer } from "../../../interfaces-and-types";

export const customersDisplayFormatter = (item: IViewCustomer) => {
  return {
    ...item,
    full_name: (
      <span
        title={item.full_name || "Customer has not provided this information"}
      >
        {item.full_name || "..."}
      </span>
    ),
    created_at: (
      <span className="date--oneline">
        {moment(item.created_at).format(appConfig.dateTimeFormat)}
      </span>
    ),
    // more: <OverflowMenuVertical />,
  };
};
