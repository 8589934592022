import { useEffect, FC, ReactNode } from "react";
import { createPortal } from "react-dom";

interface IPortalProps {
  portalRoot: string;
  children: ReactNode;
  className?: string;
}

export const Portal: FC<IPortalProps> = (props) => {
  const portalRoot = document.getElementById(props.portalRoot) || document.body;

  const el = document.createElement("div");
  el.className = props.className || "";

  useEffect(() => {
    portalRoot.appendChild(el);

    return () => {
      portalRoot.removeChild(el);
    };
  }, [el, portalRoot]);

  return createPortal(props.children, el);
};
