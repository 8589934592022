import { ISwAppBankAccountsApi } from "../../interfaces-and-types";

type TBankAccountUrls = {
  [key in keyof ISwAppBankAccountsApi]: string;
};

const rootBanksUrl = "/banks";
const rootBankAccountUrl = "/bank-accounts";

export const bankAccountUrls: TBankAccountUrls = {
  addBankAccount: `${rootBankAccountUrl}`,
  editBankAccount: `${rootBankAccountUrl}/id`,
  getBankAccounts: `${rootBankAccountUrl}`,
  getBankAccountById: `${rootBankAccountUrl}/id`,
  getPrimaryAccount: `${rootBankAccountUrl}/primary-bank-account`,
  setAsPrimaryAccount: `${rootBankAccountUrl}/set-primary-bank-account`,
  // setAsPrimaryAccount: `${rootBankAccountUrl}/set-primary-account/id`,
  deleteBankAccount: `${rootBankAccountUrl}/id`,

  // TODO: move to banks module
  getBanks: `${rootBanksUrl}`,
  resolveBankAccount: `${rootBanksUrl}/resolve-account`,
};
