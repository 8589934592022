import { CopyButton } from "../CopyButton";
import { ILinkUrlCopyDisplay } from "./LinkUrlCopyDisplay";

export const View = (props: ILinkUrlCopyDisplay) => {
  return (
    <div
      title={props.securityRequired ? "" : props.link}
      className={`link-url-copy--container ${props.wrapperClassName}`}
    >
      <div
        className={`link-url-copy--text ${
          props.securityRequired ? "with-security" : ""
        }`}
      >
        <strong>
          {/* {!props.securityRequired ? props.link : props.link.replace(/_/g, "*")} */}
          {!props.securityRequired
            ? props.link
            : "********************************************"}
        </strong>

        {/* To allow select all on click, use an input */}
        {/* <input value={props.link} /> */}
      </div>
      <CopyButton stringToCopy={props.link} />
    </div>
  );
};
