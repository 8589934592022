import { IAdminApi } from "../../interfaces-and-types";
import { accountProfileUrls } from "./accountProfileUrls";
import { accountSettingsUrls } from "./accountSettingsUrls";
import { analyticsUrls } from "./analyticsUrls";
import { authUrls } from "./authUrls";
import { balancesUrls } from "./balancesUrls";
import { bankAccountUrls } from "./bankAccountUrls";
import { customerUrls } from "./customerUrls";
import { dashboardUrls } from "./dashboardUrls";
import { generalSearchUrls } from "./generalSearchUrls";
import { invitationCodesUrls } from "./invitationCodesUrls";
import { merchantUrls } from "./merchantUrls";
import { onboardingUrls } from "./onboardingUrls";
import { paymentLinksUrls } from "./paymentLinksUrls";
import { payoutsUrls } from "./payoutsUrls";
import { productsUrls } from "./productsUrls";
import { pricingManagementUrls } from "./pricingManagementUrls";
// import { staffUserSettingsUrls } from "./staffUserSettingsUrls";
import { switchappSupportedServicesUrls } from "./switchappSupportedServicesUrls";
import { transactionsUrls } from "./transactionsUrls";
import { transfersUrls } from "./transfersUrls";

type TSwAppUrls = { [key in keyof IAdminApi]: string };

export const swAppCombinedUrls: TSwAppUrls = {
  ...accountProfileUrls,
  ...accountSettingsUrls,
  ...analyticsUrls,
  ...authUrls,
  ...balancesUrls,
  ...bankAccountUrls,
  ...customerUrls,
  ...dashboardUrls,
  ...generalSearchUrls,
  ...invitationCodesUrls,
  ...merchantUrls,
  ...onboardingUrls,
  ...paymentLinksUrls,
  ...payoutsUrls,
  ...productsUrls,
  ...pricingManagementUrls,
  // ...staffUserSettingsUrls,
  ...switchappSupportedServicesUrls,
  ...transactionsUrls,
  ...transfersUrls,
};
