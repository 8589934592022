import { useContext, useEffect } from "react";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import { ApiErrorsContext } from "../../contexts";

import { logDataToConsole } from "../../utils";
import { useAuth } from "../useAuth";
import { useNotifications } from "../useNotifications";
import { useActiveTenant } from "../useActiveTenant";

export interface ISwitchappAxiosClientConfig<TRequestData>
  extends AxiosRequestConfig<TRequestData> {
  hideApiErrorMessge?: boolean;
}

interface IHasApiErrors {
  errors: any;
}

export function useSwitchappAxiosClient<TRequestData>(
  switchAppAxiosConfig: ISwitchappAxiosClientConfig<TRequestData>
): AxiosInstance {
  const switchappAxiosClient: AxiosInstance =
    axios.create(switchAppAxiosConfig);

  const authContext = useAuth();
  const notificationsContext = useNotifications();

  const { activeTenant } = useActiveTenant();

  const location = useLocation();
  const { apiErrors, setApiErrors, clearApiErrors } =
    useContext(ApiErrorsContext);

  useEffect(() => {
    clearApiErrors();
  }, [location]);

  function displayApiError(errorTitle: string, errorMessage: string) {
    logDataToConsole(errorMessage, errorTitle);

    notificationsContext.showNotification({
      ariaLabel: "error notification",
      title: errorTitle,
      kind: "error",
      message: errorMessage,
      statusIconDescription: "error",
      timeout: 10000,
    });

    if (!apiErrors) alert(errorMessage);
  }

  // this interceptor is called before every request
  switchappAxiosClient.interceptors.request.use((req) => {
    const token = authContext.token;

    // configure serialization of object query params
    // reqConfig.paramsSerializer = (params) => {
    //   // Qs is not included in the Axios package
    //   return queryString.stringifyUrl(params, {
    //     arrayFormat: "bracket",
    //     encode: false,
    //   });
    // };

    // clear api errors if any
    clearApiErrors();

    // handle refresh token

    // add token to the request if available (only some auth requests do not need a token)
    if (token && req.headers) req.headers.Authorization = "Bearer " + token;

    // add active tenant id to the request if set
    if (activeTenant?.tenant_id && req.params) {
      req.params["tenant_id"] = activeTenant?.tenant_id;
    }

    // log application api requests to reporting service here
    logDataToConsole(req, "Api Request [Axios Interceptor]: ");

    return req;
  });

  // this interceptor is called after every response
  switchappAxiosClient.interceptors.response.use(
    (resp: any) => {
      // log application api responses to reporting service here
      logDataToConsole(resp, "Api Response [Axios Interceptor]: ");

      return resp;
    },

    (error: AxiosError</*responseType*/ any, TRequestData>) => {
      const { response = { status: null } } = error; // .toJSON();
      const { status = null } = response;

      // get validation errors if available
      const apiErrors: any = error?.response?.data?.errors;
      if (apiErrors) {
        setApiErrors(apiErrors);
      }

      // log application api errors to reporting service here
      logDataToConsole(error.response, "[Axios Interceptor error] response: ");

      // handle error messages and statuses
      if (error.code === "ERR_NETWORK") {
        displayApiError(
          "Network error",
          "No Network Connection. Please Check Your Internet Connection."
        );
      } else if (error.code === "ECONNABORTED") {
        // cancelled requests
        logDataToConsole("ECONNABORTED", "ECONNABORTED");

        // display error message
        displayApiError("Request Cancelled", "Request cancelled by user");
      } else if (status && status === 401) {
        displayApiError("Not Authorized", "You are not signed in.");

        // clear api key; TODO: display overlay for login if route is not authRoute
        authContext.setToken(null);
        authContext.signOut(); // redirect user to login again;
      } else if (status && status === 403) {
        displayApiError(
          "Access denied",
          "You do not have permission to access this resource."
        );
      } else if (status && status === 404) {
        displayApiError(
          "Resource not found",
          "The requested resource was not found."
        );
      } else if (status && status === 500) {
        displayApiError(
          "Error occured",
          // "An internal error has occurred.",
          "Oops... Something seems to be broken. Totally our fault. Please retry later."
        );
      } else if (status && status === 503) {
        displayApiError(
          "Service unavailable",
          "The service is unavailable. Please try again later."
        );
      } else if (status && status === 504) {
        displayApiError(
          "Gateway timeout",
          "The gateway timed out. Please try again later."
        );
      } else {
        if (error.response && !switchAppAxiosConfig?.hideApiErrorMessge) {
          const { data = {} } = error.response;
          const errorMessage = data["error"] || data["msg"] || data["message"];

          if (errorMessage) {
            displayApiError("Message", errorMessage);
          } else {
            displayApiError(
              "Error",
              "An error has occurred. Please review your request."
            );
          }
        }
      }

      // const jsonError = error.toJSON();
      // return Promise.reject(jsonError);

      return Promise.reject(error.response as any);
      // return Promise.reject(error.response as unknown as ISwAppServiceApi<any>);
    }
  );

  return switchappAxiosClient;
}
