import { Route, Routes } from "react-router-dom";

import { QuickTable, QuickTableFilterTabs } from "../../components";
import { merchantsDisplayFormatter } from "./merchantsDisplayFormatter";
import { IMerchantViewProps } from "./Merchants";
import { MerchantDetailsModal } from "./MerchantDetailsModal";
import { Customers } from "./Customers";

export const View = (props: IMerchantViewProps) => {
  return (
    <>
      <QuickTable
        id="merchantsTable"
        openDetailsModal={props.openDetailsModal}
        data={props.data}
        headers={props.headers}
        isLoading={props.isLoading}
        isError={props.isError}
        itemCount={props.totalCount}
        // renderDetail={(item) => item.merchantTransactions} // get with another api call
        toolbarTabs={
          <QuickTableFilterTabs
            queryStringTabNavLinks={props.queryStringTabs}
          />
        }
        displayFormatter={merchantsDisplayFormatter}
        onExport={props.onExport}
        // openCreateNewModal={props.openCreateNewModal}
        disableClickOnColumns={["setFilterByMerchant"]}
      />

      <Routes>
        <Route path=":id/details/*" element={<MerchantDetailsModal />} />

        <Route path=":id/customers" element={<Customers />} />

        {/* <Route
          path=":id/edit"
          element={<EditProductForm refreshTable={props.refreshTable} />}
        /> */}
      </Routes>
    </>
  );
};
