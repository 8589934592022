import React from "react";
import {
  StructuredListWrapper,
  StructuredListHead,
  StructuredListCell,
  StructuredListRow,
  StructuredListBody,
} from "@carbon/react";
import { NoDataToSeeContainer } from "../NoDataToSeeContainer";

export const View = (props) => {
  return (
    <div>
      <StructuredListWrapper isCondensed={props.isCondensed}>
        <StructuredListHead>{props.headers}</StructuredListHead>

        <div
          className={`cds--structured-list-tbody ${props.structuredListBodyClassName}`}
        >
          {props.rows === null ? "" : props.rows}
        </div>
      </StructuredListWrapper>

      {props.rows === null ? (
        <NoDataToSeeContainer errorMessage="No related payments in the last 6 months" />
      ) : (
        ""
      )}
    </div>
  );
};
