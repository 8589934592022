import { NavLink, Route, Routes } from "react-router-dom";
import { Content, Column, Grid, Theme } from "@carbon/react";

import {
  merchantsNavLinks,
  balancesNavLinks,
  homeNavLinks,
  mainNavLinks,
  transactionsNavLinks,
  settingsNavLinks,
  transfersNavLinks,
} from "../../routes";
import { PageTabNavLinks } from "../PageTabNavLinks";
import { PaymentsLayout } from "./PaymentsLayout";
import { BalancesLayout } from "./BalancesLayout";
import { SettingsLayout } from "./SettingsLayout";
import { UserAccountManagementLayout } from "./UserAccountManagementLayout";
import { AppHeader } from "../AppHeader";
import { AccountModeSwitcher } from "../AccountModeSwitcher";
import { Home } from "../../pages";
import { TransfersLayout } from "./TransfersLayout";
import { MerchantsLayout } from "./MerchantsLayout";

export const View = () => {
  // const { pathname } = useLocation();
  // const splitPath = pathname.split("/");
  // const breadcrumbPaths = splitPath.map((part, i) => ({
  //   path: splitPath.slice(1, i + 1).join("/"),
  //   pathFragment: part,
  // }));
  const visibleTabNavLinks = mainNavLinks.filter((link) => !link.isShowMore);
  // const hiddenTabNavLinks = mainNavLinks.filter((link) => link.isShowMore);

  return (
    <>
      <Theme theme="g10">
        <AppHeader />
      </Theme>

      <Content>
        <Grid className="layout-container">
          <Column lg={16} md={0} sm={0} className="dashboard__nav">
            <div className="flex-container justify-content--between">
              <div className="flex-box">
                {visibleTabNavLinks.map((link) => (
                  <NavLink
                    key={link.route}
                    to={link.route}
                    className="main-tab--link button--link"
                  >
                    {link.name}
                  </NavLink>
                ))}

                {/* <HoverDropDown
                  text="More"
                  className="main-tab--link"
                  contentClassName="more-content"
                >
                  {hiddenTabNavLinks.map((link, i) => (
                    <NavLink
                      key={link.route}
                      to={link.route}
                      className="more-content--link"
                    >
                      <div
                        className={`more-content--contents ${
                          i === hiddenTabNavLinks.length - 1 ? "last" : ""
                        }`}
                      >
                        <img
                          src={link.icon}
                          className="link-icon align-icon--center"
                          alt="link icon"
                        />
                        <span className="link-name">{link.name}</span>
                      </div>
                    </NavLink>
                  ))}
                </HoverDropDown> */}
              </div>

              {/* Pull right nav links */}
              <div>
                <div className="flex-container align-items--center">
                  <div className="account-mode--switcher">
                    <AccountModeSwitcher />
                  </div>
                </div>
              </div>
            </div>
          </Column>

          {/* Mobile only breadcrumbs */}
          {/* <Column lg={0} md={8} sm={4} className="">
        <Breadcrumb noTrailingSlash>
          {breadcrumbPaths.map(({ path, pathFragment }) => (
            <BreadcrumbItem>
              <Link to={path}>{pathFragment}</Link>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      </Column> */}

          <Routes>
            <Route
              path="*"
              index
              element={
                <>
                  <PageTabNavLinks pageTabNavLinks={homeNavLinks} />
                  <Column lg={16} md={8} sm={4} className="page-content">
                    <Home />
                  </Column>
                </>
              }
            />

            <Route
              path="/merchants/*"
              element={
                <>
                  <PageTabNavLinks pageTabNavLinks={merchantsNavLinks} />
                  <Column lg={16} md={8} sm={4} className="page-content">
                    <MerchantsLayout />
                  </Column>
                </>
              }
            />

            <Route
              path="payments/*"
              element={
                <>
                  <PageTabNavLinks pageTabNavLinks={transactionsNavLinks} />
                  <Column lg={16} md={8} sm={4} className="page-content">
                    <PaymentsLayout />
                  </Column>
                </>
              }
            />

            <Route
              path="balances/*"
              element={
                <>
                  <PageTabNavLinks pageTabNavLinks={balancesNavLinks} />
                  <Column lg={16} md={8} sm={4} className="page-content">
                    <BalancesLayout />
                  </Column>
                </>
              }
            />

            <Route
              path="/transfers/*"
              element={
                <>
                  <PageTabNavLinks pageTabNavLinks={transfersNavLinks} />
                  <Column lg={16} md={8} sm={4} className="page-content">
                    <TransfersLayout />
                  </Column>
                </>
              }
            />

            <Route
              path="/settings/*"
              element={
                <>
                  <PageTabNavLinks pageTabNavLinks={settingsNavLinks} />
                  <Column lg={16} md={8} sm={4} className="page-content">
                    <SettingsLayout />
                  </Column>
                </>
              }
            />

            <Route
              path="/user-account/*"
              element={
                <>
                  {/* <PageTabNavLinks pageTabNavLinks={userAccountNavLinks} /> */}
                  <Column lg={16} md={8} sm={4} className="page-content">
                    <UserAccountManagementLayout />
                  </Column>
                </>
              }
            />

            {/* <Route
              path="*"
              element={
                <>
                  <Column lg={16} md={8} sm={4} className="page-content">
                    <PageNotFound />
                  </Column>
                </>
              }
            /> */}
          </Routes>
        </Grid>
      </Content>
    </>
  );
};
