import { Route, Routes } from "react-router-dom";

// import { ChangePassword } from "../ChangePassword";

export const UserAccountManagementLayout = () => {
  return (
    <Routes>
      <>
        {/* <Route path="/change-password" element={<ChangePassword />} /> */}
      </>
    </Routes>
  );
};
