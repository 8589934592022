import { SwitchAppContentSwitcher } from "../../components";
import { TSummaryPeriod } from "../../constants";

interface ISummaryPeriodSwitcherProps {
  summaryPeriod: TSummaryPeriod;
  setSummaryPeriod: (period: TSummaryPeriod) => void;
}

export const SummaryPeriodSwitcher = ({
  summaryPeriod,
  setSummaryPeriod,
}: ISummaryPeriodSwitcherProps) => {
  return (
    <div className="mt-5 mr-5 ml-5">
      <SwitchAppContentSwitcher<TSummaryPeriod>
        onChange={({ name }) => {
          setSummaryPeriod(name);
        }}
        options={[
          {
            key: "Today",
            value: "today",
          },
          {
            key: "Week",
            value: "past week",
          },
          {
            key: "Month",
            value: "past month",
          },
          {
            key: "All Time",
            value: "all time",
          },
          // {
          //   key: "Custom",
          //   value: "custom",
          // },
        ]}
        selectedValue={summaryPeriod}
      />
    </div>
  );
};
