import { TCarbonComponentSizeProps } from "../../interfaces-and-types";
import { View } from "./View";

export interface IDataLoadingViewProps {
  message?: string;
  className?: string;
  loaderSize?: TCarbonComponentSizeProps;
  // textSize?: TCarbonComponentSizeProps;
}

export const DataLoadingView = (props: IDataLoadingViewProps) => {
  const { message = "Loading data..." } = props;

  return <View {...props} message={message} />;
};
