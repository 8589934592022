import { filterConstants } from "../constants";
import {
  ITransactionDummy,
  IQueryStringNavLinks,
  ITableHeader,
  IViewTransaction,
  IPageAndSearchRequest,
  PagedMeta,
} from "../interfaces-and-types";
import { useQueryString } from "./useQueryString";

export function useGetPageQueryParams() {
  const query = useQueryString();
  const page = query.get(filterConstants.currentPageKey);
  const page_size = query.get(filterConstants.pageSizeKey);
  const search = query.get(filterConstants.searchKey);

  return {
    page,
    page_size,
    search,
  };
}
