interface ILinkIconProps {
  mainIconSource: string;
  subscriptIconSource?: string;
  iconLeft?: boolean;
  iconClassName?: string;
  subscriptIconClassName?: string;
}

export const LinkIcon = ({
  mainIconSource,
  subscriptIconSource,
  iconLeft,
  iconClassName,
  subscriptIconClassName,
}: ILinkIconProps) => {
  return (
    <div className=" align-icon--center">
      <img
        src={mainIconSource}
        alt="link icon"
        className={`${
          iconLeft ? "icon-left" : "icon-right"
        } align-icon--center ${iconClassName || ""}`}
      />

      {!subscriptIconSource ? (
        <></>
      ) : (
        <img
          src={subscriptIconSource}
          alt="link icon"
          className={`${iconLeft ? "icon-left" : "icon-right"} icon-subscript ${
            subscriptIconClassName || ""
          }`}
        />
      )}
    </div>
  );
};
