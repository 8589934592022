import { useNavigate, useLocation } from "react-router-dom";
import { filterConstants } from "../../constants";

import {
  useApiPagedMetaSync,
  useGetPageQueryParams,
  useAdminApiQueryWithParams,
  useQueryString,
  useActiveCurrency,
} from "../../hooks";
import {
  IQueryStringNavLinks,
  ITableHeader,
  IViewTransactionListItem,
  IPageAndSearchRequest,
  PagedMeta,
} from "../../interfaces-and-types";
import { View } from "./View";

interface ITransactionQueryFilter {
  currency?: string | null;
  prop2?: string | null;
}

export interface IPaymentQueryMeta {
  status: string;
  filter?: ITransactionQueryFilter;
}

export interface IPaymentViewProps {
  data: IViewTransactionListItem[];
  headers: ITableHeader[];
  isLoading: boolean;
  isError: boolean;
  totalCount: number;
  queryStringTabs: IQueryStringNavLinks;

  // modals
  openDetailsModal: (data: any) => void;

  // extra buttons
  onExport: () => void;
}

export const Payments = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQueryString();
  const status = query.get(filterConstants.statusKey);
  const { page, page_size, search } = useGetPageQueryParams();

  const {
    // isLoading: isLoadingSupportedCurrencies,
    activeCurrency,
  } = useActiveCurrency();

  const {
    isLoading: isLoadingTransactions,
    isError: isErrorTransactions,
    // isSuccess: isSuccessTransactions,
    data: transactions,
    meta: transactionsMeta,
  } = useAdminApiQueryWithParams<
    IPageAndSearchRequest & IPaymentQueryMeta,
    IViewTransactionListItem[],
    PagedMeta
  >([
    "getTransactions",
    {
      page: (page && Number(page)) || 1,
      page_size: (page_size && Number(page_size)) || 10,
      search,
      filter: {
        currency: activeCurrency,
        // prop2: "TestingAxiosQueryStringObjectFormation",
      },
      status: status || "successful",
    },
  ]);

  useApiPagedMetaSync(transactionsMeta?.page_meta);

  const headers: ITableHeader[] = [
    {
      key: "amount",
      header: "Amount",
    },
    {
      key: "charged_amount",
      header: "Charged Amount",
    },
    {
      key: "app_fee",
      header: "App Fee(s)",
    },
    {
      key: "internal__p_gw_app_fee",
      header: "Gateway Fee(s)",
    },
    {
      key: "status",
      header: "Status",
    },
    // {
    //   key: "xxxxxxx",
    //   header: "xxxxxxx",
    // },
    {
      key: "customer",
      header: "Customer",
    },
    {
      key: "gateway",
      header: "Provider",
    },
    {
      key: "date",
      header: "Date",
    },
    {
      key: "more",
      header: "",
    },
  ];

  const queryStringTabs: IQueryStringNavLinks = {
    query: filterConstants.statusKey,
    options: [
      // {
      //   key: "All",
      //   value: null,
      // },
      {
        key: "Successful",
        value: "successful",
      },
      {
        key: "Processing",
        value: "processing",
      },
      {
        key: "Refunded",
        value: "refunded",
      },
      {
        key: "Abandoned",
        value: "abandoned",
      },
      {
        key: "Failed",
        value: "failed",
      },
    ],
  };

  const onExport = () => {
    alert("exporting data...");
  };

  const openDetailsModal = (id: any) => {
    navigate({
      pathname: `${id}/details`,
      search: location.search,
    });
  };

  return (
    <View
      data={transactions || []}
      headers={headers}
      isLoading={isLoadingTransactions}
      isError={isErrorTransactions}
      totalCount={transactionsMeta?.page_meta.item_count || 0}
      queryStringTabs={queryStringTabs}
      onExport={onExport}
      openDetailsModal={openDetailsModal}
    />
  );
};
