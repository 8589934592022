const WEBSITE_URL = "https://www.switchappgo.com";

export const appConstants = {
  appName: "SwitchApp",
  appCompanyName: "SwitchApp Tech Solutions Ltd",
  appHomepageUrl: "https://dashboard.switchappgo.com",
  appWebsiteUrl: WEBSITE_URL,
  appContactUrl: `${WEBSITE_URL}/contact`,
  appPrivacyUrl: `${WEBSITE_URL}/privacy`,
  cookiePolicyUrl: `${WEBSITE_URL}/cookie-policy`,
  termsAndConditions: `${WEBSITE_URL}/terms-and-conditions`,

  // apiKeyHeaderName: "x-api-key",
  // tenantHeaderName: "x-tenantId",
};
