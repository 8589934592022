import { createContext } from "react";

import {
  IRemoteDataContext,
  IMerchantAccountSettings,
} from "../interfaces-and-types";

export const AccountSettingsContext = createContext<
  IRemoteDataContext<IMerchantAccountSettings | null>
>({
  // remote data
  isSuccess: false,
  isError: false,
  isLoading: false,
  data: null,
  refreshRemoteData: () => {},
});
