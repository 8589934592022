import { View } from "./View";

export interface IFormContainerProps {
  title?: string;
  subtitle?: string;
  className?: string;
  children: React.ReactNode;
}

export const FormContainer = (props: IFormContainerProps) => {
  return <View {...props} />;
};
