import { CustomersSummary } from "./CustomersSummary";
import { IHomeViewProps } from "./Home";
import { MerchantsSummary } from "./MerchantsSummary";
import { PaymentsSummary } from "./PaymentsSummary";
import { PayoutsSummary } from "./PayoutsSummary";
import { RefundsSummary } from "./RefundsSummary";
import { TopUpsSummary } from "./TopUpsSummary";
import { TransfersSummary } from "./TransfersSummary";

export const View = ({ activeCurrency, navigateToRoute }: IHomeViewProps) => {
  return (
    <div className="summaries-container">
      <div className="flex-container row flex-break--small justify-content--start align-items--stretch">
        <MerchantsSummary activeCurrency={activeCurrency} />

        <PaymentsSummary activeCurrency={activeCurrency} />

        <TopUpsSummary activeCurrency={activeCurrency} />
      </div>

      <div className="flex-container row flex-break--small justify-content--start align-items--stretch">
        <TransfersSummary activeCurrency={activeCurrency} />

        <PayoutsSummary activeCurrency={activeCurrency} />

        <RefundsSummary activeCurrency={activeCurrency} />
      </div>

      <div className="flex-container row flex-break--small justify-content--start align-items--start">
        <CustomersSummary activeCurrency={activeCurrency} />

        <div className="placeholder flex-box summary-section" />
        <div className="placeholder flex-box summary-section" />
        {/* <ProductsSummary activeCurrency={activeCurrency} />

        <PaymentLinksSummary activeCurrency={activeCurrency} /> */}
      </div>
    </div>
  );
};
