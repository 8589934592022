import { ISwAppMerchantsApi } from "../../interfaces-and-types";

type TMerchantUrls = {
  [key in keyof ISwAppMerchantsApi]: string;
};

const rootMerchantsUrl = "/merchants";

export const merchantUrls: TMerchantUrls = {
  getMerchants: `${rootMerchantsUrl}`,
  getMerchantById: `${rootMerchantsUrl}/id`,
  editMerchant: `${rootMerchantsUrl}/id`,
  deleteMerchant: `${rootMerchantsUrl}/id`,
  blacklistMerchant: `${rootMerchantsUrl}/blacklist`,
};
