import { filterConstants } from "../../constants";
import {
  useGetPageQueryParams,
  useAdminApiQueryWithParams,
  useQueryString,
  useReactRouterLink,
} from "../../hooks";
import {
  IQueryStringNavLinks,
  ITableHeader,
  IPageAndSearchRequest,
  PagedMeta,
  IViewInvitationCode,
} from "../../interfaces-and-types";
import { View } from "./View";

export interface IInvitationCodeViewProps {
  data: IViewInvitationCode[];
  headers: ITableHeader[];
  isLoading: boolean;
  isError: boolean;
  totalCount: number;
  queryStringTabs: IQueryStringNavLinks;

  // modals
  openCreateNewModal: () => void;
  // openDetailsModal: (data: any) => void;
  refreshTable: () => void;

  // extra buttons
  onExport: () => void;
}

export const InvitationCodes = () => {
  const query = useQueryString();
  const status = query.get(filterConstants.statusKey);
  const { page, page_size, search } = useGetPageQueryParams();

  const { navigate, navigateToRoutePreserveParams } = useReactRouterLink();

  const {
    isLoading: isLoadingInvitationCodes,
    isError: isErrorInvitationCodes,
    data: invitationCodes,
    meta: invitationCodesMeta,
    refetch: refetchInvitationCodes,
  } = useAdminApiQueryWithParams<
    IPageAndSearchRequest,
    IViewInvitationCode[],
    PagedMeta
  >([
    "getInvitationCodes",
    {
      page: (page && Number(page)) || 1,
      page_size: (page_size && Number(page_size)) || 10,
      search,
    },
  ]);

  const headers: ITableHeader[] = [
    {
      key: "code",
      header: "Code",
    },
    {
      key: "description",
      header: "Description",
    },
    {
      key: "status",
      header: "Status",
    },
    {
      key: "used_by_merchant",
      header: "Used By",
    },
    {
      key: "used_at",
      header: "Used At",
    },
    {
      key: "expires_at",
      header: "Expires At",
    },
    {
      key: "created_at",
      header: "Created On",
    },
    // {
    //   key: "more",
    //   header: "",
    // },
  ];

  const queryStringTabs: IQueryStringNavLinks = {
    query: filterConstants.statusKey,
    options: [
      {
        key: "All",
        value: "",
      },
      {
        key: "Active",
        value: "active",
      },
      {
        key: "Used",
        value: "used",
      },
      {
        key: "Expired",
        value: "expired",
      },
    ],
  };

  const onExport = () => {
    alert("exporting data...");
  };

  const refreshTable = () => refetchInvitationCodes();

  // const openDetailsModal = (invite_code_id: any) =>
  //   navigate(`${invite_code_id}/details`);

  const openCreateNewModal = () => {
    navigateToRoutePreserveParams(`create`);
  };

  return (
    <>
      <View
        data={invitationCodes || []}
        headers={headers}
        isLoading={isLoadingInvitationCodes}
        isError={isErrorInvitationCodes}
        totalCount={invitationCodesMeta?.page_meta.item_count || 0}
        queryStringTabs={queryStringTabs}
        onExport={onExport}
        openCreateNewModal={openCreateNewModal}
        // openDetailsModal={openDetailsModal}
        refreshTable={refreshTable}
      />
    </>
  );
};
