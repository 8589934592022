import {
  Badge,
  CurrencyDisplay,
  ErrorOccuredView,
  ModalContainer,
  SectionHeader,
  SkeletonLoader,
  StructuredList,
  SwitchAppButton,
  SwitchAppIconButton,
} from "../../../components";
import { SubmitButton } from "../../../hook-form";
import { ISpecialPricingModalViewProps } from "./SpecialPricing";

import imagePlaceholder from "../../../assets/icons/img-placeholder.svg";
import refreshIcon from "../../../assets/icons/refresh-3.png";
import { Link } from "react-router-dom";
import { TableHeaderForVolumePricingOption } from "./TableHeaderForVolumePricingOption";
import { VolumePricingOptionDisplay } from "./VolumePricingOptionDisplay";
import { PricingOptionDisplay } from "./PricingOptionDisplay";
import { orderByVolumePricingThresholdParams } from "../../../utils";

const VolumePricingStatusInfo = ({ status }: { status: boolean }) => (
  <>
    (Volume Pricing{" "}
    <span className={status ? "text-success" : "text-danger"}>
      {status ? "ENABLED" : "DISABLED"}
    </span>
    )
  </>
);

export const SpecialPricingDetails = (props: ISpecialPricingModalViewProps) => {
  const { isErrorModalData, isLoadingModalData, merchant, specialPricing } =
    props;

  return (
    <ModalContainer
      isModalOpen={props.isModalOpen}
      closeModal={props.closeModal}
      modalHeader={
        isLoadingModalData || !merchant || !specialPricing ? (
          <>Loading Details...</>
        ) : (
          <SectionHeader
            title={`Special Pricing Details`}
            subtitle={
              <>
                Manage <span className="font-ctm--bold">SPECIAL</span> pricing
                for{" "}
                <span className="font-ctm--bold">
                  {merchant.merchant_name.toUpperCase()} (
                  {specialPricing.country})
                </span>
              </>
            }
            renderRight={
              <Link to={`manage`}>
                <SwitchAppButton
                  onClick={() => {}}
                  size="sm"
                  className="ml-5 mr-5 pl-5 pr-5"
                >
                  Manage
                </SwitchAppButton>
              </Link>
            }
          />
        )
      }
    >
      {isLoadingModalData || !specialPricing ? (
        <>Loading Pricing Details...</>
      ) : (
        <div className="summary-section special-pricing--container flex-box flex-container col">
          <div className="payment-pricing--subsection px-5 py-5">
            <h6 className="font-ctm--semibold flex-box summary-section--header">
              PAYMENTS{" "}
              <VolumePricingStatusInfo
                status={specialPricing.is_payment_volume_pricing_enabled}
              />
              <div className="close-button--container">
                <SwitchAppIconButton
                  size="sm"
                  kind="tertiary"
                  className=""
                  onClick={() => {}}
                >
                  <img
                    src={refreshIcon}
                    alt="refresh icon"
                    title="Refresh Data"
                    className="refresh-icon"
                  />
                </SwitchAppIconButton>
              </div>
            </h6>

            <div className="flex-box summary-section--body">
              <StructuredList
                headers={<TableHeaderForVolumePricingOption />}
                structuredListBodyClassName="text--right font-ctm--semibold"
                rows={
                  <>
                    <PricingOptionDisplay
                      index={0}
                      pricingOption={
                        specialPricing.payment_default_pricing_option
                      }
                      renderBefore={
                        <>
                          <div className="cds--structured-list-td font-ctm--semibold">
                            <Badge
                              text="DEFAULT"
                              statusColor="danger"
                              indicatorColor="danger"
                              hideIndicator
                            />
                          </div>

                          <div className="cds--structured-list-td font-ctm--semibold">
                            <Badge
                              text="DEFAULT"
                              statusColor="danger"
                              indicatorColor="danger"
                              hideIndicator
                            />
                          </div>
                        </>
                      }
                    />
                    {orderByVolumePricingThresholdParams(
                      specialPricing?.payment_volume_pricing_options
                    ).map((volOption, index) => (
                      <VolumePricingOptionDisplay
                        key={volOption.id}
                        index={index + 1}
                        volumePricingOption={volOption}
                      />
                    ))}
                  </>
                }
              />
            </div>
          </div>

          <div className="topup-pricing--subsection px-5 py-5">
            <h6 className="font-ctm--semibold flex-box summary-section--header">
              TOP-UPS{" "}
              <VolumePricingStatusInfo
                status={specialPricing.is_top_up_volume_pricing_enabled}
              />
              <div className="close-button--container">
                <SwitchAppIconButton
                  size="sm"
                  kind="tertiary"
                  className=""
                  onClick={() => {}}
                >
                  <img
                    src={refreshIcon}
                    alt="refresh icon"
                    title="Refresh Data"
                    className="refresh-icon"
                  />
                </SwitchAppIconButton>
              </div>
            </h6>

            <div className="flex-box summary-section--body">
              <StructuredList
                headers={<TableHeaderForVolumePricingOption />}
                structuredListBodyClassName="text--right font-ctm--semibold"
                rows={
                  <>
                    <PricingOptionDisplay
                      index={0}
                      pricingOption={
                        specialPricing.top_up_default_pricing_option
                      }
                      renderBefore={
                        <>
                          <div className="cds--structured-list-td font-ctm--semibold">
                            <Badge
                              text="DEFAULT"
                              statusColor="danger"
                              indicatorColor="danger"
                              hideIndicator
                            />
                          </div>

                          <div className="cds--structured-list-td font-ctm--semibold">
                            <Badge
                              text="DEFAULT"
                              statusColor="danger"
                              indicatorColor="danger"
                              hideIndicator
                            />
                          </div>
                        </>
                      }
                    />
                    {orderByVolumePricingThresholdParams(
                      specialPricing?.top_up_volume_pricing_options
                    ).map((volOption, index) => (
                      <VolumePricingOptionDisplay
                        key={volOption.id}
                        index={index + 1}
                        volumePricingOption={volOption}
                      />
                    ))}
                  </>
                }
              />
            </div>
          </div>

          <div className="transfers-pricing--subsection px-5 py-5">
            <h6 className="font-ctm--semibold flex-box summary-section--header">
              TRANSFERS{" "}
              <VolumePricingStatusInfo
                status={specialPricing.is_transfer_volume_pricing_enabled}
              />
              <div className="close-button--container">
                <SwitchAppIconButton
                  size="sm"
                  kind="tertiary"
                  className=""
                  onClick={() => {}}
                >
                  <img
                    src={refreshIcon}
                    alt="refresh icon"
                    title="Refresh Data"
                    className="refresh-icon"
                  />
                </SwitchAppIconButton>
              </div>
            </h6>

            <div className="flex-box summary-section--body">
              <StructuredList
                headers={<TableHeaderForVolumePricingOption />}
                structuredListBodyClassName="text--right font-ctm--semibold"
                rows={
                  <>
                    <PricingOptionDisplay
                      index={0}
                      pricingOption={
                        specialPricing.transfer_default_pricing_option
                      }
                      renderBefore={
                        <>
                          <div className="cds--structured-list-td font-ctm--semibold">
                            <Badge
                              text="DEFAULT"
                              statusColor="danger"
                              indicatorColor="danger"
                              hideIndicator
                            />
                          </div>

                          <div className="cds--structured-list-td font-ctm--semibold">
                            <Badge
                              text="DEFAULT"
                              statusColor="danger"
                              indicatorColor="danger"
                              hideIndicator
                            />
                          </div>
                        </>
                      }
                    />
                    {orderByVolumePricingThresholdParams(
                      specialPricing?.transfer_volume_pricing_options
                    ).map((volOption, index) => (
                      <VolumePricingOptionDisplay
                        key={volOption.id}
                        index={index + 1}
                        volumePricingOption={volOption}
                      />
                    ))}
                  </>
                }
              />
            </div>
          </div>

          <div className="payout-pricing--subsection px-5 py-5">
            <h6 className="font-ctm--semibold flex-box summary-section--header">
              PAYOUTS{" "}
              <VolumePricingStatusInfo
                status={specialPricing.is_payout_volume_pricing_enabled}
              />
              <div className="close-button--container">
                <SwitchAppIconButton
                  size="sm"
                  kind="tertiary"
                  className=""
                  onClick={() => {}}
                >
                  <img
                    src={refreshIcon}
                    alt="refresh icon"
                    title="Refresh Data"
                    className="refresh-icon"
                  />
                </SwitchAppIconButton>
              </div>
            </h6>

            <div className="flex-box summary-section--body">
              <StructuredList
                headers={<TableHeaderForVolumePricingOption />}
                structuredListBodyClassName="text--right font-ctm--semibold"
                rows={
                  <>
                    <PricingOptionDisplay
                      index={0}
                      pricingOption={
                        specialPricing.payout_default_pricing_option
                      }
                      renderBefore={
                        <>
                          <div className="cds--structured-list-td font-ctm--semibold">
                            <Badge
                              text="DEFAULT"
                              statusColor="danger"
                              indicatorColor="danger"
                              hideIndicator
                            />
                          </div>

                          <div className="cds--structured-list-td font-ctm--semibold">
                            <Badge
                              text="DEFAULT"
                              statusColor="danger"
                              indicatorColor="danger"
                              hideIndicator
                            />
                          </div>
                        </>
                      }
                    />
                    {orderByVolumePricingThresholdParams(
                      specialPricing?.payout_volume_pricing_options
                    ).map((volOption, index) => (
                      <VolumePricingOptionDisplay
                        key={volOption.id}
                        index={index + 1}
                        volumePricingOption={volOption}
                      />
                    ))}
                  </>
                }
              />
            </div>
          </div>
        </div>
      )}
    </ModalContainer>
  );
};
