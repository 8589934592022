export function truncateStringWithEllipsis(
  str: string | null | undefined,
  maxLength: number
): string | null {
  if (!str || str === null) {
    return null;
  }

  if (str.length <= maxLength) {
    return str;
  }

  return str.slice(0, maxLength) + "...";
}

export const applySecurityMask = (
  str: string | null | undefined,
  maskLength: number = str?.length || 0,
  maskType: "start" | "middle" | "end" = "start",
  maskCharacter: string = "*"
): string | null | undefined => {
  if (!str) return str;

  maskLength = Math.floor(Math.min(str.length, maskLength));

  if (maskType === "start") {
    // const firstSection = str.slice(0, maskLength)
    const firstSection = Array.from({ length: maskLength }, (v, k) => k).map(
      (v, k) => maskCharacter
    );

    const secondSection = str.slice(maskLength);

    return firstSection + secondSection;
  }
};

export const splitCamelCaseString = (
  value: string | null,
  delimiter: string = " "
): string | null =>
  value?.replace(/([a-z])([A-Z])/g, `$1${delimiter}$2`) || value;
