import { ISwAppInvitationCodesApi } from "../../interfaces-and-types";

type TInvitationCodeUrls = {
  [key in keyof ISwAppInvitationCodesApi]: string;
};

const rootInvitationCodesUrl = "/invitation-codes";

export const invitationCodesUrls: TInvitationCodeUrls = {
  createInvitationCode: `${rootInvitationCodesUrl}`,
  getInvitationCodes: `${rootInvitationCodesUrl}`,
  getInvitationCodeById: `${rootInvitationCodesUrl}/id`,
  deleteInvitationCode: `${rootInvitationCodesUrl}/id`,
};
