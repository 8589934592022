import { IBadgeProps } from "./Badge";

export const View = ({
  statusColor,
  indicatorColor,
  hideIndicator,
  indicatorSize = "md",
  text,
  largeFont,
  className,
}: IBadgeProps) => {
  return (
    <span
      className={`badge-container ${statusColor}-badge--color ${
        largeFont ? "badge-font--large" : ""
      } ${className}`}
    >
      <span>{text}</span>

      {hideIndicator ? (
        <></>
      ) : (
        <span
          className={`badge-status--indicator ${
            indicatorColor || statusColor
          }-indicator--color indicator-size--${indicatorSize}`}
        />
      )}
    </span>
  );
};
