import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { filterConstants } from "../../constants";
import {
  useGetPageQueryParams,
  useAdminApiQuery,
  useAdminApiQueryWithParams,
  useQueryString,
  useReactRouterLink,
  useActiveTenant,
} from "../../hooks";
import {
  IPageAndSearchRequest,
  IQueryStringNavLinks,
  ITableHeader,
  IViewMerchantList,
  PagedMeta,
} from "../../interfaces-and-types";
import { View } from "./View";
import { FilterEdit } from "@carbon/icons-react";

export interface IMerchantViewProps {
  data: IViewMerchantList[];
  headers: ITableHeader[];
  isLoading: boolean;
  isError: boolean;
  totalCount: number;
  queryStringTabs: IQueryStringNavLinks;

  // modals
  // openCreateNewModal: () => void;
  openDetailsModal: (data: any) => void;
  refreshTable: () => void;

  // extra buttons
  onExport: () => void;
}

export const Merchants = () => {
  const queryClient = useQueryClient();
  const query = useQueryString();
  // const status = query.get(filterConstants.statusKey);
  const { page, page_size, search } = useGetPageQueryParams();

  const { navigateToRoutePreserveParams } = useReactRouterLink();

  const { activeTenant, setActiveTenant } = useActiveTenant();

  const {
    isLoading: isLoadingMerchants,
    isError: isErrorMerchants,
    // isSuccess: isSuccessMerchants,
    data: merchants,
    meta: merchantsMeta,
  } = useAdminApiQueryWithParams<
    IPageAndSearchRequest,
    IViewMerchantList[],
    PagedMeta
  >([
    "getMerchants",
    {
      page: (page && Number(page)) || 1,
      page_size: (page_size && Number(page_size)) || 10,
      search,
      // status: status || "active",
    },
  ]);

  const headers: ITableHeader[] = [
    {
      key: "merchant_name",
      header: "Merchant Name",
    },
    {
      key: "email",
      header: "Email",
    },
    {
      key: "country",
      header: "Country",
    },
    {
      key: "phone_number",
      header: "Phone No",
    },
    {
      key: "is_account_verification_successful",
      header: "Verified",
    },
    {
      key: "status",
      header: "Status",
    },
    {
      key: "created_at",
      header: "Created",
    },
    {
      key: "is_special_merchant_pricing_enabled",
      header: (
        <>
          Special <br /> Pricing
        </>
      ),
    },
    {
      key: "setFilterByMerchant",
      header: null, // <FilterEdit />,
    },
    {
      key: "more",
      header: "",
    },
  ];

  const queryStringTabs: IQueryStringNavLinks = {
    query: filterConstants.statusKey,
    options: [
      {
        key: "All",
        value: "",
      },
      // {
      //   key: "Blacklisted",
      //   value: "blacklisted",
      // },
    ],
  };

  const onExport = () => {
    // export data via api and download
    alert("exporting data...");
  };

  const refreshTable = () => {
    queryClient.invalidateQueries([
      "getMerchants",
      {
        page: (page && Number(page)) || 1,
        page_size: (page_size && Number(page_size)) || 10,
        search,
        // status: status || "active",
      },
    ]);
  };

  // insert the functions to open edit/delete modals
  const merchantsWithLinks = merchants?.map((p) => ({
    ...p,
    isActiveTenant: p.tenant_id === activeTenant?.tenant_id,
    openEditModal: () => navigateToRoutePreserveParams(`${p.id}/edit`),
    openDeleteModal: () => navigateToRoutePreserveParams(`${p.id}/delete`),
    setFilterByMerchant:
      p.tenant_id === activeTenant?.tenant_id
        ? () => {}
        : () => setActiveTenant(p),
  }));

  const openDetailsModal = (id: any) =>
    navigateToRoutePreserveParams(`${id}/details`);

  // const openCreateNewModal = () => {
  //   navigateToRoutePreserveParams(`create`);
  // };

  return (
    <View
      data={merchantsWithLinks || []}
      headers={headers}
      isLoading={isLoadingMerchants}
      isError={isErrorMerchants}
      totalCount={merchantsMeta?.page_meta.item_count || 0}
      queryStringTabs={queryStringTabs}
      onExport={onExport}
      // openCreateNewModal={openCreateNewModal}
      openDetailsModal={openDetailsModal}
      refreshTable={refreshTable}
    />
  );
};
