import { useEffect, useMemo, useState } from "react";

import {
  IActiveCurrencyContext,
  ISwAppSupportedCurrency,
} from "../interfaces-and-types";
import { ActiveCurrencyContext } from "./ActiveCurrencyContext";
import { useAdminApiQuery } from "../hooks";

export function ActiveCurrencyProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [activeCurrency, setActiveCurrency] = useState<string | null>("NGN");

  const [debouncePeriodElapsed, setDebouncePeriodElapsed] =
    useState<boolean>(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncePeriodElapsed(true), 100);

    return () => clearTimeout(timeoutId);
  }, []);

  const { isSuccess, isError, isLoading, data } = useAdminApiQuery<
    ISwAppSupportedCurrency[]
  >("getSupportedCurrencies", {
    enabled: debouncePeriodElapsed,
  });

  const refreshRemoteData = () => {};

  const memoedActiveCurrencyContext = useMemo(() => {
    const unMemoedActiveCurrencyContext: IActiveCurrencyContext = {
      activeCurrency,
      setActiveCurrency,

      // remote context
      isSuccess,
      isError,
      isLoading,
      data: data || [],
      refreshRemoteData,
    };

    return unMemoedActiveCurrencyContext;
  }, [activeCurrency, isSuccess, isError, isLoading]);

  return (
    <ActiveCurrencyContext.Provider value={memoedActiveCurrencyContext}>
      {children}
    </ActiveCurrencyContext.Provider>
  );
}
