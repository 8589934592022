import { useParams } from "react-router-dom";
import { useAdminApiQueryWithParams, useReactRouterLink } from "../../../hooks";

import {
  IModalFormProps,
  IViewGatewayPricingPerCountry,
  IViewMerchant,
} from "../../../interfaces-and-types";

import { View } from "./View";

export interface ISpecialPricingProps {
  merchant: IViewMerchant | null;
}

export interface ISpecialPricingModalViewProps
  extends IModalFormProps,
    ISpecialPricingProps {
  merchant: IViewMerchant | null;
  specialPricing: IViewGatewayPricingPerCountry | null;
}

export const SpecialPricing = (props?: ISpecialPricingProps) => {
  const { location, navigateToRoutePreserveParams } = useReactRouterLink();

  const closeModal = () => {
    navigateToRoutePreserveParams(
      location.pathname.replace(`/special-pricing/${pricing_id}`, "")
    );
    // navigate(location.pathname.replace(`/merchants`, ""));
  };

  const { id: merchantId, pricing_id } = useParams();

  const {
    isLoading: isLoadingMerchant,
    // isSuccess: isSuccessMerchant,
    isError: isErrorMerchant,
    data: merchantFromApi,
  } = useAdminApiQueryWithParams<{ id: string }, IViewMerchant>(
    [
      "getMerchantById",
      {
        id: merchantId!, // NOTE: used to enforce react query cache with correct queryKey
      },
    ],
    {
      enabled: !!merchantId && !props?.merchant, // do not fetch merchant if already passed as props
    },
    (urlTemplate) => urlTemplate.replace("id", merchantId!)
  );

  const merchant = props?.merchant || merchantFromApi;

  const {
    isLoading,
    // isSuccess,
    isError,
    data: specialPricing,
  } = useAdminApiQueryWithParams<{ id: string }, IViewGatewayPricingPerCountry>(
    [
      "getSpecialMerchantPricingById",
      {
        id: pricing_id!,
      },
    ],
    {
      enabled: !!merchantId && !!pricing_id,
    },
    (urlTemplate) =>
      urlTemplate
        .replace("{merchant_id}", merchantId!)
        .replace("{id}", pricing_id!)
  );

  return (
    <View
      isLoadingModalData={isLoading}
      isErrorModalData={isError}
      isModalOpen={!!pricing_id}
      closeModal={closeModal}
      merchant={merchant || null}
      specialPricing={specialPricing || null}
    />
  );
};
