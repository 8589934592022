import { SubmitButton, TextInput } from "../../hook-form";
import { FormContainer, ToastNotification } from "../../components";
import switchAppLogo from "../../assets/images/switchapp-logo.svg";
import { Link } from "react-router-dom";
import { IHookForm, IForgotPasswordRequest } from "../../interfaces-and-types";

export const View = ({
  isSuccessForgotPassword,
  redirectToLogin,
  errors: errors,
  handleSubmit: handleSubmit,
  register: register,
}: IHookForm<IForgotPasswordRequest> & {
  isSuccessForgotPassword: boolean;
  redirectToLogin: () => void;
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="auth-page--container flex-container col justify-content--center align-items--center">
        <div>
          <div className="logo-container flex-container justify-content--start">
            <Link to="/">
              <img src={switchAppLogo} alt="logo" className="switchapp-logo" />
            </Link>
          </div>

          <FormContainer>
            <div className="form-container flex-container col">
              <div className="form-container__header font-ctm--bold">
                <h3>Forgot Password</h3>
              </div>

              {!isSuccessForgotPassword ? null : (
                <div className="toast-notification-container">
                  <ToastNotification
                    title={"Password Reset Link Sent"}
                    message={
                      "A password reset link has been sent to your email. Follow the directions on the email to reset your password."
                    }
                    ariaLabel={"Password Reset Link Sent Toast Notification"}
                    onClose={redirectToLogin}
                  />
                </div>
              )}

              <div className="form-container__body">
                <div className="form-container__body__input-container">
                  <TextInput
                    name="email"
                    label="Email"
                    type="text"
                    register={register}
                    errors={errors}
                  />
                </div>

                <div className="flex-container buttons-container">
                  <SubmitButton className=" onboarding-submit--button">
                    Submit
                  </SubmitButton>
                </div>
              </div>

              <div className="form-container__footer">
                <p className="">
                  Don't have an account? <Link to={"/signup"}>Sign up</Link>
                </p>
              </div>
            </div>
          </FormContainer>
        </div>

        {/* go back to sign in page */}
        <div className="switchapp-small go-back--link mt-5 font-ctm--bold">
          <Link to={"/signin"}>Go Back</Link>
        </div>
      </div>
    </form>
  );
};
