import { ISwAppProductsApi } from "../../interfaces-and-types";

type TProductsUrls = {
  [key in keyof ISwAppProductsApi]: string;
};

const rootProductsUrl = "/products";

export const productsUrls: TProductsUrls = {
  createProduct: `${rootProductsUrl}`,
  getProducts: `${rootProductsUrl}`,
  getProductById: `${rootProductsUrl}/id`,
  editProduct: `${rootProductsUrl}/id`,
  deleteProduct: `${rootProductsUrl}/id`,
};
