import { createContext } from "react";
import {
  IApiPagedMetaContext,
  IPageAndSearchMeta,
} from "../interfaces-and-types";

export const defaultApiPagedMeta: IPageAndSearchMeta = {
  page: 1,
  page_size: 10,
  page_count: 1,
  item_count: 0,
  search: null,
};

export const ApiPagedMetaContext = createContext<IApiPagedMetaContext>({
  page_meta: defaultApiPagedMeta,
  setApiPageMeta: () => {},
  clearApiPageMeta: () => {},
});
