import {
  Modal,
  ComposedModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@carbon/react";
import ReactModal from "react-modal";

export const View = ({
  isModalOpen,
  closeModal,

  modalHeader,
  children,
  modalFooter,
  className,
  modalBodyClassName = "",
  fullscreen,
  selectorPrimaryFocus,
}) => {
  return (
    // <ReactModal
    //   isOpen={isModalOpen}
    //   onRequestClose={closeModal}
    //   className={`${className} ${fullscreen ? "full-screen--modal" : ""}`}
    // >
    <Modal
      open={isModalOpen}
      onRequestClose={closeModal}
      onClose={closeModal}
      // preventCloseOnClickOutside
      modalHeading={modalHeader}
      footer={modalFooter}
      passiveModal
      className={`${modalHeader ? "with-header-border" : ""} ${
        fullscreen ? "full-screen--modal" : ""
      } ${className}`}
      selectorPrimaryFocus={selectorPrimaryFocus || ".submit-button"}
    >
      <ModalBody className={modalBodyClassName}>{children}</ModalBody>

      {/* {!modalFooter ? null : <ModalFooter>{modalFooter}</ModalFooter>} */}
    </Modal>
    // </ReactModal>
  );
};
