import { useState } from "react";

import { useAdminApiQueryWithParams } from "../../../hooks";
import {
  IRefundsSummary,
  IRefundOverview,
} from "../../../interfaces-and-types";
import { TSummaryPeriod } from "../../../constants";
import { ISummarySectionProps, ISummarySectionViewProps } from "../Home";

import { View } from "./View";

export interface IRefundsSummaryViewProps
  extends ISummarySectionViewProps<IRefundOverview> {}

export const RefundsSummary = ({ activeCurrency }: ISummarySectionProps) => {
  const {
    isLoading: isLoadingRefundsSummary,
    // isSuccess: isSuccessRefundsSummary,
    // isError: isErrorRefundsSummary,
    data: refundsSummary,
  } = useAdminApiQueryWithParams<{ currency: string }, IRefundsSummary>(
    [
      "getRefundsSummary",
      {
        currency: activeCurrency!,
      },
    ],
    {
      enabled: !!activeCurrency,
    }
  );

  const isLoadingCustomPeriodSummary = false; // update after adding fetch logic for custom

  const [summaryPeriod, setSummaryPeriod] = useState<TSummaryPeriod>("today");

  const summaryToDisplay =
    summaryPeriod === "today"
      ? refundsSummary?.today
      : summaryPeriod === "past week"
      ? refundsSummary?.past_week
      : summaryPeriod === "past month"
      ? refundsSummary?.past_month
      : // summaryPeriod === "custom" ? customPeriodRefundsSummary :
        refundsSummary?.all_time;

  const isLoadingSummary =
    summaryPeriod === "custom"
      ? isLoadingCustomPeriodSummary
      : isLoadingRefundsSummary;

  return (
    <View
      activeCurrency={activeCurrency}
      isLoadingSummary={isLoadingSummary}
      summaryToDisplay={summaryToDisplay || null}
      summaryPeriod={summaryPeriod}
      setSummaryPeriod={setSummaryPeriod}
    />
  );
};
