export const getApiErrorsByFieldName = (
  name: string,
  apiErrors: Record<string, string[]>
) => {
  const pascalCasedFieldName = name; //.charAt(0).toUpperCase() + name.slice(1);

  const apiErrorMessage =
    (apiErrors[pascalCasedFieldName] &&
      apiErrors[pascalCasedFieldName].length > 0 &&
      apiErrors[pascalCasedFieldName][0]) ||
    "";

  return apiErrorMessage;
};
