import * as dateFns from "date-fns";
import IMask from "imask";

/**
 * https://stackoverflow.com/a/10452789/8786986
 * @param args
 */
const masker = ({
  masked,
  transform,
  maskDefault,
}: {
  masked: any;
  transform?: any;
  maskDefault?: any;
}) =>
  (function () {
    const mask = IMask.createPipe(
      masked,
      IMask.PIPE_TYPE.UNMASKED,
      IMask.PIPE_TYPE.MASKED
    );

    const unmask = IMask.createPipe(
      masked,
      IMask.PIPE_TYPE.MASKED,
      IMask.PIPE_TYPE.UNMASKED
    );

    const onChange = (e: any) => {
      const unmasked = unmask(e.target.value);
      const newValue = mask(unmasked);
      e.target.value = newValue;
    };

    return {
      mask,
      onChange,
      transform: transform || unmask,
      unmask,
      maskDefault: maskDefault || mask,
    };
  })();

const cardExpiryDateFormatClient = "MM / yy";

export const cardExpiryDateMask = masker({
  masked: {
    mask: Date,
    pattern: cardExpiryDateFormatClient,
    blocks: {
      MM: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 12,
        maxLength: 2,
      },
      yy: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 99,
      },
    },
    format: (cardExpiryDate: string) => {
      return cardExpiryDate;
    },
    parse: (cardExpiryDateStr: string) => {
      return cardExpiryDateStr;
    },
  },
  transform: (value: string): [month: string | null, year: string | null] => {
    if (!value) {
      return [null, null];
    }

    // add a preceding zero if first digit greater than 1
    // if (value.length === 1) {
    //   var parsedMonthValue = Number(value) < 1 ? `0${value}` : value;
    //   return [parsedMonthValue, null];
    // }

    if (value.length < 3) return [value, null];

    var splitMonthAndYear = value.split("/");
    return [splitMonthAndYear[0], splitMonthAndYear[1]];
  },
  // for default: join expiryMonth & expiryYear and send as default value
  maskDefault: (value: string) => {
    return value;
  },
});

// TODO: use dynamic masking for the card
// export const cardNumberMask = masker({
//   masked: {
//     mask: [
//       {
//         mask: "55xx aaaa bbbb cccc",
//         cardVendor: "mastercard",
//       },
//       {
//         mask: "54xx aaaa bbbb cccc ddd",
//         cardVendor: "visa",
//       },
//       {
//         mask: "44xx aaaa bbbb cccc ddd",
//         cardVendor: "verve",
//       },
//     ],
//     dispatch: (
//       appended: any,
//       dynamicMasked: { compiledMasks: any[]; value: any }
//     ) => {
//       const mastercardMask = dynamicMasked.compiledMasks.find(
//         ({ cardVendor }) => cardVendor === "mastercard"
//       );

//       const visaMask = dynamicMasked.compiledMasks.find(
//         ({ cardVendor }) => cardVendor === "visa"
//       );

//       if (
//         `${dynamicMasked.value}${appended}`.length > mastercardMask.mask.length
//       ) {
//         return visaMask;
//       }

//       return mastercardMask;
//     },
//   },
// });

const cardNumberFormatClient = "aaaa aaaa aaaa aaaa bbb";

export const cardNumberMask = masker({
  masked: {
    mask: Date,
    pattern: cardNumberFormatClient,
    blocks: {
      aaaa: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 9999,
        maxLength: 4,
      },
      bbb: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 999,
        maxLength: 3,
      },
    },
    format: (cardNumber: string) => {
      return cardNumber;
    },
    parse: (cardNumberStr: string) => {
      return cardNumberStr;
    },
  },
  transform: (value: string): string => {
    if (!value) {
      return value;
    }

    return value.replace(" ", "");
  },
  maskDefault: (value: string) => {
    return value;
  },
});

const dateFormatClient = "dd/MM/yyyy";
const dateFormatApi = "yyyy-MM-dd";

export const dateMask = masker({
  masked: {
    mask: Date,
    pattern: dateFormatClient,
    blocks: {
      dd: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        maxLength: 2,
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        maxLength: 2,
      },
      yyyy: {
        mask: IMask.MaskedRange,
        from: 1900,
        to: 9999,
      },
    },
    format: (date: number | Date) => {
      return dateFns.format(date, dateFormatClient);
    },
    parse: (dateStr: string) => {
      return dateFns.parse(dateStr, dateFormatClient, new Date());
    },
  },
  transform: (value: string) => {
    if (!value) {
      return value;
    }
    const date = dateFns.parse(value, dateFormatClient, new Date());
    return dateFns.format(date, dateFormatApi);
  },
  maskDefault: (value: string) => {
    return dateFns.format(
      dateFns.parse(value, dateFormatApi, new Date()),
      dateFormatClient
    );
  },
});

export const cpfOrCnpjMask = masker({
  masked: {
    mask: [
      {
        mask: "000.000.000-00",
        type: "CPF",
      },
      {
        mask: "00.000.000/0000-00",
        type: "CNPJ",
      },
    ],
    dispatch: (
      appended: any,
      dynamicMasked: { compiledMasks: any[]; value: any }
    ) => {
      const cpfMask = dynamicMasked.compiledMasks.find(
        ({ type }) => type === "CPF"
      );

      const cnpjMask = dynamicMasked.compiledMasks.find(
        ({ type }) => type === "CNPJ"
      );

      if (`${dynamicMasked.value}${appended}`.length > cpfMask.mask.length) {
        return cnpjMask;
      }

      return cpfMask;
    },
  },
});

export const phoneMask = masker({
  masked: {
    mask: [
      {
        mask: "+55 00 0000-0000",
        phone: "landline",
      },
      {
        mask: "+55 00 00000-0000",
        phone: "mobile",
      },
    ],
    dispatch: (
      appended: any,
      dynamicMasked: { compiledMasks: any[]; value: any }
    ) => {
      const landlineMask = dynamicMasked.compiledMasks.find(
        ({ phone }) => phone === "landline"
      );

      const mobileMask = dynamicMasked.compiledMasks.find(
        ({ phone }) => phone === "mobile"
      );

      if (
        `${dynamicMasked.value}${appended}`.length > landlineMask.mask.length
      ) {
        return mobileMask;
      }

      return landlineMask;
    },
  },
});

export const getCurrencyMask = (currencySymbol: string) =>
  masker({
    masked: {
      mask: `${currencySymbol} num{.}cents`,
      blocks: {
        num: {
          mask: Number,
          signed: true,
          thousandsSeparator: ",",
          mapToRadix: [""],
          scale: 0,
        },
        cents: {
          mask: "00",
          normalizeZeros: true,
          padFractionalZeros: true,
        },
      },
    },
    transform: (value: any) => {
      return Number(
        getCurrencyMask(currencySymbol).unmask(value).replace(",", ".")
      );
    },
    maskDefault: (value: number) => {
      return getCurrencyMask(currencySymbol).mask(
        value.toFixed(2).replace(".", ",")
      );
    },
  });
