import { SubmitButton, TextInput } from "../../hook-form";
import { FormContainer, ToastNotification } from "../../components";
import switchAppLogo from "../../assets/images/switchapp-logo.svg";
import { Link } from "react-router-dom";
import { IHookForm, IResetPasswordRequest } from "../../interfaces-and-types";

export const View = ({
  passwordResetSuccessful,
  redirectToLogin,
  errorMessage,
  handleSubmit,
  register,
  errors,
}: IHookForm<IResetPasswordRequest> & {
  passwordResetSuccessful: boolean;
  redirectToLogin: () => void;
  errorMessage: string;
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="auth-page--container flex-container col justify-content--center align-items--center">
        <div>
          <div className="logo-container flex-container justify-content--start">
            <Link to="/">
              <img src={switchAppLogo} alt="logo" className="switchapp-logo" />
            </Link>
          </div>

          <FormContainer>
            <div className="form-container flex-container col">
              <div className="form-container__header font-ctm--bold">
                <h3>Reset Password</h3>
              </div>

              <div className="form-container__body">
                <div className="form-container__body__input-container">
                  <TextInput
                    name="new_password"
                    label="New Password"
                    type="password"
                    register={register}
                    errors={errors}
                  />
                </div>

                <div className="form-container__body__input-container">
                  <TextInput
                    name="new_password_confirmation"
                    label="Confirm New Password"
                    type="password"
                    register={register}
                    errors={errors}
                  />
                </div>

                {/* Display any form errors here */}
                {!errorMessage ? null : (
                  <div className="form-error--message">
                    <p className="switchapp-small">Error: {errorMessage}</p>
                    <p className="switchapp-small">
                      Request for another password reset link
                    </p>
                  </div>
                )}

                <div className="flex-container buttons-container">
                  <SubmitButton className=" onboarding-submit--button">
                    Submit
                  </SubmitButton>
                </div>
              </div>

              <div className="form-container__footer">
                <p className="">
                  Don't have an account? <Link to={"/signup"}>Sign up</Link>
                </p>
              </div>
            </div>
          </FormContainer>
        </div>

        {!passwordResetSuccessful ? null : (
          <div className="toast-notification-container">
            <ToastNotification
              title={"Password Reset Successfully"}
              subtitle={"Your password was successfully reset"}
              message={
                "You may now login to your account with your new password."
              }
              ariaLabel={"Password Reset Successful Toast Notification"}
              onClose={redirectToLogin}
            />
          </div>
        )}

        {/* go back to sign in page */}
        <div className="switchapp-small go-back--link mt-5 font-ctm--bold">
          <Link to={"/signin"}>Go To Login</Link>
        </div>
      </div>
    </form>
  );
};
