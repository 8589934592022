import { FieldValues, Path } from "react-hook-form";

import { InfoIcon, Toggle } from "../../components";
import { IHookFormToggleViewProps } from "./HookFormToggle";

export const View = <TFormValues extends FieldValues>(
  props: IHookFormToggleViewProps<TFormValues>
) => {
  const { name, containerClassName, errorMessage, register, ...rest } = props;

  // console.log("defaultToggled HookFormToggle: ", rest.defaultToggled);

  return (
    <Toggle
      {...rest}
      {...register(name as Path<TFormValues>)}
      containerClassName={`input-container ${containerClassName}`}
      invalid={!!errorMessage}
      invalidText={errorMessage}
    />
  );
};
