import { ReactNode } from "react";
import {
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import { useApiErrors } from "../../hooks";
import { TCarbonComponentSizeProps } from "../../interfaces-and-types";
import { getApiErrorsByFieldName } from "../../utils";
import { TInfoIconAlignment } from "../InfoIcon";

import { View } from "./View";

export interface IToggleProps {
  name: string;
  label: string;
  labelRight?: boolean;
  labelOn?: ReactNode;
  labelOff?: ReactNode;
  helperText?: string;
  infoIconText?: ReactNode;
  infoIconAlignment?: TInfoIconAlignment;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  size?: TCarbonComponentSizeProps;
  placeholder?: string;

  defaultToggled?: boolean;
  toggled?: boolean;
  onToggle?: (event: any) => void;

  // error display
  invalid?: boolean;
  invalidText?: string;
}

export function Toggle(props: IToggleProps) {
  return <View {...props} />;
}
