import { Popover, PopoverContent } from "@carbon/react";

export const View = ({
  align = "bottom",
  isOpen,
  children,
  className = "",
  popoverContent,
  popoverContentClassName = "",
  highContrast = false,
  ...rest
}) => {
  return (
    <Popover
      open={isOpen}
      align={align}
      autoAlign
      highContrast={highContrast}
      className={`popover ${className}`}
      {...rest}
    >
      {children}
      <PopoverContent className={`popover-content ${popoverContentClassName}`}>
        {popoverContent}
      </PopoverContent>
    </Popover>
  );
};
