import * as yup from "yup";

import {
  IEditGatewayPricingPerCountry,
  IEditPricingOption,
} from "../../../../interfaces-and-types";
import {
  baseChargeThresholdAction,
  volumeBasedPricingInterval,
} from "../../../../enums";

const pricingOptionValidationSchema: yup.SchemaOf<IEditPricingOption> = yup
  .object()
  .shape({
    id: yup.string().required(),

    // is_international_pricing_option: yup
    //   .boolean()
    //   .required("International pricing status is required"),

    is_flat_rate_enabled: yup
      .boolean()
      .required("Flat rate status is required"),

    flat_rate: yup.number().nullable().optional(),

    base_charge: yup.number().nullable().optional(),
    base_charge_threshold_amount: yup.number().nullable().optional(),
    base_charge_threshold_action: yup
      .mixed<baseChargeThresholdAction>()
      .oneOf(Object.values(baseChargeThresholdAction))
      .required("Base charge threshold action is required"),

    percentage_charge: yup.number().nullable().optional(),

    is_charge_cap_enabled: yup
      .boolean()
      .required("Charge cap status is required"),

    charge_cap: yup.number().nullable().optional(),
  });

export const swappSpecialPricingValidationSchema: yup.SchemaOf<IEditGatewayPricingPerCountry> =
  yup
    .object({
      id: yup.string().required(),

      payment_default_pricing_option: pricingOptionValidationSchema,
      international_payment_pricing_option: pricingOptionValidationSchema,
      is_payment_volume_pricing_enabled: yup
        .boolean()
        .required("Volume pricing status is required"),
      payment_volume_pricing_interval: yup
        .mixed<volumeBasedPricingInterval>()
        .oneOf(Object.values(volumeBasedPricingInterval))
        .required("Payment volume pricing interval is required"),

      payout_default_pricing_option: pricingOptionValidationSchema,
      is_payout_volume_pricing_enabled: yup
        .boolean()
        .required("Volume pricing status is required"),
      payout_volume_pricing_interval: yup
        .mixed<volumeBasedPricingInterval>()
        .oneOf(Object.values(volumeBasedPricingInterval))
        .required("Payout volume pricing interval is required"),

      top_up_default_pricing_option: pricingOptionValidationSchema,
      is_top_up_volume_pricing_enabled: yup
        .boolean()
        .required("Volume pricing status is required"),
      top_up_volume_pricing_interval: yup
        .mixed<volumeBasedPricingInterval>()
        .oneOf(Object.values(volumeBasedPricingInterval))
        .required("TopUp volume pricing interval is required"),

      transfer_default_pricing_option: pricingOptionValidationSchema,
      is_transfer_volume_pricing_enabled: yup
        .boolean()
        .required("Volume pricing status is required"),
      transfer_volume_pricing_interval: yup
        .mixed<volumeBasedPricingInterval>()
        .oneOf(Object.values(volumeBasedPricingInterval))
        .required("Transfer volume pricing interval is required"),
    })
    .required();
