import { View } from "./View";

export interface ISkeletonLoaderProps {
  type: "text" | "placeholder" | "icon";
  className?: string;
}

export const SkeletonLoader = (props: ISkeletonLoaderProps) => {
  return <View {...props} />;
};
