import { appConfig } from "../../configs";

const apiVersion = appConfig.apiVersion;

const adminIdentityApiBaseUrlVersionParam = appConfig.adminIdentityApiBaseUrl;
export const adminIdentityApiBaseUrl =
  adminIdentityApiBaseUrlVersionParam.replace("{apiVersion}", apiVersion);

const adminApiBaseUrlVersionParam = appConfig.adminApiBaseUrl;
export const adminApiBaseUrl = adminApiBaseUrlVersionParam.replace(
  "{apiVersion}",
  apiVersion
);
