import { Column } from "@carbon/react";

import { CustomNavLink } from "../CustomNavLink";
import { truncateStringWithEllipsis } from "../../utils";
import { SwitchAppButton } from "../SwitchAppButton";

export const View = ({
  setActiveTenant,
  activeTenant,
  activeTenantId,
  pageTabNavLinks,
  boundedUnderline = false,
  className = "" /*search*/,
}) => {
  return (
    <>
      {/* Display side-by-side tabs for lg screens */}
      <Column lg={16} md={8} sm={0} className={`page-tab--links ${className}`}>
        <div className="flex-container justify-content--between align-items--end">
          <div>
            {pageTabNavLinks.map((link) => (
              <CustomNavLink
                key={link.route}
                // to={`${link.route}${search}`}
                to={`${link.route}`}
                className="page-tab--nav-link"
              >
                <span
                  /* className="link-name"*/ style={{ display: "inline-block" }}
                >
                  {link.name}
                </span>
              </CustomNavLink>
            ))}
          </div>

          <div className="merchant-id">Admin Email: {activeTenantId}</div>
        </div>

        <div
          className={`page-tab--underline ${
            boundedUnderline ? "bounded-underline" : ""
          }`}
        />
      </Column>

      <Column lg={16} md={8} sm={4}>
        {!activeTenant ? null : (
          <div
            title={`Selected Merchant: ${activeTenant.merchant_name.toUpperCase()} (${
              activeTenant.id
            })`}
            className="active-tenant-display font-ctm--bold flex-container justify-content--between align-items--center"
          >
            <div>
              <span className="">Filtering by Merchant: </span>
              <span className="ml-5 text-danger">
                {activeTenant.id} &nbsp; - &nbsp;
                {truncateStringWithEllipsis(
                  activeTenant.merchant_name.toUpperCase(),
                  100
                )}
              </span>
            </div>
            <div className="">
              <SwitchAppButton
                size="sm"
                kind={"danger"}
                className=""
                title={"clear the selected merchant"}
                onClick={() => setActiveTenant(null)}
              >
                Clear Filter
              </SwitchAppButton>
            </div>
          </div>
        )}
      </Column>
      {/* Display single line for sm screens */}
      {/* <Column
        lg={0}
        md={0}
        sm={4}
        className="page-tab--links small-screen--only"
      >
        <Grid>
          {pageTabNavLinks.map((link) => (
            <Column sm={4}>
              <NavLink
                key={link.route}
                to={link.route}
                className="page-tab--nav-link"
              >
                - {link.name}
              </NavLink>
            </Column>
          ))}
        </Grid>
      </Column> */}
    </>
  );
};
