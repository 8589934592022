import { useEffect, useState } from "react";

export function useDebounce(
  debouncePeriodMs: number = 100,
  dependencyValue: any = null
): boolean {
  const [debouncePeriodElapsed, setDebouncePeriodElapsed] =
    useState<boolean>(false);

  useEffect(() => {
    const timeoutId = setTimeout(
      () => setDebouncePeriodElapsed(true),
      debouncePeriodMs
    );

    return () => {
      timeoutId && clearTimeout(timeoutId);
      setDebouncePeriodElapsed(false);
    };
  }, [dependencyValue]);

  return debouncePeriodElapsed;

  // return {
  //   debouncePeriodElapsed,
  //   resetDebounce: () => setDebouncePeriodElapsed(false),
  // };
}
