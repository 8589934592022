import { IViewVolumePricingOption } from "../interfaces-and-types";

export const orderByVolumePricingThresholdParams = (
  volPricingOptions: IViewVolumePricingOption[]
) =>
  volPricingOptions
    .sort((op1, op2) =>
      op1.minimum_amount === null || op2.minimum_amount === null
        ? 0
        : op1.minimum_amount > op2.minimum_amount
        ? 1
        : op1.minimum_amount < op2.minimum_amount
        ? -1
        : 0
    )
    .sort((op1, op2) =>
      op1.minimum_count === null || op2.minimum_count === null
        ? 0
        : op1.minimum_count > op2.minimum_count
        ? 1
        : op1.minimum_count < op2.minimum_count
        ? -1
        : 0
    );
