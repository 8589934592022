import { useEffect, useState } from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

import { appConfig } from "../../configs";
import { TSummaryPeriod, filterConstants } from "../../constants";
import { useActiveCurrency, useQueryString } from "../../hooks";
import { IViewTransactionChartDto } from "../../interfaces-and-types";
import { chartTypes, transactionStatus } from "../../enums";
import { AxisOptionsBase } from "react-charts";

import { View } from "./View";

export interface ISummarySectionProps {
  activeCurrency: string;
  // summaryPeriod: TSummaryPeriod;
}

export interface ISummarySectionViewProps<TSummaryOverview> {
  activeCurrency: string;

  summaryToDisplay: TSummaryOverview | null;
  isLoadingSummary: boolean;

  summaryPeriod: TSummaryPeriod;
  setSummaryPeriod: (period: TSummaryPeriod) => void;
}

export interface IHomeViewProps {
  activeCurrency: string;
  navigateToRoute: (route: string) => void;

  // use a context to sum profits
  // allSectionsProfitSummaryColumData: IQuickSummaryColumDataProps[];

  chartType: AxisOptionsBase["elementType"];
  setChartType: (chartType: AxisOptionsBase["elementType"]) => void;
  setStartChartPeriodFromDatePicker: (date: string | number | Date) => void;
  setEndChartPeriodFromDatePicker: (date: string | number | Date) => void;
}

export const sumChartValues = (chartValues: IViewTransactionChartDto[]) =>
  chartValues?.reduce((acc, curr) => acc + curr.amount, 0);

const tabValues = {
  monthly: "mnth",
  yearly: "yr",
};

export const Home = () => {
  const {
    // isLoading: isLoadingSupportedCurrencies,
    activeCurrency,
  } = useActiveCurrency();

  const location = useLocation();
  const query = useQueryString();
  const monthOrYearTabSelected = query.get(
    filterConstants.monthlyOrYearlyStatusKey
  );
  const startDate = query.get(filterConstants.startDateKey);
  const endDate = query.get(filterConstants.endDateKey);

  const [startChartPeriod, setStartChartPeriod] = useState(
    moment().subtract(1, "month").format(appConfig.dateFormat1)
  );
  const [endChartPeriod, setEndChartPeriod] = useState(
    moment().format(appConfig.dateFormat1)
  );

  const [gatewayChartTxStatus, setGatewayChartTxStatus] = useState(
    transactionStatus.successful
  );

  const [chartType, setChartType] = useState<AxisOptionsBase["elementType"]>(
    chartTypes.line
  );

  // set the dashboard url at the address bar on first load
  useEffect(() => {
    if (location.pathname !== "/") navigate("/");
  }, []);

  // useEffect to set the correct start and end chart periods when the tab is changed
  useEffect(() => {
    // console.log(startDate, endDate);
    if (startDate !== null) {
      setStartChartPeriod(startDate);
      setEndChartPeriod(moment().format(appConfig.dateFormat1));
    } else {
      if (monthOrYearTabSelected === tabValues.monthly) {
        setStartChartPeriod(
          moment().subtract(1, "month").format(appConfig.dateFormat1)
        );
        setEndChartPeriod(moment().format(appConfig.dateFormat1));
      } else if (monthOrYearTabSelected === tabValues.yearly) {
        setStartChartPeriod(
          moment().subtract(1, "year").format(appConfig.dateFormat1)
        );
        setEndChartPeriod(moment().format(appConfig.dateFormat1));
      }
    }
  }, [startDate, endDate]);

  const setStartChartPeriodFromDatePicker = (
    startDateToSet: string | number | Date
  ) =>
    setStartChartPeriod(moment(startDateToSet).format(appConfig.dateFormat1));

  const setEndChartPeriodFromDatePicker = (
    endDateToSet: string | number | Date
  ) => setEndChartPeriod(moment(endDateToSet).format(appConfig.dateFormat1));

  const navigate = useNavigate();

  const navigateToRoute = (route: string) => {
    navigate(route);
  };

  return (
    <View
      activeCurrency={activeCurrency || ""}
      navigateToRoute={navigateToRoute}
      chartType={chartType}
      setChartType={setChartType}
      setStartChartPeriodFromDatePicker={setStartChartPeriodFromDatePicker}
      setEndChartPeriodFromDatePicker={setEndChartPeriodFromDatePicker}
    />
  );
};
