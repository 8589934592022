import { ISwAppPayoutsApi } from "../../interfaces-and-types";

type TPayoutsUrls = {
  [key in keyof ISwAppPayoutsApi]: string;
};

const rootPayoutsUrl = "/payouts";

export const payoutsUrls: TPayoutsUrls = {
  createPayout: `${rootPayoutsUrl}`,
  getPayouts: `${rootPayoutsUrl}`,
  getPayoutById: `${rootPayoutsUrl}/id`,
  retryFailedPayoutById: `${rootPayoutsUrl}/retry-payout/id`,
};
