import { ISwAppOnboardingApi } from "../../interfaces-and-types";

type TOnboardingUrls = { [key in keyof ISwAppOnboardingApi]: string };

const rootOnboardingUrl = "/onboarding";

export const onboardingUrls: TOnboardingUrls = {
  getOnboardingStatus: `${rootOnboardingUrl}/status`,

  saveOnboardingStep1: `${rootOnboardingUrl}/step1`,
  saveOnboardingStep2: `${rootOnboardingUrl}/step2`,
  saveOnboardingStep3: `${rootOnboardingUrl}/step3`,
  saveOnboardingStep4: `${rootOnboardingUrl}/step4`,
  saveOnboardingStep5: `${rootOnboardingUrl}/step5`,
  requestAccountActivation: `${rootOnboardingUrl}/request-activation`,

  // get onboarding values
  getBusinessTypes: `${rootOnboardingUrl}/business-types`,
  getRevenueBrackets: `${rootOnboardingUrl}/revenue-brackets`,
};
