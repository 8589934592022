import { useEffect } from "react";
import * as yup from "yup";

import { View } from "./View";
import {
  useApiErrors,
  useAuth,
  useHookForm,
  useAdminIdentityMutation,
  useAdminApiMutation,
} from "../../hooks";
import {
  ILoginRequest,
  ILoginResponse,
  IResendConfirmationEmailRequest,
} from "../../interfaces-and-types";
import { getApiErrorsByFieldName, globalHandlerUtils } from "../../utils";

export const SignIn = () => {
  const { signIn } = useAuth();

  const {
    // isLoading,
    isSuccess,
    data: apiResponse,
    mutate: login,
  } = useAdminIdentityMutation<ILoginRequest, ILoginResponse>(
    "login"
  ).usePostMutation;

  const {
    isLoading: isLoadingResendVerificationEmail,
    isSuccess: isSuccessResendVerificationEmail,
    mutate: resendConfirmationEmail,
  } = useAdminIdentityMutation<IResendConfirmationEmailRequest, null>(
    "resendConfirmationEmail"
  ).usePostMutation;

  const resendVerificationLink = () =>
    resendConfirmationEmail({ email: formEmailValue });

  useEffect(() => {
    if (isSuccess) {
      const isLoginSuccessful = apiResponse.token !== undefined;

      if (isLoginSuccessful) {
        signIn(
          // apiResponse.staffDepartments,
          apiResponse.token,
          apiResponse.user
        );
      }
    }
  }, [isSuccess]);

  const initialFormValues: ILoginRequest = {
    email: "",
    password: "",
    remember_me: false,
  };

  const loginSchema: yup.SchemaOf<ILoginRequest> = yup
    .object({
      email: yup.string().email().required(),
      password: yup.string().required(),
      remember_me: yup.boolean().required(),
    })
    .required();

  const handleApiSubmit = (data: ILoginRequest) => {
    login(data);
  };

  const handleError = (errors: any) => globalHandlerUtils.handleError(errors);

  const { onFormSubmit, errors, register, watch } = useHookForm({
    initialFormValues,
    validationSchema: loginSchema,
    onValid: handleApiSubmit,
    onInvalid: handleError,
  });

  const formEmailValue = watch("email");
  const { apiErrors } = useApiErrors();

  const apiErrorMessage = getApiErrorsByFieldName("email", apiErrors);
  const emailNotConfirmed =
    !!formEmailValue &&
    !!apiErrorMessage &&
    apiErrorMessage.indexOf("confirm") >= 0;

  return (
    <View
      formEmailValue={formEmailValue}
      emailNotConfirmed={emailNotConfirmed}
      resendVerificationLink={resendVerificationLink}
      handleSubmit={onFormSubmit}
      register={register}
      errors={errors}
    />
  );
};
