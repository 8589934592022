import { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { quickTableConfig } from "../../configs";
import { filterConstants } from "../../constants";
import { defaultApiPagedMeta } from "../../contexts";
import {
  useApiPagedMeta,
  useDebounce,
  useGetPageQueryParams,
  useQueryString,
  useQueryStringFilter,
} from "../../hooks";
import { ITableHeader } from "../../interfaces-and-types";

import { View } from "./View";

export interface IQuickTableProps<ITableData> {
  // identity: used to perform cache & storage
  id: string;

  // title
  tableTitle?: string;
  tableSubtitle?: string;

  // data
  data: ITableData[];
  headers: ITableHeader[];
  isLoading: boolean;
  isError: boolean;
  itemCount: number;
  isPaginatedOnFrontend?: boolean;

  // display formatting:  reformat the data to match table headers
  displayFormatter?: (item: ITableData) => object;

  // styling
  tableClassName?: string;

  // render details
  renderDetail?: (item: ITableData) => ReactNode;

  // pagination
  availablePageSizes?: number[];

  // filtering
  toolbarTabs?: ReactNode;

  // extra buttons
  extraButtons?: ReactNode;
  onExport?: () => void;
  openCreateNewModal?: () => void;
  createNewButtonText?: string;
  detailsModalKeySelector?: (rowData: ITableData) => string | number | object; // get the key for the details modal
  openDetailsModal?: (data: any) => void;
  rowTitleSelector?: (rowData: ITableData) => ReactNode; // get the key for the details modal

  // DISABLE CLICK ON CERTAIL COLUMNS
  disableClickOnColumns?: string[];
  disableClickOnTargetClassNames?: string[];
}

export function QuickTable<ITableData>(props: IQuickTableProps<ITableData>) {
  const { id, data, isPaginatedOnFrontend, displayFormatter, renderDetail } =
    props;
  const location = useLocation();

  const {
    page_meta: page_metaInfoFromApi,
    clearApiPageMeta, // clear when route changes
    setApiPageMeta,
  } = useApiPagedMeta();

  // console.log("page_metaInfoFromApi in Quicktable: ", page_metaInfoFromApi);

  const formattedTableId = id.replace(" ", "_");
  const defaultPageSize = quickTableConfig.availablePageSizes[0];

  const { setQueryStringFilter } = useQueryStringFilter([
    [filterConstants.searchKey, ""],
    [filterConstants.pageSizeKey, defaultPageSize.toString()],
  ]);

  const query = useQueryString();
  const { page, page_size, search } = useGetPageQueryParams();

  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1); // TODO: get the default value from url
  const [currentPageSize, setCurrentPageSize] = useState(
    page_size ? Number(page_size) : defaultPageSize
  );

  // NOTE: use query string instead
  // const [transactionPeriod, setTransactionPeriod] =
  //   useState<TTransactionPeriodSelect>(
  //     (query.get(
  //       filterConstants.transactionPeriodKey
  //     ) as TTransactionPeriodSelect) ||
  //       filterConstants.transactionPeriods[0].value
  //   );

  // clear page params on route change
  useEffect(() => {
    // clear all filtering from previous route
    setApiPageMeta(defaultApiPagedMeta);
    // clearApiPageMeta();
    // clearAllQuerystringFilters();
  }, [location.pathname]);

  // set actual page returned from api when page details is different from requested
  useEffect(() => {
    if (page_metaInfoFromApi?.page && page_metaInfoFromApi.page != currentPage)
      setCurrentPage(page_metaInfoFromApi?.page);
  }, [page_metaInfoFromApi?.page]);

  // set actual page_size returned from api when page_size details is different from requested
  useEffect(() => {
    if (
      page_metaInfoFromApi?.page_size &&
      page_metaInfoFromApi?.page_size != currentPageSize
    )
      setCurrentPageSize(page_metaInfoFromApi?.page_size);
  }, [page_metaInfoFromApi?.page_size]);

  // set current page on url when page changed in table
  useEffect(() => {
    setQueryStringFilter(
      filterConstants.currentPageKey,
      currentPage.toString()
    );
  }, [currentPage]);

  // set page size on url
  useEffect(() => {
    setQueryStringFilter(
      filterConstants.pageSizeKey,
      currentPageSize.toString()
    );
  }, [currentPageSize]);

  // set transactionPeriod on url
  // useEffect(() => {
  //   setQueryStringFilter(
  //     filterConstants.transactionPeriodKey,
  //     transactionPeriod.toString()
  //   );
  // }, [transactionPeriod]);

  // debounce loading skeleton
  const debouncedLoadingSkeleton = useDebounce(300, props.isLoading);

  // TODO: debounce this to prevent multiple fetches on input
  const [searchStringState, setSearchStringState] = useState<string | null>(
    null
  );
  const debouncePeriodElapsed = useDebounce(1500, searchStringState);

  useEffect(() => {
    const filterWithSearch = (searchParams: string) => {
      const trimmedSearchParams = searchParams?.trim()?.toLowerCase();
      if (!props.isPaginatedOnFrontend) {
        return setQueryStringFilter(
          filterConstants.searchKey,
          trimmedSearchParams
        );
      }

      // for client-side managed data
      // return data.filter((d) =>
      //   Object.values(d).some(
      //     (value) =>
      //       value && value.toString().toLowerCase().includes(trimmedSearchParams)
      //   )
      // );
    };

    if (debouncePeriodElapsed) filterWithSearch(searchStringState || "");
  }, [searchStringState, debouncePeriodElapsed]);

  // use firstRowIndex to form query or slice data
  const paginatedData = !isPaginatedOnFrontend
    ? data
    : data.slice(firstRowIndex, firstRowIndex + currentPageSize);

  const displayFormattedData = !displayFormatter
    ? paginatedData
    : paginatedData.map((item) => displayFormatter(item));

  return (
    <View
      {...props}
      id={formattedTableId}
      isLoading={props.isLoading /* && debouncedLoadingSkeleton*/}
      isError={props.isError}
      data={displayFormattedData}
      renderDetail={renderDetail}
      currentPageSize={currentPageSize}
      setCurrentPage={setCurrentPage}
      setCurrentPageSize={setCurrentPageSize}
      setFirstRowIndex={setFirstRowIndex}
      // transactionPeriod={transactionPeriod}
      // onTransactionPeriodChange={setTransactionPeriod}
      onToolbarSearchInputChange={setSearchStringState}
      defaultSearchValue={search}
      toolbarTabs={props.toolbarTabs}
      extraButtons={props.extraButtons}
      onExport={props.onExport}
      openCreateNewModal={props.openCreateNewModal}
      createNewButtonText={props.createNewButtonText}
      disableClickOnColumns={props.disableClickOnColumns}
      disableClickOnTargets={props.disableClickOnTargetClassNames}
    />
  );
}
