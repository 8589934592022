import { TextInput as CarbonTextInput } from "@carbon/react";
import { InfoIcon } from "../../components";
// import { Controller } from "react-hook-form";

import hidePasswordIcon from "../../assets/icons/eye-slash.svg";
import showPasswordIcon from "../../assets/icons/eye.svg";

export const View = (props) => {
  const {
    showPassword,
    toggleShowPassword,

    control,
    type,
    errorMessage,
    hideErrorMessage,
    register,
    iconRight,
    iconRightClassName,
    infoIconText,
    onChange,
    ...rest
  } = props;

  const hookFormProps = register(props.name);
  const { onChange: onChangeHookForm, ...restHookFormProps } = hookFormProps;

  return (
    <div className={`input-container text-input ${props.containerClassName}`}>
      <CarbonTextInput
        // helperText={props.helperText}
        // placeholder={props.placeholder}
        onChange={(e) => {
          onChange && onChange(e);
          onChangeHookForm(e);
        }}
        {...restHookFormProps}
        {...rest}
        id={props.name}
        labelText={
          !props.label && !infoIconText ? (
            ""
          ) : (
            <div className="flex-container">
              <div className="flex-box">{props.label} </div>
              {infoIconText ? (
                <div className="ml-3">
                  <InfoIcon message={infoIconText} />{" "}
                </div>
              ) : null}
            </div>
          )
        }
        type={type}
        invalid={!!errorMessage}
        invalidText={hideErrorMessage ? "" : errorMessage}
        className={`${props.className} switchapp-themed ${
          hideErrorMessage ? "hide-error-message" : ""
        }`}
      />

      {(type === "password" || showPassword) && (
        <button
          type="button"
          className={`show-password-toggle`}
          title={`${showPassword ? "hide" : "show"} password`}
          onClick={toggleShowPassword}
        >
          <img
            alt="toggle show password"
            src={showPassword ? hidePasswordIcon : showPasswordIcon}
            className="show-password-toggle--icon"
          />
        </button>
      )}

      {iconRight && (
        <div className={`input-icon-right ${iconRightClassName}`}>
          {iconRight}
        </div>
      )}

      {/* <Controller
        render={({ field }) => (
          <CarbonTextInput
            // helperText={props.helperText}
            // placeholder={props.placeholder}
            {...rest}
            {...field}
            id={props.name}
            labelText={props.label}
            invalid={!!errorMessage}
            invalidText={errorMessage}
            className={`${props.className} switchapp-themed`}
          />
        )}
        control={control}
        name={props.name}
        defaultValue={props.defaultValue}
      /> */}

      {/* <Controller
        control={control}
        rules={{
          maxLength: 100,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <CarbonTextInput
            // helperText={props.helperText}
            // placeholder={props.placeholder}
            {...rest}
            id={props.name}
            labelText={props.label}
            invalid={!!errorMessage}
            invalidText={errorMessage}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            className={`${props.className} switchapp-themed`}
          />
        )}
        name={props.name}
      /> */}
    </div>
  );
};
