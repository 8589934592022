import { ReactNode } from "react";
import { View } from "./View";

export interface StructuredListProps {
  headers: ReactNode;
  structuredListBodyClassName?: string;
  rows: any;
  isCondensed?: boolean;
}

export const StructuredList = (props: StructuredListProps) => {
  return <View {...props} />;
};
