import { useActivityBlocker } from "../../hooks/useActivityBlocker";
import { View } from "./View";

export interface IActivityBlockerViewProps {
  isBlocked: boolean;
  messages: string[];
}

export const ActivityBlocker = () => {
  const { isBlocked, messages } = useActivityBlocker();

  return <View isBlocked={isBlocked} messages={messages} />;
};
