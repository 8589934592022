import { IPageTabNavLink } from "../interfaces-and-types";

export const balancesNavLinks: IPageTabNavLink[] = [
  {
    name: "Overview",
    route: "",
  },
  {
    name: "Top-Ups",
    route: "top-ups",
  },
  {
    name: "Payouts",
    route: "payouts",
  },
];
