import { ReactNode } from "react";
import {
  StatusColors,
  TCarbonComponentSizeProps,
} from "../../interfaces-and-types";

import { View } from "./View";

export type TInfoIconAlignment = "bottom" | "top" | "left" | "right";

export interface IInfoIconProps {
  statusColor?: StatusColors;
  align?: TInfoIconAlignment;
  defaultOpen?: boolean;
  size?: TCarbonComponentSizeProps;
  label?: string;
  message: ReactNode;
  className?: string;
  toggleTipActions?: ReactNode;
}

export const InfoIcon = (props: IInfoIconProps) => {
  return (
    <View
      {...props}
      statusColor={props.statusColor}
      size={props.size}
      label={props.label}
      className={props.className}
      toggleTipActions={props.toggleTipActions}
    />
  );
};
