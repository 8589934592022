import { Button, InlineLoading, Loading } from "@carbon/react";

export const View = ({
  isBlocked,
  showChildrenWithLoadingIcon = false,
  className = "",
  children,
  ...rest
}) => {
  return (
    <Button
      type="submit"
      disabled={isBlocked}
      className={`submit-button flex-box switchapp-themed ${className} ${
        isBlocked ? "submit-button--disabled" : ""
      }`}
      {...rest}
    >
      {isBlocked ? (
        <div className="flex-container align-items--center">
          <div>
            <InlineLoading description="" />
          </div>
          {showChildrenWithLoadingIcon ? children : null}
        </div>
      ) : (
        children
      )}
    </Button>
  );
};
