import {
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import { IToggleProps } from "../../components/Toggle/Toggle";
import { useApiErrors } from "../../hooks";
import { TCarbonComponentSizeProps } from "../../interfaces-and-types";
import { getApiErrorsByFieldName } from "../../utils";

import { View } from "./View";

export interface IHookFormToggleViewProps<TFormValues extends FieldValues>
  extends Omit<IHookFormToggleProps<TFormValues>, "errors"> {
  errorMessage: string;
}

interface IHookFormToggleProps<TFormValues extends FieldValues>
  extends IToggleProps {
  toggled: boolean;
  onToggle: () => void;
  register: UseFormRegister<TFormValues>;
  errors: FieldErrorsImpl<DeepRequired<TFormValues>>;
}

export function HookFormToggle<TFormValues extends FieldValues>(
  props: IHookFormToggleProps<TFormValues>
) {
  const { errors, ...rest } = props;

  const { apiErrors } = useApiErrors();

  const apiErrorMessage = getApiErrorsByFieldName(props.name, apiErrors);
  const hookFormErrorMessage =
    errors[props.name] && errors[props.name]?.["message"];

  const combinedErrors = hookFormErrorMessage || apiErrorMessage || "";

  return <View errorMessage={combinedErrors as string} {...rest} />;
}
