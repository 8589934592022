import { ReactNode } from "react";
import {
  useAdminApiQueryWithParams,
  useReactRouterLink,
} from "../../../../hooks";
import { useParams } from "react-router-dom";
import {
  IModalFormProps,
  IViewCustomer,
} from "../../../../interfaces-and-types";

import { View } from "./View";
export interface ICustomerDetailsModalViewProps extends IModalFormProps {
  modalData: IViewCustomer | null;
  paymentData: ReactNode[];
  invoiceData: ReactNode[];
  subscriptionData: ReactNode[];
}

export const CustomerDetailsModal = () => {
  const { location, navigateToRoutePreserveParams } = useReactRouterLink();

  const closeModal = () => {
    navigateToRoutePreserveParams(
      location.pathname.replace(`/${customerId}/details`, "")
    );
  };

  const { customerId } = useParams();

  const {
    isLoading,
    // isSuccess,
    isError,
    data: paymentLink,
  } = useAdminApiQueryWithParams<{ id: string }, IViewCustomer>(
    [
      "getCustomerById",
      {
        id: customerId!, // NOTE: used to enforce react query cache with correct queryKey
      },
    ],
    {
      enabled: !!customerId,
    },
    (urlTemplate) => urlTemplate.replace("id", customerId!)
  );

  return (
    <View
      isLoadingModalData={isLoading}
      isErrorModalData={isError}
      isModalOpen={!!customerId}
      closeModal={closeModal}
      modalData={paymentLink || null}
      paymentData={[]}
      invoiceData={[]}
      subscriptionData={[]}
    />
  );
};
