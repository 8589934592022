import { useEffect } from "react";

import {
  useHookForm,
  useAdminApiMutation,
  useAdminApiQueryWithParams,
  useReactRouterLink,
} from "../../../../hooks";
import {
  IHookForm,
  IModalFormProps,
  IEditGatewayPricingPerCountry,
  IViewGatewayPricingPerCountry,
  IViewMerchant,
  IAddEditPricingOptionBase,
} from "../../../../interfaces-and-types";
import { globalHandlerUtils } from "../../../../utils";

import { View } from "./View";
import { swappSpecialPricingValidationSchema } from "./validationSchema";
import { volumeBasedPricingInterval } from "../../../../enums";
import { defaultEditPricingOption } from "./defaults";
import { useParams } from "react-router-dom";
import { UseFormSetValue } from "react-hook-form";

export type TPricingOptionType = "payment" | "topup" | "transfer" | "payout";

export function clearFlatRateIfNotEnabled<T extends IAddEditPricingOptionBase>(
  pricingOption: T
): T {
  return {
    ...(!pricingOption.is_flat_rate_enabled
      ? {
          ...pricingOption,
          flat_rate: null,
        }
      : pricingOption),
  };
}

export interface IManageSpecialPricingFormViewProps
  extends IHookForm<IEditGatewayPricingPerCountry>,
    IModalFormProps {
  merchant: IViewMerchant | null;
  specialPricing: IViewGatewayPricingPerCountry | null;
  // navigateToAddSpecialVolumePricingOption: (
  //   pricingOptionType: TPricingOptionType
  // ) => void;
  isLoadingDeleteSpecialPricing: boolean;
  deleteSpecialPricing: () => void;

  setValue: UseFormSetValue<IEditGatewayPricingPerCountry>;
  formValues: IEditGatewayPricingPerCountry;
}

// interface IEditPricingFormHelper {}

export const ManageSpecialPricingForm = () =>
  // props?: ISpecialPricingModalViewProps // props may be null if page is accessed by directly & not by navigating from details page
  {
    const { location, navigate, navigateToRoutePreserveParams } =
      useReactRouterLink();

    const { id: merchantId, pricing_id } = useParams();

    const {
      isLoading: isLoadingMerchant,
      // isSuccess: isSuccessMerchant,
      isError: isErrorMerchant,
      data: merchantFromApi,
    } = useAdminApiQueryWithParams<{ id: string }, IViewMerchant>(
      [
        "getMerchantById",
        {
          id: merchantId!, // NOTE: used to enforce react query cache with correct queryKey
        },
      ],
      {
        enabled: !!merchantId,
      },
      (urlTemplate) => urlTemplate.replace("id", merchantId!)
    );

    const {
      isLoading,
      // isSuccess,
      isError,
      data: specialPricingFromApi,
    } = useAdminApiQueryWithParams<
      { id: string },
      IViewGatewayPricingPerCountry
    >(
      [
        "getSpecialMerchantPricingById",
        {
          id: pricing_id!,
        },
      ],
      {
        enabled: !!merchantId && !!pricing_id,
      },
      (urlTemplate) =>
        urlTemplate
          .replace("{merchant_id}", merchantId!)
          .replace("{id}", pricing_id!)
    );

    const merchant = /*props?.merchant ||*/ merchantFromApi;
    const specialPricing = /*props?.specialPricing ||*/ specialPricingFromApi;

    useEffect(() => {
      if (!!specialPricing) {
        reset(specialPricing);
      }
    }, [!!specialPricing]);

    const navigateToMerchantDetails = () => {
      navigateToRoutePreserveParams(
        location.pathname.replace(`/special-pricing/${pricing_id}/manage`, "")
      );
    };

    const navigateToPricingDetails = () => {
      navigateToRoutePreserveParams(location.pathname.replace(`/manage`, ""));
    };

    const {
      isSuccess: isSuccessEditSpecialPricing,
      mutate: editSpecialPricing,
    } = useAdminApiMutation<
      IEditGatewayPricingPerCountry,
      IViewGatewayPricingPerCountry
    >(
      "editSpecialMerchantPricing",
      ["getSpecialMerchantPricingById"],
      {
        /* axios config */
      },
      (urlTemplate) =>
        urlTemplate
          .replace("{merchant_id}", merchantId!)
          .replace("{id}", pricing_id!)
    ).usePutMutation;

    const {
      isSuccess: isSuccessDeleteSpecialPricing,
      isLoading: isLoadingDeleteSpecialPricing,
      mutate: deleteSpecialPricing,
    } = useAdminApiMutation<void, void>(
      "deleteSpecialMerchantPricing",
      ["getSpecialMerchantPricingById", "getMerchantById"],
      {
        /* axios config */
      },
      (urlTemplate) =>
        urlTemplate
          .replace("{merchant_id}", merchantId!)
          .replace("{id}", pricing_id!)
    ).useDeleteMutation;

    useEffect(() => {
      if (isSuccessEditSpecialPricing) {
        reset();
        navigateToPricingDetails();
      }
    }, [isSuccessEditSpecialPricing]);

    useEffect(() => {
      if (isSuccessDeleteSpecialPricing) {
        navigate(`/merchants/${merchantId}`);
      }
    }, [isSuccessDeleteSpecialPricing]);

    const initialFormValues: IEditGatewayPricingPerCountry = {
      id: "",

      // payment
      payment_default_pricing_option: defaultEditPricingOption,
      international_payment_pricing_option: defaultEditPricingOption,
      is_payment_volume_pricing_enabled: false,
      payment_volume_pricing_interval: volumeBasedPricingInterval.always,

      // payouts
      payout_default_pricing_option: defaultEditPricingOption,
      is_payout_volume_pricing_enabled: false,
      payout_volume_pricing_interval: volumeBasedPricingInterval.always,

      // topups
      top_up_default_pricing_option: defaultEditPricingOption,
      is_top_up_volume_pricing_enabled: false,
      top_up_volume_pricing_interval: volumeBasedPricingInterval.always,

      // transfers
      transfer_default_pricing_option: defaultEditPricingOption,
      is_transfer_volume_pricing_enabled: false,
      transfer_volume_pricing_interval: volumeBasedPricingInterval.always,
    };

    const handleApiSubmit = (data: IEditGatewayPricingPerCountry) => {
      const dataToSubmit: IEditGatewayPricingPerCountry = {
        ...data,
        payment_default_pricing_option: clearFlatRateIfNotEnabled(
          data.payment_default_pricing_option
        ),
        payout_default_pricing_option: clearFlatRateIfNotEnabled(
          data.payout_default_pricing_option
        ),
        top_up_default_pricing_option: clearFlatRateIfNotEnabled(
          data.top_up_default_pricing_option
        ),
        transfer_default_pricing_option: clearFlatRateIfNotEnabled(
          data.transfer_default_pricing_option
        ),
      };

      editSpecialPricing(dataToSubmit);
    };

    const handleError = (errors: any) => globalHandlerUtils.handleError(errors);

    const { onFormSubmit, errors, register, reset, setValue, watch } =
      useHookForm({
        initialFormValues,
        validationSchema: swappSpecialPricingValidationSchema,
        onValid: handleApiSubmit,
        onInvalid: handleError,
      });

    const formValues = watch();

    // const navigateToAddSpecialVolumePricingOption = (
    //   pricingOptionType: TPricingOptionType
    // ) => {
    //   navigate(`add-${pricingOptionType}-volume-pricing`);
    // };

    return (
      <View
        isModalOpen
        merchant={merchant || null}
        specialPricing={specialPricing || null}
        closeModal={navigateToMerchantDetails}
        // navigateToAddSpecialVolumePricingOption={
        //   navigateToAddSpecialVolumePricingOption
        // }
        isLoadingDeleteSpecialPricing={isLoadingDeleteSpecialPricing}
        deleteSpecialPricing={deleteSpecialPricing}
        setValue={setValue}
        handleSubmit={onFormSubmit}
        register={register}
        errors={errors}
        formValues={formValues}
      />
    );
  };
