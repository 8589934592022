import qs from "query-string";
import {
  ParamKeyValuePair,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useQueryString } from "./useQueryString";

export const useQueryStringFilter = (defaultInit: ParamKeyValuePair[]) => {
  let [, setSearchParams] = useSearchParams(defaultInit);

  const location = useLocation();
  const queryParam = qs.parse(location.search);

  const query = useQueryString();

  const setQueryStringFilter = (queryKey: string, value: string) => {
    const queryValue = query.get(queryKey);
    if (!value && (queryValue === null || queryValue?.trim() === "")) return;

    const newQueryParam = {
      ...queryParam,
      [queryKey]: value,
    };

    setSearchParams(qs.stringify(newQueryParam), { replace: true });
  };

  const seturlQueryStringFilter = (urlParam: object, useFilter = false) => {
    const newQueryParam = {
      ...queryParam,
      ...urlParam,
    };
    // console.log("useQueryStringFilter urlParam:", qs.stringify(urlParam));
    // console.log(
    //   "useQueryStringFilter newQueryParam:",
    //   qs.stringify(newQueryParam)
    // );

    setSearchParams(qs.stringify(newQueryParam), { replace: true });
  };

  const clearQueryStringFilter = (queryKey: string) => {
    const {
      // [queryKey],
      ...restQueryParams
    } = queryParam;
    // location.search.replace(queryKey, null);

    setSearchParams(qs.stringify(restQueryParams), { replace: true });
  };

  return {
    setQueryStringFilter,
    clearQueryStringFilter,
    seturlQueryStringFilter,
  };
};
