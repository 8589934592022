import { ReactNode, useState } from "react";
import {
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import { useApiErrors } from "../../hooks";
import { TCarbonComponentSizeProps } from "../../interfaces-and-types";
import { getApiErrorsByFieldName, getCurrencyMask } from "../../utils";

import { View } from "./View";

export interface ITextInputProps<TFormValues extends FieldValues> {
  name: string;
  label: ReactNode;
  helperText?: string;
  infoIconText?: ReactNode;
  disabled?: boolean;
  size?: TCarbonComponentSizeProps;
  type: "text" | "password" | "email" | "number" | "tel" | "date";
  inputPattern?: string;
  autoComplete?: string;
  maxLength?: number;
  placeholder?: string;
  className?: string;
  iconRight?: React.ReactNode;
  hideErrorMessage?: boolean;
  containerClassName?: string;

  // controlled form props
  value?: string | number | null;
  onChange?: (value: any) => void;

  // hook form props
  register: UseFormRegister<TFormValues>;
  errors: FieldErrorsImpl<DeepRequired<TFormValues>>;
}

export function TextInput<TFormValues extends FieldValues>(
  props: ITextInputProps<TFormValues>
) {
  const { hideErrorMessage, errors, inputPattern, type, onChange, ...rest } =
    props;

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () =>
    setShowPassword((currentState) => !currentState);

  const inputType =
    type === "number"
      ? "text"
      : type === "password" && showPassword
      ? "text"
      : type;

  const { apiErrors } = useApiErrors();

  const apiErrorMessage = getApiErrorsByFieldName(props.name, apiErrors);
  const hookFormErrorMessage =
    errors[props.name] && errors[props.name]?.["message"];

  const combinedErrors = hookFormErrorMessage || apiErrorMessage || "";

  let pattern = inputPattern;
  // if (type === "number") {
  //   pattern = inputPattern ?? "[0-9]+"; // "/^\d+(\.\d+)?$/" // NOW USING MASKING
  // }

  const onChangeWithNumberMask =
    type === "number"
      ? (e: any) => {
          getCurrencyMask("").onChange(e);
          onChange && onChange(e);
        }
      : onChange;

  return (
    <View
      showPassword={showPassword}
      toggleShowPassword={toggleShowPassword}
      errorMessage={combinedErrors}
      hideErrorMessage={hideErrorMessage}
      type={inputType}
      pattern={pattern}
      onChange={onChangeWithNumberMask}
      // onChange={onChange}
      {...rest}
    />
  );
}
