import { Suspense } from "react";
import {
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import {
  ActivityBlockerProvider,
  ApiErrorsProvider,
  ApiPagedMetaProvider,
  AuthProvider,
  NotificationsProvider,
  RemoteDataProviders,
} from "./contexts";
import {
  ActivityBlocker,
  ActivityIndicator,
  AppLayout,
  ErrorBoundary,
} from "./components";
// import { useGoogleAnalyticsTracking } from "./hooks";

import "./app.scss";

function App() {
  const reactQueryClient = new QueryClient();

  // TODO: this hook breaks react query even without GA enabled.
  // useGoogleAnalyticsTracking();

  return (
    <ErrorBoundary>
      <NotificationsProvider>
        <ActivityBlockerProvider>
          <ActivityBlocker />
          {/* React query uses the activity blocker context, so must remain wrapped by it */}
          <ReactQueryClientProvider client={reactQueryClient}>
            <ApiPagedMetaProvider>
              <ApiErrorsProvider>
                <AuthProvider>
                  <Suspense fallback={<ActivityIndicator />}>
                    <AppLayout />
                  </Suspense>
                </AuthProvider>
              </ApiErrorsProvider>
            </ApiPagedMetaProvider>

            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </ReactQueryClientProvider>
        </ActivityBlockerProvider>
      </NotificationsProvider>
    </ErrorBoundary>
  );
}

export default App;
