import {
  Select as CarbonSelect,
  SelectSkeleton as CarbonSelectSkeleton,
  SelectItem,
  SelectItemGroup,
} from "@carbon/react";

import { InfoIcon } from "../../components";

export const View = (props) => {
  const {
    errorMessage,
    register,
    selectItems,
    selectGroupedItems = [],
    showSkeleton,
    infoIconText,
    containerClassName,
    ...rest
  } = props;

  return (
    <div className={`input-container ${containerClassName}`}>
      {showSkeleton && !props.disabled ? (
        <CarbonSelectSkeleton {...rest} />
      ) : (
        <CarbonSelect
          {...register(props.name)}
          // helperText={props.helperText}
          // placeholder={props.placeholder}
          {...rest}
          id={props.name}
          labelText={
            !props.label && !infoIconText ? (
              ""
            ) : (
              <div className="flex-container">
                <div className="flex-box">{props.label} </div>
                {infoIconText ? (
                  <div className="ml-3">
                    <InfoIcon message={infoIconText} />{" "}
                  </div>
                ) : null}
              </div>
            )
          }
          invalid={!!errorMessage}
          invalidText={errorMessage}
          className={`${props.className} switchapp-themed`}
        >
          <SelectItem
            disabled
            text="Choose an option"
            value="placeholder-item"
          />

          {selectGroupedItems.map((itemGroup, i) => (
            <SelectItemGroup
              key={`${itemGroup.groupLabel}_${i}`}
              label={itemGroup.groupLabel}
            >
              {itemGroup.options.map((option, optionIndex) => {
                return (
                  <SelectItem
                    key={`${option.value}_${optionIndex}`}
                    text={option.text}
                    // text={
                    //   <>
                    //     <img src={flagImage} alt="flag icon" /> {option.text}
                    //   </>
                    // }
                    value={option.value}
                  />
                );
              })}
            </SelectItemGroup>
          ))}

          {selectItems.map((item, i) => (
            <SelectItem
              key={`${item.value}_${i}`}
              text={item.text}
              value={item.value}
            />
          ))}
        </CarbonSelect>
      )}
    </div>
  );
};
