import { ISwAppAccountProfileApi } from "../../interfaces-and-types";

type TAccountProfileUrls = { [key in keyof ISwAppAccountProfileApi]: string };

const rootAccountProfileUrl = "/account";

export const accountProfileUrls: TAccountProfileUrls = {
  register: `${rootAccountProfileUrl}/register`,
  confirmEmail: `${rootAccountProfileUrl}/confirm-email`,
};
