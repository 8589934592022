import { createContext } from "react";

import { IActivityBlockerContext } from "../interfaces-and-types";

export const ActivityBlockerContext = createContext<IActivityBlockerContext>({
  isBlocked: false,
  messages: [],
  setBlocked: () => {},
  clearBlocked: () => {},
  //   onCancel: () => {},
});
