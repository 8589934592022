import {
  IConfirmEmailRequest,
  IResetPasswordRequest,
} from "../interfaces-and-types";

interface IConfirmationConstant
  extends Record<string, keyof IConfirmEmailRequest> {}

interface IResetPasswordConstant
  extends Record<string, keyof IResetPasswordRequest> {}

export const accountConfirmationConstants: IConfirmationConstant = {
  confirmationCodeQueryStringName: "code",
  emailQueryStringName: "email",
  merchantIdQueryStringName: "account_id",
};

export const forgotPasswordConstants: IResetPasswordConstant = {
  emailQueryStringName: "email",
  reset_password_tokenQueryStringName: "reset_password_token",
};
