import { ISwAppPricingApi } from "../../interfaces-and-types";

type TProductsUrls = {
  [key in keyof ISwAppPricingApi]: string;
};

const rootPricingManagementUrl = "/merchant-pricing";

export const pricingManagementUrls: TProductsUrls = {
  // shared pricing
  getSharedMerchantPricingByCountry: `${rootPricingManagementUrl}/shared-pricing`,
  editSharedMerchantPricingByCountry: `${rootPricingManagementUrl}/shared-pricing/{id}`,

  // add shared vol pricing
  addSharedPaymentVolumePricing: `${rootPricingManagementUrl}/shared-pricing/{pricing_id}/payment-volume-pricing`,
  addSharedPayoutVolumePricing: `${rootPricingManagementUrl}/shared-pricing/{pricing_id}/payout-volume-pricing`,
  addSharedTopUpVolumePricing: `${rootPricingManagementUrl}/shared-pricing/{pricing_id}/topup-volume-pricing`,
  addSharedTransferVolumePricing: `${rootPricingManagementUrl}/shared-pricing/{pricing_id}/transfer-volume-pricing`,

  // special pricing
  listSpecialMerchantPricing: `${rootPricingManagementUrl}/{merchant_id}/special-pricing`,
  getSpecialMerchantPricingById: `${rootPricingManagementUrl}/{merchant_id}/special-pricing/{id}`,
  addSpecialMerchantPricing: `${rootPricingManagementUrl}/{merchant_id}/special-pricing`,
  editSpecialMerchantPricing: `${rootPricingManagementUrl}/{merchant_id}/special-pricing/{id}`,
  deleteSpecialMerchantPricing: `${rootPricingManagementUrl}/{merchant_id}/special-pricing/{id}`,

  // add special vol pricing
  addSpecialPaymentVolumePricing: `${rootPricingManagementUrl}/{merchant_id}/special-pricing/{pricing_id}/payment-volume-pricing`,
  addSpecialPayoutVolumePricing: `${rootPricingManagementUrl}/{merchant_id}/special-pricing/{pricing_id}/payout-volume-pricing`,
  addSpecialTopUpVolumePricing: `${rootPricingManagementUrl}/{merchant_id}/special-pricing/{pricing_id}/topup-volume-pricing`,
  addSpecialTransferVolumePricing: `${rootPricingManagementUrl}/{merchant_id}/special-pricing/{pricing_id}/transfer-volume-pricing`,

  // delete special volume pricing
  deleteSpecialPaymentVolumePricing: `${rootPricingManagementUrl}/{merchant_id}/special-pricing/{pricing_id}/payment-volume-pricing/{id}`,
  deleteSpecialPayoutVolumePricing: `${rootPricingManagementUrl}/{merchant_id}/special-pricing/{pricing_id}/payout-volume-pricing/{id}`,
  deleteSpecialTopUpVolumePricing: `${rootPricingManagementUrl}/{merchant_id}/special-pricing/{pricing_id}/topup-volume-pricing/{id}`,
  deleteSpecialTransferVolumePricing: `${rootPricingManagementUrl}/{merchant_id}/special-pricing/{pricing_id}/transfer-volume-pricing/{id}`,
};
