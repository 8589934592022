import { useEffect, useRef } from "react";

import {
  useHookForm,
  useAdminApiMutation,
  useReactRouterLink,
} from "../../../../../hooks";
import {
  IHookForm,
  IAddVolumePricingOption,
  IViewVolumePricingOption,
} from "../../../../../interfaces-and-types";
import { globalHandlerUtils } from "../../../../../utils";

import { View } from "./View";
import { volumePricingOptionValidationSchema } from "./validationSchema";
import { defaultAddOrEditPricingOptionBase } from "../defaults";
import { UseFormSetValue } from "react-hook-form";
import {
  TPricingOptionType,
  clearFlatRateIfNotEnabled,
} from "../ManageSpecialPricing";

export interface IAddVolumePricingOptionFormProps {
  merchantId: string;
  pricingId: string;
  volumePricingType: TPricingOptionType;
}

export interface IAddVolumePricingOptionFormViewProps
  extends IHookForm<IAddVolumePricingOption> {
  volumePricingType: TPricingOptionType;
  closeAddVolumePricingView: () => void;
  setValue: UseFormSetValue<IAddVolumePricingOption>;
  formValues: IAddVolumePricingOption;
}

export const AddVolumePricingOptionForm = (
  props: IAddVolumePricingOptionFormProps // props may be null if page is accessed by directly & not by navigating from details page
) => {
  const { merchantId, pricingId, volumePricingType } = props;

  const { location, navigate, navigateToRoutePreserveParams } =
    useReactRouterLink();

  // make it scroll to the actual volumePricingOption being added
  // const timeoutId = useRef<NodeJS.Timeout>();
  // useEffect(() => {
  //   timeoutId.current = setTimeout(
  //     () =>
  //       navigate({
  //         pathname: location.pathname,
  //         hash: `add-${volumePricingType}-volume-pricing-form`,
  //         search: location.search,
  //       }),
  //     1500
  //   );

  //   return () => clearTimeout(timeoutId.current);
  // }, []);

  const closeAddVolumePricingView = () =>
    navigate({
      pathname: location.pathname.replace(
        `/add-${volumePricingType}-volume-pricing`,
        ""
      ),
      search: location.search,
    });

  const {
    isSuccess: isSuccessAddSpecialVolumePricingOption,
    // isLoading: isLoadingAddSpecialVolumePricingOption,
    mutate: addSpecialVolumePricingOption,
  } = useAdminApiMutation<IAddVolumePricingOption, IViewVolumePricingOption>(
    volumePricingType === "payment"
      ? "addSpecialPaymentVolumePricing"
      : volumePricingType === "payout"
      ? "addSpecialPayoutVolumePricing"
      : volumePricingType === "topup"
      ? "addSpecialTopUpVolumePricing"
      : "addSpecialTransferVolumePricing",
    ["getSpecialMerchantPricingById"],
    {
      /* axios config */
    },
    (urlTemplate) =>
      urlTemplate
        .replace("{merchant_id}", merchantId)
        .replace("{pricing_id}", pricingId)
  ).usePostMutation;

  useEffect(() => {
    if (isSuccessAddSpecialVolumePricingOption) {
      reset();
      closeAddVolumePricingView();
    }
  }, [isSuccessAddSpecialVolumePricingOption]);

  const handleApiSubmit = (data: IAddVolumePricingOption) => {
    var dataToSubmit = clearFlatRateIfNotEnabled(data);
    addSpecialVolumePricingOption(dataToSubmit);
  };

  const handleError = (errors: any) => globalHandlerUtils.handleError(errors);

  const { onFormSubmit, errors, register, reset, setValue, watch } =
    useHookForm({
      initialFormValues: defaultAddOrEditPricingOptionBase,
      validationSchema: volumePricingOptionValidationSchema,
      onValid: handleApiSubmit,
      onInvalid: handleError,
    });

  const formValues = watch();

  return (
    <View
      volumePricingType={volumePricingType}
      closeAddVolumePricingView={closeAddVolumePricingView}
      setValue={setValue}
      handleSubmit={onFormSubmit}
      register={register}
      errors={errors}
      formValues={formValues}
    />
  );
};
