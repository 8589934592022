import { SkeletonIcon, SkeletonPlaceholder, SkeletonText } from "@carbon/react";

export const View = (props /*: ISkeletonLoaderProps*/) => {
  if (props.type === "icon")
    return <SkeletonIcon className={props.className} />;

  if (props.type === "text")
    return <SkeletonText className={props.className} />;

  return <SkeletonPlaceholder className={props.className} />;
};
