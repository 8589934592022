import { ISwAppAdminAuthApi } from "../../interfaces-and-types";

type TAuthUrls = { [key in keyof ISwAppAdminAuthApi]: string };

const rootAuthUrl = "/auth";

export const authUrls: TAuthUrls = {
  // changePassword: "/change-password",
  login: `${rootAuthUrl}/login`,
  logout: `${rootAuthUrl}/logout`,
  refresh_token: `${rootAuthUrl}/refresh-token`,
  forgotPassword: `${rootAuthUrl}/resetpassword/forgot-password`,
  resetPassword: `${rootAuthUrl}/resetpassword/confirm-reset-password`,
  resendConfirmationEmail: `${rootAuthUrl}/resend-confirmation-email`,

  remoteValidateEmail: `${rootAuthUrl}/remote-validation/validate-email`,
  remoteValidatePassword: `${rootAuthUrl}/remote-validation/validate-password`,
};
