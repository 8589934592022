// import { Button } from "@carbon/react";

import { IErrorBoundaryViewProps } from "./ErrorBoundary";

export const View = ({ reloadWindow }: IErrorBoundaryViewProps) => {
  return (
    <div className="error-boundary--container flex-container justify-content--center align-items--center">
      <span className="error-boundary--message font-ctm--medium">
        An error occured. Please reload the page.
      </span>

      <button
        className="cds--btn cds--btn--primary switchapp-themed reload-window--btn"
        onClick={reloadWindow}
      >
        Reload Page
      </button>
    </div>
  );
};
