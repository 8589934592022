import { SwitchAppButton } from "../../../../components";
import { useAdminApiMutation } from "../../../../hooks";
import { IViewVolumePricingOption } from "../../../../interfaces-and-types";
import { VolumePricingOptionDisplay } from "../VolumePricingOptionDisplay";
import { TPricingOptionType } from "./ManageSpecialPricing";

interface IManageVolumePricingEntry {
  index: number;
  merchantId: string;
  pricingId: string;
  volumePricingOption: IViewVolumePricingOption;
  volumePricingType: TPricingOptionType;
}

export const ManageVolumePricingEntry = (props: IManageVolumePricingEntry) => {
  const { merchantId, pricingId, volumePricingType, volumePricingOption } =
    props;

  const {
    // isSuccess: isSuccessDeleteSpecVolumePricingById,
    mutate: deleteSpecVolumePricingById,
  } = useAdminApiMutation<null, void>(
    volumePricingType === "payment"
      ? "deleteSpecialPaymentVolumePricing"
      : volumePricingType === "payout"
      ? "deleteSpecialPayoutVolumePricing"
      : volumePricingType === "topup"
      ? "deleteSpecialTopUpVolumePricing"
      : "deleteSpecialTransferVolumePricing",
    ["getSpecialMerchantPricingById"],
    {
      /* axios config */
    },
    (urlTemplate) =>
      urlTemplate
        .replace("{merchant_id}", merchantId)
        .replace("{pricing_id}", pricingId)
        .replace("{id}", volumePricingOption.id)
  ).useDeleteMutation;

  const removeVolumePricingById = () => {
    if (
      window.confirm(
        `Are you sure you want to delete this ${volumePricingType} volume pricing entry?`
      )
    ) {
      deleteSpecVolumePricingById();
    }
  };

  return (
    <VolumePricingOptionDisplay
      index={props.index}
      volumePricingOption={props.volumePricingOption}
      renderAfter={
        <div className="cds--structured-list-td">
          <SwitchAppButton
            kind="danger"
            onClick={removeVolumePricingById}
            size="sm"
            className="ml-5"
          >
            Delete
          </SwitchAppButton>
        </div>
      }
    />
  );
};
