import { ReactNode } from "react";
import { View } from "./View";

interface ISwitchAppDetailFooterLinkProps {
  linkComponent: ReactNode;
  linkUrl: string;
}

export interface ISwitchAppLegalAndContactFooterProps {
  sections: ISwitchAppDetailFooterLinkProps[];
  className?: string;
}

export const SwitchAppLegalAndContactFooter = (
  props: ISwitchAppLegalAndContactFooterProps
) => {
  return <View {...props} />;
};
