import { useState } from "react";

import { useAdminApiQueryWithParams } from "../../../hooks";
import {
  IPayoutsSummary,
  IPayoutOverview,
} from "../../../interfaces-and-types";
import { TSummaryPeriod } from "../../../constants";
import { ISummarySectionProps, ISummarySectionViewProps } from "../Home";

import { View } from "./View";

export interface IPayoutsSummaryViewProps
  extends ISummarySectionViewProps<IPayoutOverview> {}

export const PayoutsSummary = ({ activeCurrency }: ISummarySectionProps) => {
  const {
    isLoading: isLoadingPayoutsSummary,
    // isSuccess: isSuccessPayoutsSummary,
    // isError: isErrorPayoutsSummary,
    data: payoutsSummary,
  } = useAdminApiQueryWithParams<{ currency: string }, IPayoutsSummary>(
    [
      "getPayoutsSummary",
      {
        currency: activeCurrency!,
      },
    ],
    {
      enabled: !!activeCurrency,
    }
  );

  const isLoadingCustomPeriodSummary = false; // update after adding fetch logic for custom

  const [summaryPeriod, setSummaryPeriod] = useState<TSummaryPeriod>("today");

  const summaryToDisplay =
    summaryPeriod === "today"
      ? payoutsSummary?.today
      : summaryPeriod === "past week"
      ? payoutsSummary?.past_week
      : summaryPeriod === "past month"
      ? payoutsSummary?.past_month
      : // summaryPeriod === "custom" ? customPeriodPayoutsSummary :
        payoutsSummary?.all_time;

  const isLoadingSummary =
    summaryPeriod === "custom"
      ? isLoadingCustomPeriodSummary
      : isLoadingPayoutsSummary;

  return (
    <View
      activeCurrency={activeCurrency}
      isLoadingSummary={isLoadingSummary}
      summaryToDisplay={summaryToDisplay || null}
      summaryPeriod={summaryPeriod}
      setSummaryPeriod={setSummaryPeriod}
    />
  );
};
