import { createContext } from "react";
import { IApiErrorContext, IApiErrorDictionary } from "../interfaces-and-types";

export const defaultApiErrorState = {
  apiErrors: {},
};

export const ApiErrorsContext = createContext<IApiErrorContext>({
  ...defaultApiErrorState,
  setApiErrors: (errors: IApiErrorDictionary) => {},
  clearApiErrors: () => {},
  clearApiErrorByName: () => {},
});
