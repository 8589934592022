import React from "react";
import { DotSeparator } from "../DotSeparator";
import { ISwitchAppLegalAndContactFooterProps } from "./SwitchAppLegalAndContactFooter";

export const View = (props: ISwitchAppLegalAndContactFooterProps) => {
  return (
    <div
      className={`switchapp-legal-and-contact-footer--container flex-container font-ctm--bold ${
        props.className || ""
      }`}
    >
      {props.sections.map((section, index) => (
        <React.Fragment key={index}>
          <a href={section.linkUrl} className="switchapp-footer--item">
            {section.linkComponent}
          </a>
          {index === props.sections.length - 1 ? null : <DotSeparator />}
        </React.Fragment>
      ))}
    </div>
  );
};
