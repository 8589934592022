import { useMemo, useState } from "react";

import {
  IApiPagedMetaContext,
  IPageAndSearchMeta,
} from "../interfaces-and-types";
import {
  ApiPagedMetaContext,
  defaultApiPagedMeta,
} from "./ApiPagedMetaContext";

export function ApiPagedMetaProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [apiPageAndSearchMeta, setApiPagedMetaState] =
    useState<IPageAndSearchMeta>();

  const setApiPageMeta = (pageAndSearchMeta: IPageAndSearchMeta) =>
    setApiPagedMetaState(pageAndSearchMeta);

  const clearApiPageMeta = () => setApiPagedMetaState(defaultApiPagedMeta);

  const memoedContextValue: IApiPagedMetaContext = useMemo(
    () => ({
      page_meta: apiPageAndSearchMeta || null,
      setApiPageMeta,
      clearApiPageMeta,
    }),
    [
      apiPageAndSearchMeta?.page,
      apiPageAndSearchMeta?.page_size,
      apiPageAndSearchMeta?.page_count,
      apiPageAndSearchMeta?.item_count,
      apiPageAndSearchMeta?.search,
      setApiPageMeta,
      clearApiPageMeta,
    ]
  );

  return (
    <ApiPagedMetaContext.Provider value={memoedContextValue}>
      {children}
    </ApiPagedMetaContext.Provider>
  );
}
