import { SwitchAppInlineLoadingIcon } from "../SwitchAppInlineLoadingIcon";
import { IDataLoadingViewProps } from "./DataLoadingView";

export const View = (props: IDataLoadingViewProps) => {
  return (
    <div
      className={`loading-view--container flex-box flex-container justify-content--center align-items--center pt-5 pb-5 ${props.className}`}
    >
      <SwitchAppInlineLoadingIcon
        {...props}
        description={props.message}
        className={props.loaderSize ? `loader-${props.loaderSize}` : ""}
      />
    </div>
  );
};
