import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import {
  useHookForm,
  useAdminIdentityMutation,
  useAdminApiMutation,
  useAdminApiQuery,
  useQueryString,
} from "../../hooks";
import { globalHandlerUtils } from "../../utils";
import {
  IForgotPasswordRequest,
  IForgotPasswordResponse,
} from "../../interfaces-and-types";

import { View } from "./View";
import { forgotPasswordConstants } from "../../constants";

export const ForgotPassword = () => {
  const navigate = useNavigate();

  // get the form values from the url
  const query = useQueryString();
  const emailQueryString = query.get(
    forgotPasswordConstants.emailQueryStringName
  );

  const {
    isLoading: isLoadingForgotPassword,
    isSuccess: isSuccessForgotPassword,
    isError: isErrorForgotPassword,
    data: apiResponse,
    mutate: ForgotPassword,
  } = useAdminIdentityMutation<IForgotPasswordRequest, IForgotPasswordResponse>(
    "forgotPassword"
  ).usePostMutation;

  const redirectToLogin = () => navigate("/login");

  const initialFormValues: IForgotPasswordRequest = {
    email: emailQueryString || "",
  };

  const ForgotPasswordSchema: yup.SchemaOf<IForgotPasswordRequest> = yup
    .object({
      email: yup.string().email().required("Email is required"),
    })
    .required();

  const handleApiSubmit = (data: IForgotPasswordRequest) => {
    ForgotPassword(data);
  };

  const handleError = (errors: any) => globalHandlerUtils.handleError(errors);

  const { onFormSubmit, errors, register, reset } = useHookForm({
    initialFormValues,
    validationSchema: ForgotPasswordSchema,
    onValid: handleApiSubmit,
    onInvalid: handleError,
  });

  return (
    <View
      isSuccessForgotPassword={isSuccessForgotPassword}
      redirectToLogin={redirectToLogin}
      handleSubmit={onFormSubmit}
      register={register}
      errors={errors}
    />
  );
};
