import * as yup from "yup";

import { IAddVolumePricingOption } from "../../../../../interfaces-and-types";
import {
  baseChargeThresholdAction,
  // volumeBasedPricingInterval,
} from "../../../../../enums";

export const volumePricingOptionValidationSchema: yup.SchemaOf<IAddVolumePricingOption> =
  yup
    .object({
      id: yup.string().required(),

      is_international_pricing_option: yup
        .boolean()
        .required("International pricing status is required"),

      is_amount_based_threshold_enabled: yup
        .boolean()
        .required("International pricing status is required"),
      minimum_amount: yup.number().nullable().optional(),

      is_count_based_threshold_enabled: yup
        .boolean()
        .required("International pricing status is required"),
      minimum_count: yup.number().nullable().optional(),

      is_flat_rate_enabled: yup
        .boolean()
        .required("Flat rate status is required"),

      flat_rate: yup.number().nullable().optional(),

      base_charge: yup.number().nullable().optional(),
      base_charge_threshold_amount: yup.number().nullable().optional(),
      base_charge_threshold_action: yup
        .mixed<baseChargeThresholdAction>()
        .oneOf(Object.values(baseChargeThresholdAction))
        .required("Base charge threshold action is required"),

      percentage_charge: yup.number().nullable().optional(),

      is_charge_cap_enabled: yup
        .boolean()
        .required("Charge cap status is required"),

      charge_cap: yup.number().nullable().optional(),
    })
    .required();
