import {
  ToggletipLabel,
  Toggletip,
  ToggletipButton,
  ToggletipContent,
  ToggletipActions,
  Tooltip,
} from "@carbon/react";
import { Information } from "@carbon/icons-react";

import { IInfoIconProps } from "./InfoIcon";

export const View = ({
  statusColor,
  size = "md",
  align = "right",
  defaultOpen = false,
  label,
  message,
  toggleTipActions,
  className,
}) => {
  return (
    <Tooltip
      align={align}
      label={label}
      defaultOpen={defaultOpen}
      description={message}
    >
      <button className="sb-tooltip-trigger" type="button">
        <Information />
      </button>
    </Tooltip>

    // <div
    //   style={{ display: "inline", justifyContent: "center" }}
    //   className={className}
    // >
    //   {label ? <ToggletipLabel>{label}</ToggletipLabel> : null}
    //   <Toggletip defaultOpen={defaultOpen} align={align}>
    //     <ToggletipButton label="Show information">
    //       <Information />
    //     </ToggletipButton>
    //     <ToggletipContent>
    //       <p>{message}</p>
    //       <ToggletipActions>{toggleTipActions}</ToggletipActions>
    //     </ToggletipContent>
    //   </Toggletip>
    // </div>
  );
};
