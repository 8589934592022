import { Link } from "react-router-dom";
import { ILinkWithIconProps } from "./LinkWithIcon";

import chevronRightIcon from "../../assets/icons/chevron-right.svg";
import { LinkIcon } from "./LinkIcon";
import { ReactNode } from "react";

export const View = (props: ILinkWithIconProps) => {
  const {
    containerClassName,
    asButton,
    disabled,
    onButtonClick,
    iconLeft,
    iconRight,
    title,
    subtitle,
    hideDivider,
    urlTarget,
  } = props;

  const LinkContainer = (props: { children: ReactNode }) => {
    return asButton || disabled ? (
      <div
        onClick={disabled ? () => {} : onButtonClick}
        className={`flex-container linkContainer align-items--center ${
          disabled ? "disabled-link--button" : ""
        }`}
      >
        {props.children}
      </div>
    ) : (
      <Link
        to={urlTarget}
        className="flex-container linkContainer align-items--center"
      >
        {props.children}
      </Link>
    );
  };

  return (
    <div className={`link-with-icon--container ${containerClassName}`}>
      <LinkContainer>
        <div className="flex-container labelContainer">
          {iconLeft}

          <div className="titleContainer">
            <p className="title font-ctm--medium">{title}</p>
            {subtitle ? <span className="subtitle">{subtitle}</span> : null}
          </div>
        </div>

        <div className="iconRightContainer">
          {!iconRight ? <LinkIcon mainIconSource={chevronRightIcon} /> : null}
          {iconRight && iconRight}
        </div>
      </LinkContainer>
    </div>

    // <div className={`link-with-icon--container ${containerClassName}`}>
    //   {asButton ? (
    //     <div
    //       onClick={onButtonClick}
    //       className="flex-container linkContainer align-items--center"
    //     >
    //       <div className="flex-container labelContainer">
    //         {iconLeft}

    //         <div className="titleContainer">
    //           <p className="title font-ctm--medium">{title}</p>
    //           {subtitle ? <span className="subtitle">{subtitle}</span> : null}
    //         </div>
    //       </div>

    //       <div className="iconRightContainer">
    //         {!iconRight ? <LinkIcon mainIconSource={chevronRightIcon} /> : null}
    //         {iconRight && iconRight}
    //       </div>
    //     </div>
    //   ) : (
    //     <Link
    //       to={urlTarget}
    //       className="flex-container linkContainer align-items--center"
    //     >
    //       <div className="flex-container labelContainer">
    //         {iconLeft}

    //         <div className="titleContainer">
    //           <p className="title font-ctm--medium">{title}</p>
    //           {subtitle ? <span className="subtitle">{subtitle}</span> : null}
    //         </div>
    //       </div>

    //       <div className="iconRightContainer">
    //         {!iconRight ? <LinkIcon mainIconSource={chevronRightIcon} /> : null}
    //         {iconRight && iconRight}
    //       </div>
    //     </Link>
    //   )}
    // </div>
  );
};
