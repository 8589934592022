import { ISwitchAppButtonProps } from "../../components/SwitchAppButton/SwitchAppButton";
import { useActivityBlocker } from "../../hooks/useActivityBlocker";
import { View } from "./View";

interface ISubmitButtonProps extends Omit<ISwitchAppButtonProps, "onClick"> {
  showChildrenWithLoadingIcon?: boolean;
}

export const SubmitButton = (props: ISubmitButtonProps) => {
  const { isBlocked } = useActivityBlocker();

  return <View {...props} isBlocked={isBlocked} />;
};
