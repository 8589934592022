import { ISwAppPaymentLinksApi } from "../../interfaces-and-types";

type TPaymentLinksUrls = {
  [key in keyof ISwAppPaymentLinksApi]: string;
};

const rootPaymentLinksUrl = "/payment-links";

export const paymentLinksUrls: TPaymentLinksUrls = {
  createPaymentLink: `${rootPaymentLinksUrl}`,
  getPaymentLinks: `${rootPaymentLinksUrl}`,
  getPaymentLinkById: `${rootPaymentLinksUrl}/id`,
  editPaymentLink: `${rootPaymentLinksUrl}/id`,
  deletePaymentLink: `${rootPaymentLinksUrl}/id`,

  // TODO: move to hostedLinkUrls
  getHostedPaymentLink: `/hosted-payment-links`,
};
