import { getCurrencySymbol } from "../../utils";
import { View } from "./View";

export interface IViewProps {
  currencyOrSymbol: string;
  useSymbolAsIs?: boolean; // do not try to replace with symbol
  amount: number;
  symbolClassName?: string;
  amountClassName?: string;
  symbolFontSize?: number;
  amountFontSize?: number;
}

export const CurrencyDisplay = (props: IViewProps) => {
  return (
    <View
      {...props}
      currencyOrSymbol={
        props.useSymbolAsIs
          ? props.currencyOrSymbol
          : getCurrencySymbol(props.currencyOrSymbol) || props.currencyOrSymbol
      }
    />
  );
};
