import { createContext } from "react";

import { IActiveTenantContext } from "../interfaces-and-types";

export const ActiveTenantContext = createContext<IActiveTenantContext>({
  activeTenant: null,
  setActiveTenant: () => {},

  filterTenantsSearchTerm: null,
  setFilterTenantsSearchTerm: () => {},

  // remote data
  isSuccess: false,
  isError: false,
  isLoading: false,
  data: [],
  refreshRemoteData: () => {},
});
