import {
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import { useApiErrors } from "../../hooks";
import {
  IKeyValuePair,
  ISelectGroupedItemsProps,
  ISelectOptionProps,
  TCarbonComponentSizeProps,
} from "../../interfaces-and-types";
import { getApiErrorsByFieldName } from "../../utils";

import { View } from "./View";
import { ReactNode } from "react";

export interface ISelectProps {
  name: string;
  arrayFieldName?: string;
  arrayFieldIndex?: string;
  label: ReactNode;
  helperText?: string;
  infoIconText?: string;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  size?: TCarbonComponentSizeProps;
  defaultValue?: string;
  showSkeleton?: boolean;
  hideSkeletonLabel?: boolean;
  selectItems: ISelectOptionProps[];
  selectGroupedItems?: Array<ISelectGroupedItemsProps>;
  placeholder?: string;

  // error display
  invalid?: boolean;
  invalidText?: string;

  // controlled form props
  value?: string | number | null;
  onChange?: (value: any) => void;
}

export function Select(props: ISelectProps) {
  const { name, ...rest } = props;

  return <View name={name} {...rest} />;
}
