import { IKeyValuePairT } from "../interfaces-and-types";

export type TTransactionPeriodSelect = null | "7d" | "1m" | "6m" | "1y";

export type TSummaryPeriod =
  | null
  | "today"
  | "past week"
  | "past month"
  | "all time"
  | "custom";

interface IFilterConstants {
  // keys
  startDateKey: string;
  endDateKey: string;
  monthlyOrYearlyStatusKey: string;
  currentPageKey: string;
  pageSizeKey: string;
  searchKey: string;
  statusKey: string;
  isDisabledKey: string;
  // fraudStatusKey: string;

  // options
  transactionPeriods: IKeyValuePairT<TTransactionPeriodSelect>[];
}

export const filterConstants: IFilterConstants = {
  // keys
  startDateKey: "start_date",
  endDateKey: "end_date",
  monthlyOrYearlyStatusKey: "mnthoryr",
  currentPageKey: "page",
  pageSizeKey: "page_size",
  searchKey: "search",
  statusKey: "status",
  isDisabledKey: "isDisabled",
  // fraudStatusKey: "fraudstat",

  // options
  transactionPeriods: [
    { key: "Custom", value: null },
    { key: "7 days", value: "7d" },
    { key: "1 month", value: "1m" },
    { key: "6 months", value: "6m" },
    { key: "1 year", value: "1y" },
  ],
};
