import { Route, Routes } from "react-router-dom";

import { ForgotPassword, ResetPassword, SignIn } from "../../pages";

export const AuthLayout = () => {
  return (
    <Routes>
      <Route path="*" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
    </Routes>
  );
};
