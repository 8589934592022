import {
  QuickTable,
  QuickTableFilterTabs,
  TransactionListDetail,
} from "../../components";
import { paymentDisplayFormatter } from "./paymentDisplayFormatter";
import { IPaymentViewProps } from "./Payments";
import { PaymentDetailsModal } from "./PaymentDetailsModal";
import { Route, Routes } from "react-router-dom";

export const View = (props: IPaymentViewProps) => {
  return (
    <div className="payments-page">
      <Routes>
        <Route
          index
          element={
            <QuickTable
              id="paymentsTable"
              data={props.data}
              headers={props.headers}
              isLoading={props.isLoading}
              isError={props.isError}
              itemCount={props.totalCount}
              // renderDetail={(item) => <TransactionListDetail tx={item} />}
              toolbarTabs={
                <QuickTableFilterTabs
                  queryStringTabNavLinks={props.queryStringTabs}
                />
              }
              displayFormatter={paymentDisplayFormatter}
              onExport={props.onExport}
              openDetailsModal={props.openDetailsModal}
            />
          }
        />
        <Route path=":id/details" element={<PaymentDetailsModal />} />
      </Routes>
    </div>
  );
};
