import tickCircleBlueIcon from "../assets/icons/tick-circle-blue.svg";
import closeCircleIcon from "../assets/icons/close-circle.svg";
import {
  ITickCircleColorParams,
  TickCircleColorParams,
} from "../assets/icons/tick-circle-color--params";

interface IFeatureEnabledIconProps extends ITickCircleColorParams {
  isEnabled: boolean;
}
export const FeatureEnabledIcon = ({
  isEnabled,
  ...rest
}: IFeatureEnabledIconProps) => {
  const customTickCircleParamsProvided =
    !!rest.tickColor || !!rest.backgroundColor;

  if (isEnabled && customTickCircleParamsProvided)
    return <TickCircleColorParams {...rest} />;

  return (
    <img
      src={isEnabled ? tickCircleBlueIcon : closeCircleIcon}
      alt="feature is enabled/disabled"
      title={`Feature is ${isEnabled ? "Enabled" : "Disabled"}`}
      width={20}
      height={20}
    />
  );
};
