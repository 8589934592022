export function formatNumberWithCommas(x?: number | null): string {
  if (!x && x !== 0) return "--";

  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return parts.join(".");
}

export function smartCountDisplay(value: number) {
  const thousandsRemainder = value % 1000;
  const thousands = value / 1000;
  const smartCount =
    thousandsRemainder > 100 ? thousands.toFixed(1) : thousands.toFixed(0);

  return smartCount;
}
