import { formatAsCurrency } from "../../utils";
import { IViewProps } from "./CurrencyDisplay";

export const View = (props: IViewProps) => {
  const { amountFontSize = 20, symbolFontSize = 14 } = props;
  const negativeAwareFormatting = (amount: number) =>
    amount < 0 ? `(${formatAsCurrency(amount)})` : formatAsCurrency(amount);

  return (
    <span className="currency-display--container">
      <span
        className={`currency-symbol ${props.symbolClassName}`}
        style={{ fontSize: symbolFontSize }}
      >
        {props.currencyOrSymbol}
      </span>

      <span
        className={`currency-amount font-ctm--semibold ${props.amountClassName}`}
        style={{ fontSize: amountFontSize }}
      >
        {negativeAwareFormatting(props.amount)}
      </span>
    </span>
  );
};
