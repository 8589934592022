export const appConfig = {
  // api version & endpoint roots
  apiVersion: "v1",

  adminIdentityApiBaseUrl: process.env.REACT_APP_ADMIN_IDENTITY_API_BASE_URL!,
  adminApiBaseUrl: process.env.REACT_APP_ADMIN_API_BASE_URL!,
  adminDashboardBaseUrl: process.env.REACT_APP_ADMIN_DASHBOARD_URL!,
  merchantDashboardBaseUrl: process.env.REACT_APP_MERCHANT_DASHBOARD_URL!,

  // data storage
  APP_DATA_STORAGE_KEY: "SWITCHAPP_DATA_STORE_KEY",

  // formatting
  javascriptCodeDateFormat: "MM/DD/YYYY",
  dateFormat1: "MMM DD, YYYY",
  dateFormat2: "M d, Y",
  dateFormat3: "MM YYYY",
  timeFormat1: "hh:mm A",
  timeFormat2: "hh:mm:ss A",
  timeFormat3: "HH:mm A",
  dateTimeFormat: "ddd MMM DD, YYYY, hh:mm A",

  // query caching
  queryCacheIntervalInMins: 20,
  queryRetryEnabled: false,
};
