// import { CopyButton as CarbonCopyButton } from "@carbon/react";

import { SwitchAppPopover } from "../SwitchAppPopover";

import copyDocumentIcon from "../../assets/icons/document-copy.svg";

export const View = ({
  className = "",
  feedbackInfo = "✓ copied!",
  iconDescription = "Copy to clipboard",
  isPopoverOpen,
  handleCopy,
  copyIcon = copyDocumentIcon,
}) => {
  return (
    <div
      onClick={handleCopy}
      // feedback={feedbackInfo}
      // iconDescription={iconDescription}
      className={`copy-document--button ${className}`}
      title={isPopoverOpen ? "" : iconDescription}
    >
      <img src={copyIcon} alt="copy icon" className="copy-document--icon" />
      <SwitchAppPopover
        isOpen={isPopoverOpen}
        popoverContent={feedbackInfo}
        align={"right"}
        className="popover-display"
        popoverContentClassName="popover-content"
        highContrast
      ></SwitchAppPopover>
    </div>
  );
};
