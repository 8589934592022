import { Card } from "../Card";
import { IFormContainerProps } from "./FormContainer";

export const View = (props: IFormContainerProps) => {
  return (
    <Card className={`switchapp-form--container ${props.className}`}>
      {props.children}
    </Card>
  );
};
