import {
  ModalContainer,
  SectionHeader,
  SwitchAppButton,
} from "../../../../../components";
import { baseChargeThresholdAction } from "../../../../../enums";
import {
  HookFormSelect,
  HookFormToggle,
  SubmitButton,
  TextInput,
} from "../../../../../hook-form";
import { splitCamelCaseString } from "../../../../../utils";

import { IAddVolumePricingOptionFormViewProps } from "./AddVolumePricingOptionForm";

export const View = ({
  // props
  volumePricingType,
  closeAddVolumePricingView,

  //  form mgmt
  formValues,
  setValue,

  errors,
  handleSubmit,
  register,
}: IAddVolumePricingOptionFormViewProps) => {
  return (
    <ModalContainer
      isModalOpen
      closeModal={closeAddVolumePricingView}
      modalHeader={
        <SectionHeader
          title={`NEW ${volumePricingType.toUpperCase()} VOLUME PRICING OPTION`}
          subtitle={`Add a new entry in the ${volumePricingType} volume pricing list.`}
        />
      }
    >
      <div className="add-volume-pricing-form--container">
        <form
          onSubmit={handleSubmit}
          id={`add-${volumePricingType}-volume-pricing-form`}
          className={`add-volume-pricing--form add-${volumePricingType}-volume-pricing--form mt-5`}
        >
          <div className="volume-pricing--subsection">
            <h6 className="text-primary">
              These Parameters Control When This Volume Pricing Is
              Active/Applied
            </h6>
            <hr className="opacity-30" />

            <div
              className={
                "pricing-subsection--body flex-container pb-5 align-items--end"
              }
            >
              <div className="flex-container col align-items--end mr-10 ">
                <HookFormToggle
                  name={`is_amount_based_threshold_enabled`}
                  label="Is Amount Based Threshold?"
                  // labelOff="No"
                  labelOff={<>No&nbsp;</>}
                  labelOn="Yes"
                  size="sm"
                  containerClassName="settings-toggle"
                  toggled={formValues.is_amount_based_threshold_enabled}
                  onToggle={() =>
                    setValue(
                      `is_amount_based_threshold_enabled`,
                      !formValues.is_amount_based_threshold_enabled
                    )
                  }
                  infoIconText={
                    <>
                      Toggle this to use amount/volume of the transaction (eg
                      NGN50,000.00, USD300.00) to determine if this pricing
                      option will be applied to it
                    </>
                  }
                  register={register}
                  errors={errors}
                />

                <HookFormToggle
                  name={`is_count_based_threshold_enabled`}
                  label="Is Count Based Threshold?"
                  // labelOff="No"
                  labelOff={<>No&nbsp;</>}
                  labelOn="Yes"
                  size="sm"
                  containerClassName="settings-toggle"
                  toggled={formValues.is_count_based_threshold_enabled}
                  onToggle={() =>
                    setValue(
                      `is_count_based_threshold_enabled`,
                      !formValues.is_count_based_threshold_enabled
                    )
                  }
                  infoIconText={
                    <>
                      Toggle this to use total counts (eg 10 txs, 150 txs, 1000
                      txs) of transactions for a given period (eg Day, Week,
                      Months, etc) to determine if this pricing option will be
                      applied to it
                    </>
                  }
                  register={register}
                  errors={errors}
                />
              </div>

              <div
                className={`flex-container pb-5 align-content--start align-items--end`}
              >
                <TextInput
                  name={`minimum_amount`}
                  label="Minimum Amount"
                  infoIconText={
                    <>
                      The minimum{" "}
                      <span className="text-danger">Transaction Amount</span>{" "}
                      that activates this volume pricing option
                    </>
                  }
                  containerClassName="mr-5"
                  type="text" // type="number"
                  disabled={!formValues.is_amount_based_threshold_enabled}
                  register={register}
                  errors={errors}
                />

                <TextInput
                  name={`minimum_count`}
                  label="Minimum Count"
                  infoIconText={
                    <>
                      The minimum{" "}
                      <span className="text-danger">Transaction Count</span> for
                      the <span className="text-success">Interval Type</span>{" "}
                      that activates this volume pricing option
                    </>
                  }
                  containerClassName="mr-5"
                  type="text" // type="number"
                  disabled={!formValues.is_count_based_threshold_enabled}
                  register={register}
                  errors={errors}
                />
              </div>
            </div>

            {/* <hr /> */}

            <h6 className="text-teal mt-10">
              These Parameters Control How Much The Charges Will Be
            </h6>
            <hr className="opacity-30" />

            <div
              className={
                "pricing-subsection--body flex-container pb-5 justify-content--between align-items--end"
              }
            >
              <div className="flex-container col align-items--end mr-10 ">
                <HookFormToggle
                  name={`is_flat_rate_enabled`}
                  label="Use Flat Rate?"
                  // labelOff="No"
                  labelOff={<>No&nbsp;</>}
                  labelOn="Yes"
                  size="sm"
                  containerClassName="settings-toggle"
                  toggled={formValues.is_flat_rate_enabled}
                  onToggle={() =>
                    setValue(
                      `is_flat_rate_enabled`,
                      !formValues.is_flat_rate_enabled
                    )
                  }
                  infoIconText={
                    <>
                      A flat-rate takes precedence over all other settings.
                      Exact stated flat-rate amount is always charged as long as
                      the pricing is active.
                    </>
                  }
                  register={register}
                  errors={errors}
                />

                <HookFormToggle
                  name={`is_charge_cap_enabled`}
                  label="Is Capped?"
                  // labelOff="No"
                  labelOff={<>No&nbsp;</>}
                  labelOn="Yes"
                  size="sm"
                  containerClassName="settings-toggle"
                  toggled={formValues.is_charge_cap_enabled}
                  onToggle={() =>
                    setValue(
                      `is_charge_cap_enabled`,
                      !formValues.is_charge_cap_enabled
                    )
                  }
                  infoIconText={
                    <>
                      If enabled, will set this value as the max charge if the
                      calculated value exceeds it.
                    </>
                  }
                  disabled={formValues.is_flat_rate_enabled}
                  register={register}
                  errors={errors}
                />
              </div>

              <div
                className={`flex-container pb-5 align-content--start align-items--end`}
              >
                <TextInput
                  name={`flat_rate`}
                  label="Flat Rate"
                  infoIconText={
                    <>
                      A flat rate takes precedence over all other settings. It
                      charges the exact amount, always; as long as the pricing
                      is active based on volume pricing settings.
                    </>
                  }
                  containerClassName="mr-5"
                  type="text" // type="number"
                  disabled={!formValues.is_flat_rate_enabled}
                  register={register}
                  errors={errors}
                />

                <TextInput
                  name={`base_charge`}
                  label="Base Charge"
                  infoIconText={
                    <>
                      A base charge is the root amount before any percentages
                      are added.
                    </>
                  }
                  containerClassName="mr-5"
                  type="text" // type="number"
                  disabled={formValues.is_flat_rate_enabled}
                  register={register}
                  errors={errors}
                />

                <TextInput
                  name={`base_charge_threshold_amount`}
                  label={
                    <>
                      Base Charge <br /> Threshold Amount
                    </>
                  }
                  infoIconText={
                    <>
                      If the amount to calculate pricing reaches this amount, an
                      action is taken on the base charge eg apply base charge,
                      remove base charge, etc
                    </>
                  }
                  containerClassName="mr-5"
                  type="text" // type="number"
                  disabled={formValues.is_flat_rate_enabled}
                  register={register}
                  errors={errors}
                />

                <HookFormSelect
                  name={`base_charge_threshold_action`}
                  label={
                    <>
                      Action on Base Charge When <br /> Amont = Threshold Amount
                    </>
                  }
                  infoIconText="This is the action that will be taken on the base charge once the amount to calculate pricing reaches the threshold amount."
                  placeholder="Choose action"
                  selectItems={Object.values(baseChargeThresholdAction).map(
                    (val) => ({
                      text: splitCamelCaseString(val),
                      // ?.concat(
                      //   val === baseChargeThresholdAction.noAction
                      //     ? ""
                      //     : " Than Threshold"
                      // )
                      value: val,
                    })
                  )}
                  containerClassName="mr-5"
                  disabled={formValues.is_flat_rate_enabled}
                  register={register}
                  errors={errors}
                />

                <TextInput
                  name={`percentage_charge`}
                  label="Percentage"
                  infoIconText={
                    <>
                      A % that is added to the base charge. This has no effect
                      if flat-rate is enabled.
                    </>
                  }
                  containerClassName="mr-5"
                  type="text" // type="number"
                  disabled={formValues.is_flat_rate_enabled}
                  register={register}
                  errors={errors}
                />

                <TextInput
                  name={`charge_cap`}
                  label="Charge Cap"
                  infoIconText={
                    <>
                      The max amount permissible as charge. This is effected as
                      a ceiling amount after charges have been calculated ie if
                      the charges exceeds this value, it is set to this value
                    </>
                  }
                  containerClassName="mr-5"
                  type="text" // type="number"
                  disabled={
                    formValues.is_flat_rate_enabled ||
                    !formValues.is_charge_cap_enabled
                  }
                  register={register}
                  errors={errors}
                />
              </div>
            </div>

            <div className="flex-container justify-content--end mt-5">
              <div
                id="md_alignment_helper"
                className="flex-box flex-1_5 d-none d-md-flex"
              />
              <div className="flex-box flex-container">
                <SwitchAppButton
                  kind="secondary"
                  onClick={closeAddVolumePricingView}
                  size="sm"
                >
                  Cancel
                </SwitchAppButton>

                <SubmitButton size="sm" className="ml-5">
                  Save New Volume Pricing Option
                </SubmitButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ModalContainer>
  );
};
