import { currencies } from "../constants";
import { ISwAppSupportedCurrency } from "../interfaces-and-types";

export function formatAsCurrency(
  value?: number | null,
  fixedDecimals: number = 2,
  noCommas: boolean = false
): string {
  if (!value && value !== 0) return "--";

  return noCommas
    ? Number(value)?.toFixed(fixedDecimals)
    : currencyWithCommas(value?.toFixed(fixedDecimals));
}

export function hideBalance(amount: number, replacementString: string): string {
  return formatAsCurrency(amount)?.replace(/[0-9]/g, replacementString);
}

function currencyWithCommas(x: string): string {
  const parts = x?.split(".");
  parts[0] = parts && parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return parts?.join(".");
}

export const getCurrencySymbol = (
  currency: string /*, supportedCurrencies: ISwAppSupportedCurrency[] = []*/
) => {
  const swAppCurrency = currencies.find((c) => c.value === currency);

  return swAppCurrency?.currencySymbol || currency;
};
