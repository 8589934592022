export const View = (props) => {
  return (
    <div
      className={`modal-section-title flex-container row justify-content--between ${props.className}`}
    >
      <div className="main-header">{props.key_id}</div>
      <div className="secondary-section">{props.value}</div>
    </div>
  );
};
