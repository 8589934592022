import { useEffect, useState } from "react";
import { ParamKeyValuePair } from "react-router-dom";
import { useQueryString, useQueryStringFilter } from "../../hooks";

import { IQueryStringNavLinks } from "../../interfaces-and-types";
import { View } from "./View";

interface IQuickTableFilterTabsProps {
  queryStringTabNavLinks: IQueryStringNavLinks;
  tabListType?: "" | "contained";
  containerClassName?: string;
}

export interface IQuickTableFilterTabsViewProps
  extends IQuickTableFilterTabsProps {
  activeTab: string;
  setQueryStringFilter: () => void;
}

export const QuickTableFilterTabs = (props: IQuickTableFilterTabsProps) => {
  const query = useQueryString();
  const activeTabOnUrl = query.get(props.queryStringTabNavLinks.query);

  const selectedIndexUrl = props.queryStringTabNavLinks.options.findIndex(
    (opt, i) => opt.value === activeTabOnUrl
  );

  const [defaultSelectedIndex, setDefaultSelectedIndex] = useState(0);
  useEffect(() => {
    setDefaultSelectedIndex(selectedIndexUrl > -1 ? selectedIndexUrl : 0);
  }, []);

  const [selectedIndex, setSelectedIndex] = useState(
    selectedIndexUrl > -1 ? selectedIndexUrl : 0
  );

  const defaultInit: ParamKeyValuePair[] =
    props.queryStringTabNavLinks.options.map((qst) => [
      props.queryStringTabNavLinks.query,
      qst.value,
    ]);

  const { setQueryStringFilter, clearQueryStringFilter } =
    useQueryStringFilter(defaultInit);

  // set active tab on url
  useEffect(() => {
    const queryKey = props.queryStringTabNavLinks.query;
    const selectedTabQueryValue =
      props.queryStringTabNavLinks.options[selectedIndex].value;

    selectedTabQueryValue === "all"
      ? clearQueryStringFilter(queryKey)
      : setQueryStringFilter(queryKey, selectedTabQueryValue);
  }, [selectedIndex]);

  return (
    <View
      {...props}
      setQueryStringFilter={setQueryStringFilter}
      defaultSelectedIndex={defaultSelectedIndex}
      selectedIndex={selectedIndex}
      onChange={(value: any) => setSelectedIndex(value.selectedIndex)} // use with selectedIndex
      // tabListType={props.tabListType}
    />
  );
};
