import { Toggle as CarbonToggle } from "@carbon/react";

import { InfoIcon } from "../../components";

export const View = (props) => {
  const {
    containerClassName,
    label,
    labelRight,
    labelOn,
    labelOff,
    invalidText,
    infoIconText,
    ...rest
  } = props;

  // console.log("defaultToggled Toggle: ", rest.defaultToggled);

  const ToggleLabel = () => (
    <div className="align-items--center title-container ml-5">
      <label htmlFor={props.name} className="checkbox-title font-ctm--bold">
        {!props.label && !infoIconText ? null : (
          <div className="flex-container">
            <div className="flex-box text-secondary">{props.label} </div>
            {infoIconText ? (
              <div className="ml-3">
                <InfoIcon
                  align={props.infoIconAlignment}
                  message={infoIconText}
                />{" "}
              </div>
            ) : null}
          </div>
        )}
      </label>
    </div>
  );

  return (
    <div className={`toggle-container ${containerClassName || ""}`}>
      <div className="flex-container justify-content--start align-items--end">
        {!labelRight ? <ToggleLabel /> : null}
        <div className="checkbox-wrapper ml-5">
          <CarbonToggle
            // helperText={props.helperText}
            // placeholder={props.placeholder}
            {...rest}
            id={props.name}
            labelA={labelOff}
            labelB={labelOn}
            labelText={""}
            // labelText={
            //   !props.label && !infoIconText ? (
            //     <></>
            //   ) : (
            //     <div className="flex-container">
            //       {/* <div className="flex-box">{props.label} </div> */}
            //       {infoIconText ? (
            //         <div className="ml-3">
            //           <InfoIcon message={infoIconText} />{" "}
            //         </div>
            //       ) : null}
            //     </div>
            //   )
            // }
            // labelText={
            //   !props.label && !infoIconText ? null : (
            //     <div className="flex-container">
            //       <div className="flex-box">{props.label} </div>
            //       {infoIconText ? (
            //         <div className="ml-3">
            //           <InfoIcon message={infoIconText} />{" "}
            //         </div>
            //       ) : null}
            //     </div>
            //   )
            // }
            className={`${props.className} switchapp-themed`}
            // onToggle={register(props.name).onChange}
          />
        </div>
        {labelRight ? <ToggleLabel /> : null}
      </div>
      {invalidText ? (
        <div className="cds--form-requirement">{invalidText}</div>
      ) : null}
    </div>
  );
};
