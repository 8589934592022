import { IHeaderMenuDropDownViewProps } from "./HeaderMenuDropDown";
import { useRef } from "react";
import { useClickOutside } from "../../hooks";

export function View<TData>(props: IHeaderMenuDropDownViewProps<TData>) {
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(
    ref,
    props.open,
    props.ignoreClickOutside ? () => {} : props.closeOnClickOutside
  );

  return (
    <div className={props.headerContainerClass} ref={ref}>
      <button className={props.className} onClick={props.toggleOpen}>
        {/* {props.selectedItem && props.renderSelectedItem(props.selectedItem)} */}
        {props.renderInButton}
      </button>

      {props.open ? (
        <div className={props.headerDropdownContentClass}>
          {props.renderBefore}
          {props.renderBefore ? (
            <hr style={{ border: "1px dashed #ddd" }} />
          ) : null}

          <div className="dropdown-items--list">
            {props.renderNonListOnOpen ? (
              <>{props.renderNonListOnOpen}</>
            ) : (
              props.items.map((item, index) => (
                <div
                  key={index}
                  className={props.headerMenuDropdownContentItemClass}
                  onClick={() => props.onSelect(item)}
                >
                  {props.headerMenuDropdownButton ? (
                    <button className="header-menu-dropdown--button">
                      {props.renderItem(item)}
                    </button>
                  ) : (
                    props.renderItem(item)
                  )}
                </div>
              ))
            )}

            {props.renderNonListOnOpen || props.items?.length > 0 ? null : (
              <div className="empty-list text-danger">No items in list...</div>
            )}
          </div>

          {props.renderAfter ? (
            <hr style={{ border: "1px dashed #ddd" }} />
          ) : null}
          {props.renderAfter}
        </div>
      ) : null}
    </div>
  );
}
