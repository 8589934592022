import { useActiveTenant, useAuth } from "../../hooks";
import { IPageTabNavLink } from "../../interfaces-and-types";
import { View } from "./View";

export const PageTabNavLinks = (props: {
  pageTabNavLinks: IPageTabNavLink[];
  boundedUnderline?: boolean;
  className?: string;
}) => {
  // const location = useLocation();
  const { user: adminUser } = useAuth();
  const { activeTenant, setActiveTenant } = useActiveTenant();

  return (
    <View
      {...props}
      setActiveTenant={setActiveTenant}
      activeTenantId={adminUser?.email}
      activeTenant={activeTenant}
    />
  );
};
