import qs from "qs";

export function isEmptyObject(obj: object): boolean {
  return Object.keys(obj).length === 0;
}

type OverPostedObject<T> = T & { [key: string]: any };

export function pickObjectProperties<T extends {}>(
  sourceObj: OverPostedObject<T>,
  destinationObj: T
): T {
  const keys: (keyof T)[] = Object.keys(destinationObj) as (keyof T)[];

  return keys.reduce((acc, key) => {
    if (sourceObj.hasOwnProperty(key)) {
      acc[key] = sourceObj[key];
    }
    return acc;
  }, destinationObj);
}

export const convertToQueryString = (params: Record<string, any>): string => {
  const queryString = qs.stringify(params, { allowDots: true });
  return queryString;
};

// export function convertToQueryString(params: Record<string, any>) {
//   const searchParams = new URLSearchParams();

//   const processParams = (obj: Record<string, any>, parentKey = "") => {
//     for (const key in obj) {
//       if (obj.hasOwnProperty(key)) {
//         const value = obj[key];
//         const fullKey = parentKey ? `${parentKey}.${key}` : key;

//         if (typeof value === "object") {
//           processParams(value, fullKey);
//         } else {
//           searchParams.append(fullKey, value);
//         }
//       }
//     }
//   };

//   processParams(params);

//   return searchParams.toString();
// }
