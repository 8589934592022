import moment from "moment";

import { Badge, CurrencyDisplay } from "../../components";
import { appConfig } from "../../configs";
import { IViewTopUpListItem, StatusColors } from "../../interfaces-and-types";
import { getPaymentStatusIndicator } from "../../utils";

export const topUpsDisplayFormatter = (item: IViewTopUpListItem) => {
  const statusIndicator: { statusText: string; statusType: StatusColors } =
    getPaymentStatusIndicator(item);

  return {
    ...item,
    amount: (
      <CurrencyDisplay currencyOrSymbol={item.currency} amount={item.amount} />
    ),
    status: (
      <Badge
        text={statusIndicator.statusText}
        statusColor={statusIndicator.statusType}
      />
    ),
    description: <strong>{item.description || "..."}</strong>,
    gateway: (
      <img
        src={`${process.env.PUBLIC_URL}/gateway-logos/${item.gateway_code}.svg`}
        alt="gateway logo"
        className="gateway-logo-full"
      />
    ),
    date: (
      <span className="date--oneline">
        {moment(item.created_at).format(appConfig.dateTimeFormat)}
      </span>
    ),
    // more: <OverflowMenuDropDown />,
  };
};
