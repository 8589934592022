import { Route, Routes } from "react-router-dom";
import { ManageSpecialPricingForm } from "./ManageSpecialPricing/ManageSpecialPricing";

import { ISpecialPricingModalViewProps } from "./SpecialPricing";
import { SpecialPricingDetails } from "./SpecialPricingDetails";

export const View = (props: ISpecialPricingModalViewProps) => {
  return (
    <div className="summaries-container">
      <Routes>
        <Route index element={<SpecialPricingDetails {...props} />} />

        <Route
          path="manage/*"
          element={<ManageSpecialPricingForm /*{...props}*/ />}
        />
      </Routes>
    </div>
  );
};
