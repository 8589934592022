import React from "react";
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableExpandHeader,
  TableHeader,
  TableBody,
  TableExpandRow,
  TableCell,
  TableExpandedRow,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListCell,
  StructuredListRow,
  StructuredListBody,
} from "@carbon/react";

export const View = (props) => {
  return (
    <div className="card flex-container col">
      <div className="key-text">{props.key_id}</div>
      <div className="value-text">{props.value}</div>
    </div>
  );
};
