import { useState } from "react";

import { View } from "./View";

export interface IAccountModeSwitcherViewProps {
  is_liveMode: boolean;
  toggleAccountMode: () => void;
}

export const AccountModeSwitcher = () => {
  const [isLive, setIsLive] = useState(true);

  const toggleAccountMode = () => {}; // setIsLive((isLive) => !isLive);

  return <View is_liveMode={isLive} toggleAccountMode={toggleAccountMode} />;
};
