import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useDebounce, useAdminApiQuery } from "../hooks";

import {
  IRemoteDataContext,
  IMerchantAccountSettings,
} from "../interfaces-and-types";
import { AccountSettingsContext } from "./AccountSettingsContext";

export function AccountSettingsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const queryClient = useQueryClient();
  const [isRefreshing, setIsRefreshing] = useState<boolean>(true);
  const debouncePeriodElapsed = useDebounce(100, isRefreshing);

  const { isSuccess, isError, isLoading, data } =
    useAdminApiQuery<IMerchantAccountSettings>("getAccountSettings", {
      enabled: false, // debouncePeriodElapsed, // TODO: uncomment after implementing account settings
    });

  useEffect(() => {
    if (isRefreshing) {
      queryClient.refetchQueries(["getAccountSettings"]);

      setIsRefreshing(false);
    }
  }, [isRefreshing]);

  const refreshRemoteData = () => {
    setIsRefreshing(true);
  };

  // const is_live = data?.is_live || false;

  // const memoedAccountSettingsContext = useMemo(() => {
  const unMemoedAccountSettingsContext: IRemoteDataContext<IMerchantAccountSettings | null> =
    {
      isSuccess,
      isError,
      isLoading,
      data: data || null,
      refreshRemoteData,
    };

  //   return unMemoedAccountSettingsContext;
  // }, [isLoadingAccountSettings, isRefreshing, is_live]);

  return (
    <AccountSettingsContext.Provider value={unMemoedAccountSettingsContext}>
      {children}
    </AccountSettingsContext.Provider>
  );
}
