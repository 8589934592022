import { useState } from "react";

import { useAdminApiQueryWithParams } from "../../../hooks";
import {
  ITransfersSummary,
  ITransfersOverview,
} from "../../../interfaces-and-types";
import { TSummaryPeriod } from "../../../constants";
import { ISummarySectionProps, ISummarySectionViewProps } from "../Home";

import { View } from "./View";

export interface ITransfersSummaryViewProps
  extends ISummarySectionViewProps<ITransfersOverview> {}

export const TransfersSummary = ({ activeCurrency }: ISummarySectionProps) => {
  const {
    isLoading: isLoadingTransfersSummary,
    // isSuccess: isSuccessTransfersSummary,
    // isError: isErrorTransfersSummary,
    data: transfersSummary,
  } = useAdminApiQueryWithParams<{ currency: string }, ITransfersSummary>(
    [
      "getTransfersSummary",
      {
        currency: activeCurrency!,
      },
    ],
    {
      enabled: !!activeCurrency,
    }
  );

  const isLoadingCustomPeriodSummary = false; // update after adding fetch logic for custom

  const [summaryPeriod, setSummaryPeriod] = useState<TSummaryPeriod>("today");

  const summaryToDisplay =
    summaryPeriod === "today"
      ? transfersSummary?.today
      : summaryPeriod === "past week"
      ? transfersSummary?.past_week
      : summaryPeriod === "past month"
      ? transfersSummary?.past_month
      : // summaryPeriod === "custom" ? customPeriodTransfersSummary :
        transfersSummary?.all_time;

  const isLoadingSummary =
    summaryPeriod === "custom"
      ? isLoadingCustomPeriodSummary
      : isLoadingTransfersSummary;

  return (
    <View
      activeCurrency={activeCurrency}
      isLoadingSummary={isLoadingSummary}
      summaryToDisplay={summaryToDisplay || null}
      summaryPeriod={summaryPeriod}
      setSummaryPeriod={setSummaryPeriod}
    />
  );
};
