import { useState, useEffect, RefObject } from "react";

export const useClickOutside = (
  ref: RefObject<HTMLDivElement>,
  initialState: boolean = false,
  closeOnClickOutside: () => void
) => {
  const [open, setOpen] = useState(initialState);

  useEffect(() => {
    const handleClickOutside = (
      event: any /*MouseEvent<HTMLDivElement, MouseEvent>*/
    ) => {
      // console.log("useClickOutsideRef event target: ", event.target);
      // console.log("useClickOutsideRef current: ", ref?.current);
      // console.log(
      //   "ref?.current?.contains(event.target): ",
      //   ref?.current?.contains(event.target)
      // );

      if (!ref?.current?.contains(event.target)) {
        setOpen(false);
        closeOnClickOutside();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref]);

  // return { open, setOpen };
};
