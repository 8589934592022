import moment from "moment";
import { Badge, LinkUrlCopyDisplay } from "../../components";
import { appConfig } from "../../configs";

import { IViewInvitationCode, StatusColors } from "../../interfaces-and-types";
import { getInvitationCodeStatusIndicator } from "../../utils";

export const invitationCodesDisplayFormatter = (item: IViewInvitationCode) => {
  const statusIndicator: { statusText: string; statusType: StatusColors } =
    getInvitationCodeStatusIndicator(item);

  return {
    ...item,
    code: <LinkUrlCopyDisplay link={item.code} />,
    status: (
      <Badge
        text={statusIndicator.statusText}
        statusColor={statusIndicator.statusType}
      />
    ),
    used_at: (
      <span className="date--oneline">
        {item.used_at
          ? moment(item.used_at).format(appConfig.dateTimeFormat)
          : null}
      </span>
    ),
    expires_at: (
      <span className="date--oneline">
        {moment(item.expires_at).format(appConfig.dateTimeFormat)}
      </span>
    ),
    created_at: (
      <span className="date--oneline">
        {moment(item.created_at).format(appConfig.dateTimeFormat)}
      </span>
    ),
    // more: <OverflowMenuDropDown />,
  };
};
