import { TCarbonComponentSizeProps } from "../../interfaces-and-types";
import { View } from "./View";

export type TLoadingStatus = "inactive" | "active" | "finished" | "error";

interface ISwitchAppInlineLoadingIcon {
  className?: string;
  description?: string;
  iconDescription?: string;
  onSuccess?: () => void;
  status?: TLoadingStatus;
  successDelay?: number;
  loaderSize?: TCarbonComponentSizeProps;
  textSize?: TCarbonComponentSizeProps;
}

export const SwitchAppInlineLoadingIcon = (
  props: ISwitchAppInlineLoadingIcon
) => {
  return <View {...props} />;
};
