import { ReactNode } from "react";
import { View } from "./View";

export interface ModalSectionTitleProps {
  key_id: ReactNode;
  value?: ReactNode | null;
  className?: string;
}

export const ModalSectionTitle = (props: ModalSectionTitleProps) => {
  return <View {...props} />;
};
