import { ContentSwitcher, Switch } from "@carbon/react";

export const View = ({ size = "sm", onChange, options, selectedIndex }) => {
  return (
    <ContentSwitcher
      size={size}
      onChange={onChange}
      selectedIndex={selectedIndex}
    >
      {/* <Switch name="one" text="Card" /> */}
      {options.map((opt) => (
        <Switch key={opt.value} name={opt.value} title={opt.key}>
          {opt.key}
        </Switch>
      ))}
    </ContentSwitcher>
  );
};
