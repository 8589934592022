import { Route, Routes } from "react-router-dom";

import { Payments, TopUps } from "../../pages";

export const PaymentsLayout = () => {
  return (
    <Routes>
      <Route path="/*" element={<Payments />} />
      <Route path="/top-ups" element={<TopUps />} />
    </Routes>
  );
};
