import { useState } from "react";
import { TSummaryPeriod } from "../../../constants";
import { useAdminApiQueryWithParams } from "../../../hooks";
import { ITopUpsSummary, ITxOverview } from "../../../interfaces-and-types";
import { View } from "./View";
import { ISummarySectionProps, ISummarySectionViewProps } from "../Home";

export interface ITopUpsSummaryViewProps
  extends ISummarySectionViewProps<ITxOverview> {}

export const TopUpsSummary = ({ activeCurrency }: ISummarySectionProps) => {
  const {
    isLoading: isLoadingTopUpsSummary,
    // isSuccess: isSuccessTopUpsSummary,
    // isError: isErrorTopUpsSummary,
    data: topUpsSummary,
  } = useAdminApiQueryWithParams<{ currency: string }, ITopUpsSummary>(
    [
      "getTopUpsSummary",
      {
        currency: activeCurrency!,
      },
    ],
    {
      enabled: !!activeCurrency,
    }
  );

  const isLoadingCustomPeriodSummary = false; // update after adding fetch logic for custom

  const [summaryPeriod, setSummaryPeriod] = useState<TSummaryPeriod>("today");

  const summaryToDisplay =
    summaryPeriod === "today"
      ? topUpsSummary?.today
      : summaryPeriod === "past week"
      ? topUpsSummary?.past_week
      : summaryPeriod === "past month"
      ? topUpsSummary?.past_month
      : // summaryPeriod === "custom" ? customPeriodTransactionsSummary :
        topUpsSummary?.all_time;

  const isLoadingSummary =
    summaryPeriod === "custom"
      ? isLoadingCustomPeriodSummary
      : isLoadingTopUpsSummary;

  return (
    <View
      activeCurrency={activeCurrency}
      isLoadingSummary={isLoadingSummary}
      summaryToDisplay={summaryToDisplay || null}
      summaryPeriod={summaryPeriod}
      setSummaryPeriod={setSummaryPeriod}
    />
  );
};
