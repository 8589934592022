import { useMemo, useState } from "react";

import { IApiErrorContext, IApiErrorDictionary } from "../interfaces-and-types";
import { defaultApiErrorState } from "./ApiErrorsContext";
import { ApiErrorsContext } from "./ApiErrorsContext";

export function ApiErrorsProvider({ children }: { children: React.ReactNode }) {
  const [apiErrors, setApiErrorsState] = useState<IApiErrorDictionary>(
    defaultApiErrorState.apiErrors
  );

  const setApiErrors = (errors: IApiErrorDictionary) =>
    setApiErrorsState(errors);

  const clearApiErrors = () =>
    setApiErrorsState(defaultApiErrorState.apiErrors);

  const clearApiErrorByName = (name: string) => {
    const {
      [name]: [],
      ...rest
    } = apiErrors;
    setApiErrorsState({ ...rest });
  };

  const memoedContextValue: IApiErrorContext = useMemo(
    () => ({
      apiErrors,
      setApiErrors,
      clearApiErrors,
      clearApiErrorByName,
    }),
    [apiErrors, setApiErrors, clearApiErrors, clearApiErrorByName]
  );

  return (
    <ApiErrorsContext.Provider value={memoedContextValue}>
      {children}
    </ApiErrorsContext.Provider>
  );
}
