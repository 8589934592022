import { ReactNode } from "react";
import { useDebounce } from "../../hooks";

import { IModalFormProps } from "../../interfaces-and-types";
import { View } from "./View";

interface IModalContainerProps extends IModalFormProps {
  modalHeader?: ReactNode;
  children: ReactNode;
  modalFooter?: ReactNode;
  className?: string;
  modalBodyClassName?: string;
  fullscreen?: boolean;
  selectorPrimaryFocus?: string;
}

export const ModalContainer = (props: IModalContainerProps) => {
  const debouncePeriodElapsed = useDebounce(200);

  return (
    <View
      {...props}
      isModalOpen={props.isModalOpen && debouncePeriodElapsed}
      modalHeader={props.modalHeader}
      modalFooter={props.modalFooter}
      className={props.className}
      fullscreen={props.fullscreen}
      selectorPrimaryFocus={props.selectorPrimaryFocus}
    />
  );
};
