import { ReactNode } from "react";
import { View } from "./View";

export interface ICardProps {
  noPadding?: boolean;
  className?: string;
  contentContainerClassName?: string;
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
}

export const Card = (props: ICardProps) => {
  const contentContainerClassName = `${props.contentContainerClassName} ${
    props.noPadding ? "no-padding" : ""
  }`;

  return (
    <View {...props} contentContainerClassName={contentContainerClassName} />
  );
};
