import { QuickTable, QuickTableFilterTabs } from "../../components";
import { CreateInvitationCodeForm } from "./CreateInvitationCodeForm";
import { invitationCodesDisplayFormatter } from "./invitationCodesDisplayFormatter";
import { IInvitationCodeViewProps } from "./InvitationCodes";
import { Route, Routes } from "react-router-dom";

export const View = (props: IInvitationCodeViewProps) => {
  return (
    <Routes>
      <Route
        index
        element={
          <>
            <QuickTable
              id="invitationCodesTable"
              data={props.data}
              headers={props.headers}
              isLoading={props.isLoading}
              isError={props.isError}
              itemCount={props.totalCount}
              // renderDetail={(item) => item.description}
              toolbarTabs={
                <QuickTableFilterTabs
                  queryStringTabNavLinks={props.queryStringTabs}
                />
              }
              displayFormatter={invitationCodesDisplayFormatter}
              onExport={props.onExport}
              openCreateNewModal={props.openCreateNewModal}
              createNewButtonText="New Invite"
              // openDetailsModal={props.openDetailsModal}
            />
          </>
        }
      />

      <Route
        path="create"
        element={
          <CreateInvitationCodeForm
            refreshTable={props.refreshTable}
            goBackOnClose={false}
          />
        }
      />

      {/* <Route path=":invitation_id/details" element={<InvitationCodeDetailsModal />} /> */}
    </Routes>
  );
};
