import { baseChargeThresholdAction } from "../../../../enums";
import {
  IAddEditPricingOptionBase,
  IEditPricingOption,
} from "../../../../interfaces-and-types";

export const defaultAddOrEditPricingOptionBase: IAddEditPricingOptionBase = {
  // is_international_pricing_option: false,
  is_flat_rate_enabled: false,
  flat_rate: null,

  base_charge: 0,
  base_charge_threshold_amount: null,
  base_charge_threshold_action: baseChargeThresholdAction.noAction,

  percentage_charge: null,

  is_charge_cap_enabled: false,
  charge_cap: null,
};

export const defaultEditPricingOption: IEditPricingOption = {
  id: "",

  ...defaultAddOrEditPricingOptionBase,
};
