import { ISwAppTransactionsApi } from "../../interfaces-and-types";

type TTransactionsUrls = {
  [key in keyof ISwAppTransactionsApi]: string;
};

const rootTransactionsUrl = "/transactions";

const rootTopUpsUrl = "/top-ups";
// const rootTopUpTransactionsUrl = "/topup-transactions";

export const transactionsUrls: TTransactionsUrls = {
  getTransactions: `${rootTransactionsUrl}`,
  getTransactionById: `${rootTransactionsUrl}/id`,
  getTransactionByRef: `${rootTransactionsUrl}/reference/tx_ref`,

  // top-up txs
  getTopUps: `${rootTopUpsUrl}`,
  getTopUpById: `${rootTopUpsUrl}/id`,
};
