import moment from "moment";

export function logDataToConsole(data: any, title: string = "log to console") {
  console.log(" ");
  console.log(" ");
  console.log(" ");
  console.log(
    `%c---------------------------  ${title} log start: ${moment().format(
      "ddd, DD-MM-YYYY HH:mm:ss"
    )}  -----------------------`,
    "color: blue; font-weight: bold;"
  );
  console.log(" ");
  console.log(data);
  console.log(" ");
  console.log(
    `%c---------------------------  ${title} log end  -----------------------`,
    "color: red; font-weight: bold;"
  );
}
