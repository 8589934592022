import {
  Link,
  LinkProps,
  NavLink,
  useMatch,
  useResolvedPath,
} from "react-router-dom";

export const CustomNavLink = ({ children, to, ...props }: LinkProps) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  const isMatch = match;

  return (
    <Link
      // style={{ textDecoration: match ? "underline" : "none" }}
      to={to}
      {...props}
      className={`${props.className} ${isMatch ? "active" : ""}`}
    >
      {children}
    </Link>
  );
};
