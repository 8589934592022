import notificationCircle from "../../assets/icons/notificationCircle.svg";

export const NotificationView = (props: any) => {
  return (
    <div className="flex-container">
      <div className="flex-container col justify-content--center align-items--center notification-text">
        <div className="notification-top flex-container justify-content--between align-items--center">
          <div className="notification-head-text">Notifications</div>
          <div className="notification-sub-text">Mark as read</div>
        </div>
        <img
          src={notificationCircle}
          alt="department"
          className="notification-view--icon"
        />
        <div>No notifications yet</div>
        <div>Messages and alerts about your account will show up here</div>
      </div>
    </div>
  );
};
