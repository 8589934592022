import { Badge, SwitchAppIconButton } from "../../../components";

import refreshIcon from "../../../assets/icons/refresh-3.png";
import { SummaryPeriodSwitcher } from "../SummaryPeriodSwitcher";
import { IMerchantsSummaryViewProps } from "./MerchantsSummary";

export const View = ({
  isLoadingSummary,
  summaryToDisplay,
  summaryPeriod,
  setSummaryPeriod,
}: IMerchantsSummaryViewProps) => {
  return (
    <div className="summary-section merchants-summary--container flex-box flex-container col">
      <div className="flex-container summary-section--header">
        <h5 className="font-ctm--semibold flex-box">Merchants Summary</h5>
        <div className="close-button--container">
          <SwitchAppIconButton
            size="sm"
            kind="tertiary"
            className=""
            onClick={() => {}}
          >
            <img
              src={refreshIcon}
              alt="refresh icon"
              title="Refresh Data"
              className="refresh-icon"
            />
          </SwitchAppIconButton>
        </div>
      </div>

      <SummaryPeriodSwitcher
        setSummaryPeriod={setSummaryPeriod}
        summaryPeriod={summaryPeriod}
      />

      <div className="flex-box summary-section--body">
        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            New Merchants
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {summaryToDisplay?.new_merchants}
          </div>
        </div>

        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            Verified Merchants
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {summaryToDisplay?.verified_merchants}
          </div>
        </div>

        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            Verification Requested
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {summaryToDisplay?.verification_pending}
            {summaryToDisplay && summaryToDisplay?.verification_pending > 0 ? (
              <Badge
                statusColor="danger"
                className="ml-5"
                text="attention required"
              />
            ) : null}
          </div>
        </div>

        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            Verification Rejected
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {summaryToDisplay?.verification_rejected}
          </div>
        </div>
      </div>
    </div>
  );
};
