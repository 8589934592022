import { ReactNode } from "react";
import { View } from "./View";
import { IKeyValuePairT } from "../../../interfaces-and-types";

export interface IModalTopSectionProps {
  closeModal: (data: any) => void;
  header: string;
  subHeader: ReactNode;
  copyText: string;
  copyContent: string;
}
export const ModalTopSection = (props: IModalTopSectionProps) => {
  return <View {...props} />;
};
