import { ISwAppTransfersApi } from "../../interfaces-and-types";

type TTransfersUrls = {
  [key in keyof ISwAppTransfersApi]: string;
};

const rootTransfersUrl = "/transfers";

export const transfersUrls: TTransfersUrls = {
  createBatchBankTransfer: `${rootTransfersUrl}/bank`,
  // createBatchGatewayTransfer: `${rootTransfersUrl}/gateway`,

  getBatchList: `${rootTransfersUrl}/batch`,
  getBatchById: `${rootTransfersUrl}/batch/id`,
  getBatchByRef: `${rootTransfersUrl}/batch/reference/batch_ref`,
  requeueFailedInBatchByRef: `${rootTransfersUrl}/batch/requeue-failed/batch_ref`,

  getSingleTransfersList: `${rootTransfersUrl}`,
  getSingleTransfersListByBatchRef: `${rootTransfersUrl}/batch/batch_ref/transfers`,
  getSingleTransferById: `${rootTransfersUrl}/single/id`,
  getSingleTransferByRef: `${rootTransfersUrl}/single/reference/ref`,
};
