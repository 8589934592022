import {
  DeepRequired,
  DefaultValues,
  FieldErrorsImpl,
  FieldValues,
  SubmitHandler,
  useForm,
  ValidationMode,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface IUseHookFormProps<
  TFormValues extends { [x: string]: any },
  TFormValidationSchema extends yup.SchemaOf<TFormValues>
> {
  initialFormValues: DefaultValues<TFormValues>;
  validationSchema?: TFormValidationSchema;
  onValid: (values: TFormValues) => void; // (values: TFormValues) => void; // SubmitHandler<FieldValues>
  onInvalid: (errors: FieldErrorsImpl<DeepRequired<TFormValues>>) => void;
  mode?: keyof ValidationMode;
}

export function useHookForm<
  TFormValues extends { [x: string]: any },
  TFormValidationSchema extends yup.SchemaOf<TFormValues>
>(props: IUseHookFormProps<TFormValues, TFormValidationSchema>) {
  const { initialFormValues, onValid, onInvalid, validationSchema, mode } =
    props;

  const formContextMethods = useForm({
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    defaultValues: initialFormValues,
    mode,
  });

  const {
    control,
    getValues,
    setValue,
    setFocus,
    setError,
    getFieldState,
    clearErrors,
    handleSubmit,
    formState: {
      isDirty,
      dirtyFields,
      isValid,
      isSubmitting,
      touchedFields,
      errors,
    },
    register,
    reset,
    resetField,
    trigger,
    watch,
  } = formContextMethods;

  const onFormSubmit = handleSubmit(onValid, onInvalid);

  return {
    formContextMethods,
    control,

    // formstate
    isDirty,
    dirtyFields,
    isValid,
    isSubmitting,
    touchedFields,
    errors,

    // functions
    getValues,
    setValue,
    setFocus,
    setError,
    getFieldState,
    clearErrors,
    onFormSubmit,
    register,
    reset,
    resetField,
    trigger,
    watch,
  };
}
