import { Search } from "@carbon/react";
import { forwardRef } from "react";

export const View = forwardRef(
  ({ name, placeholder = "Search...", label, ...rest }, ref) => {
    return (
      <div className="search-bar--container">
        <Search
          closeButtonLabelText="Clear search text"
          id={name}
          labelText={label || "Search"}
          placeholder={placeholder}
          // onChange={function noRefCheck() {}}
          // onKeyDown={function noRefCheck() {}}
          {...rest}
          ref={ref}
        />
      </div>
    );
  }
);
