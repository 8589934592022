// import { truncateStringWithEllipsis } from "../../utils";
import { useAuth } from "../../hooks/useAuth";
import { Badge } from "../Badge";
interface IUserIconViewProps {
  navigateToProfile: () => void;
  onSignOut: () => void;
}

export const UserIconView = (props: IUserIconViewProps) => {
  const { user } = useAuth();
  return (
    <div className="flex-container col">
      <div className="user-top">
        <div className="notification-head-text">
          {/* To get the users name */}
          <h5>{user?.last_name + " " + user?.first_name}</h5>
          <p className="user-email">{user?.email}</p>
        </div>
      </div>

      {/* <div onClick={props.navigateToProfile} className="user-buttons">
        My Profile
      </div> */}

      <div onClick={props.onSignOut} className="user-buttons red">
        Sign out
      </div>
    </div>
  );
};
