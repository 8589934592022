import { ISwAppAnalyticsApi } from "../../interfaces-and-types";

type TAnalyticsUrls = { [key in keyof ISwAppAnalyticsApi]: string };

const rootAnalyticsUrl = "/analytics";

export const analyticsUrls: TAnalyticsUrls = {
  testNetworkConnection: `${rootAnalyticsUrl}/health-check`,
  // getAnalytics: `${rootAnalyticsUrl}`,
  // getAnalyticsByCustomer: `${rootAnalyticsUrl}/customer`,
  // getAnalyticsByCustomerAndDate: `${rootAnalyticsUrl}/customer/date`,
  // getAnalyticsByCustomerAndDateAndType: `${rootAnalyticsUrl}/customer/date/type`,
  // getAnalyticsByCustomerAndType: `${rootAnalyticsUrl}/customer/type`,
  // getAnalyticsByDate: `${rootAnalyticsUrl}/date`,
  // getAnalyticsByDateAndType: `${rootAnalyticsUrl}/date/type`,
  // getAnalyticsByType: `${rootAnalyticsUrl}/type`,
};
