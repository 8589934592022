import { FilterEdit } from "@carbon/icons-react";
import moment from "moment";

import { appConfig } from "../../configs";
import { IViewMerchantList } from "../../interfaces-and-types";
import { CopyButton, SwitchAppButton } from "../../components";

import tickCircleBlueIcon from "../../assets/icons/tick-circle-blue.svg";
import closeCircleIcon from "../../assets/icons/close-circle.svg";

export const merchantsDisplayFormatter = (item: IViewMerchantList) => {
  return {
    ...item,
    merchant_name: (
      <span title={item.merchant_name} className="font-ctm--bold">
        {item.merchant_name.toUpperCase()}
      </span>
    ),
    email: (
      <div className="flex-container justify-content--start align-items--center no-wrap">
        <div className="merchant-email modal-header--title">
          <span
            title={item.email}
            className={`font-ctm--bold ${
              item.email_confirmed ? "text-success" : "text-danger"
            }`}
          >
            {item.email}
          </span>
        </div>
        <div className="copy-button--container ml-3">
          <CopyButton
            stringToCopy={item.email}
            iconDescription="copy merchant email"
            className="copy-btn"
          />
        </div>
      </div>
    ),
    phone_number: (
      <div className="flex-container justify-content--start align-items--center no-wrap">
        <div className="merchant-phonenumber modal-header--title">
          <span title={item.phone_number}>{item.phone_number}</span>
        </div>
        <div className="copy-button--container ml-3">
          <CopyButton
            stringToCopy={item.phone_number}
            iconDescription="copy merchant phone number"
            className="copy-btn"
          />
        </div>
      </div>
    ),
    created_at: (
      <span className="date--oneline">
        {moment(item.created_at).format(appConfig.dateTimeFormat)}
      </span>
    ),
    is_special_merchant_pricing_enabled: (
      <img
        src={
          item.is_special_merchant_pricing_enabled
            ? tickCircleBlueIcon
            : closeCircleIcon
        }
        alt="special pricing offered"
        width={30}
        height={30}
      />
    ),
    setFilterByMerchant: (
      <div className="filter-button--column flex-container justify-content--center align-items--center">
        <SwitchAppButton
          onClick={item.setFilterByMerchant}
          title={"filter by this merchant"}
          size="xs"
          kind={item.isActiveTenant ? "success" : "tertiary"}
          className="filter-button filters"
        >
          <FilterEdit />
        </SwitchAppButton>
      </div>
    ),
  };
};
