import { createContext } from "react";

import { IGeneralSearchContext } from "../interfaces-and-types";

export const GeneralSearchContext = createContext<IGeneralSearchContext>({
  isLoading: false,

  searchText: null,
  setSearchText: () => {},

  data: null,
});
