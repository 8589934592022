import { ISwAppSupportedServicesApi } from "../../interfaces-and-types";

type TSwitchappSupportedServices = {
  [key in keyof ISwAppSupportedServicesApi]: string;
};

const rootSupportedServicesUrl = "/supported-services";

export const switchappSupportedServicesUrls: TSwitchappSupportedServices = {
  getSupportedCountries: `${rootSupportedServicesUrl}/countries`,

  getSupportedCurrencies: `${rootSupportedServicesUrl}/currencies`,
  getSupportedCurrenciesByCountry: `${rootSupportedServicesUrl}/currencies-by-country`,

  getSupportedGateways: `${rootSupportedServicesUrl}/gateways`,
  getSupportedGatewaysByCountry: `${rootSupportedServicesUrl}/gateways-by-country`,
  getMerchantEnabledCheckoutGateways: `${rootSupportedServicesUrl}/checkout-gateways`,

  getSupportedPaymentChannels: `${rootSupportedServicesUrl}/payment-methods`,
  // getSupportedPaymentMethodsByCountry: `${rootSupportedServicesUrl}/payment-methods-by-country`,
};
