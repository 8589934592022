import { useCallback, useEffect, useState } from "react";

export function useCopyToClipboard(
  text: string,
  alertText: string,
  alertDurationInMs: number = 2000
) {
  const [displayAlertText, setDisplayAlertText] = useState(false);
  const [alertTextDisplayed, setAlertTextDisplayed] = useState<string | null>(
    null
  );

  const copyToClipboard = useCallback(async () => {
    navigator.clipboard.writeText(text);

    setDisplayAlertText(true);
    setAlertTextDisplayed(alertText);
  }, [text, alertText]);

  useEffect(() => {
    const clearAlertText = () => {
      setDisplayAlertText(false);
      setAlertTextDisplayed(null);
    };

    if (displayAlertText) {
      const timeoutId = setTimeout(clearAlertText, alertDurationInMs);

      return () => clearTimeout(timeoutId);
    }
  }, [displayAlertText]);

  return { copyToClipboard, displayAlertText, alertTextDisplayed };
}
