import moment from "moment";
import { ModalTopSection } from "../../../../components/DetailsModal/ModalTopSection";
import {
  DataLoadingView,
  ErrorOccuredView,
  ModalContainer,
  SectionSubheader,
} from "../../../../components";
import { ModalSectionTitle } from "../../../../components";
import { LeftRightFlexedContainer } from "../../../../components";
import { ICustomerDetailsModalViewProps } from "./CustomerDetailsModal";
import { StructuredList } from "../../../../components";
import { appConfig } from "../../../../configs";

export const View = (props: ICustomerDetailsModalViewProps) => {
  return (
    <div className="customer-details--container">
      <ModalContainer
        isModalOpen={props.isModalOpen}
        closeModal={props.closeModal}
        className="payment-modal-container"
        modalHeader={
          !props.modalData ? (
            <SectionSubheader title="CUSTOMER DETAILS" />
          ) : (
            <ModalTopSection
              header="CUSTOMER"
              subHeader={
                <div className="name-sub-header flex-container align-items--end justify-content--start">
                  {props.modalData.email}
                </div>
              }
              copyText="Customer ID"
              copyContent={props.modalData.id}
              closeModal={props.closeModal}
            />
          )
        }
      >
        {!props.modalData ? (
          <>
            {props.isLoadingModalData ? (
              <DataLoadingView message={"Loading payment link details"} />
            ) : (
              <ErrorOccuredView
                message={
                  "An error occured while loading the payment link details. Please retry..."
                }
              />
            )}
          </>
        ) : (
          <>
            <>
              {/* <ModalSectionTitle key_id="Account Details" value="" /> */}

              <LeftRightFlexedContainer
                key_id="Full Name"
                value={props.modalData.full_name || "..."}
              />
              <LeftRightFlexedContainer
                key_id="Email"
                value={props.modalData.email}
              />
              <LeftRightFlexedContainer
                key_id="Date Added"
                value={moment(props.modalData.created_at).format(
                  appConfig.dateTimeFormat
                )}
              />
              {/* <LeftRightFlexedContainer
                key_id="Amount Spent"
                value={
                  <CurrencyDisplay
                    currencyOrSymbol={"NGN"}
                    amount={2000}
                    amountFontSize={16}
                    symbolFontSize={12}
                  />
                }
              /> */}
              {!props.modalData.phone_number ? null : (
                <LeftRightFlexedContainer
                  key_id="Phone Number"
                  value={props.modalData.phone_number}
                />
              )}
              {/* {!props.modalData.Address ? null : <LeftRightFlexedContainer
            key_id="Address"
            value={props.modalData.Address}
          />}
          {!props.modalData.xxxxxxxx ? null : <LeftRightFlexedContainer
            key_id="xxxxxxxxxxxxx"
            value={props.modalData.xxxxxxxxxxxxx}
          />} */}
            </>

            <>
              <ModalSectionTitle key_id="Payments" value="" />
              <StructuredList
                headers={
                  <div className="cds--structured-list-row cds--structured-list-row--header-row">
                    <div className="cds--structured-list-th">AMOUNT</div>
                    <div className="cds--structured-list-th">STATUS</div>
                    <div className="cds--structured-list-th">
                      INVOICE NUMBER
                    </div>
                    <div className="cds--structured-list-th">DATE CREATED</div>
                  </div>
                }
                {...(props.paymentData.length > 0
                  ? {
                      rows: props.paymentData.forEach((data) => (
                        <div className="cds--structured-list-tbody">
                          <div className="cds--structured-list-row cds--structured-list-row--header-row">
                            {/* <div className="cds--structured-list-td">{data?[0]}</div>
                <div className="cds--structured-list-td">{data?[1]}</div>
                <div className="cds--structured-list-td">{data?[2]}</div>
                <div className="cds--structured-list-td">{data?[3]}</div> */}
                          </div>
                        </div>
                      )),
                    }
                  : {
                      rows: null,
                    })}
              />
            </>

            <>
              <ModalSectionTitle key_id="Invoices" value="" />
              <StructuredList
                headers={
                  <div className="cds--structured-list-row cds--structured-list-row--header-row">
                    <div className="cds--structured-list-th">AMOUNT</div>
                    <div className="cds--structured-list-th">STATUS</div>
                    <div className="cds--structured-list-th">
                      INVOICE NUMBER
                    </div>
                    <div className="cds--structured-list-th">DATE CREATED</div>
                    <div className="cds--structured-list-th">DUE DATE</div>
                  </div>
                }
                {...(props.invoiceData.length > 0
                  ? {
                      rows: props.paymentData.forEach((data) => (
                        <div className="cds--structured-list-tbody">
                          <div className="cds--structured-list-row cds--structured-list-row--header-row">
                            {/* <div className="cds--structured-list-td">{data?[0]}</div>
                <div className="cds--structured-list-td">{data?[1]}</div>
                <div className="cds--structured-list-td">{data?[2]}</div>
                <div className="cds--structured-list-td">{data?[3]}</div> */}
                          </div>
                        </div>
                      )),
                    }
                  : {
                      rows: null,
                    })}
              />
            </>

            <>
              <ModalSectionTitle key_id="Subscriptions" value="" />
              <StructuredList
                headers={
                  <div className="cds--structured-list-row cds--structured-list-row--header-row">
                    <div className="cds--structured-list-th">
                      PRODUCT/SERVICES
                    </div>
                    <div className="cds--structured-list-th">STATUS</div>
                    <div className="cds--structured-list-th">
                      SUBSCRIPTION TYPE
                    </div>
                    <div className="cds--structured-list-th">DATE CREATED</div>
                  </div>
                }
                {...(props.invoiceData.length > 0
                  ? {
                      rows: props.paymentData.forEach((data) => (
                        <div className="cds--structured-list-tbody">
                          <div className="cds--structured-list-row cds--structured-list-row--header-row">
                            {/* <div className="cds--structured-list-td">{data?[0]}</div>
                <div className="cds--structured-list-td">{data?[1]}</div>
                <div className="cds--structured-list-td">{data?[2]}</div>
                <div className="cds--structured-list-td">{data?[3]}</div> */}
                          </div>
                        </div>
                      )),
                    }
                  : {
                      rows: null,
                    })}
              />
            </>

            <>
              <ModalSectionTitle key_id="Payment Methods" value="" />
              <div className="payment-methods payment-methods-break--small">
                <div className="item item-break--small">
                  <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.496 2.132a1 1 0 00-.992 0l-7 4A1 1 0 003 8v7a1 1 0 100 2h14a1 1 0 100-2V8a1 1 0 00.496-1.868l-7-4zM6 9a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1zm3 1a1 1 0 012 0v3a1 1 0 11-2 0v-3zm5-1a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>

                  <div>Bank Transfer</div>
                </div>

                <div className="item item-break--small">
                  <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 4a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm2 2V5h1v1H5zM3 13a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1v-3zm2 2v-1h1v1H5zM13 3a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1V4a1 1 0 00-1-1h-3zm1 2v1h1V5h-1z"
                      clip-rule="evenodd"
                    ></path>
                    <path d="M11 4a1 1 0 10-2 0v1a1 1 0 002 0V4zM10 7a1 1 0 011 1v1h2a1 1 0 110 2h-3a1 1 0 01-1-1V8a1 1 0 011-1zM16 9a1 1 0 100 2 1 1 0 000-2zM9 13a1 1 0 011-1h1a1 1 0 110 2v2a1 1 0 11-2 0v-3zM7 11a1 1 0 100-2H4a1 1 0 100 2h3zM17 13a1 1 0 01-1 1h-2a1 1 0 110-2h2a1 1 0 011 1zM16 17a1 1 0 100-2h-3a1 1 0 100 2h3z"></path>
                  </svg>

                  <div>QR Code</div>
                </div>

                <div className="item item-break--small">
                  <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z"></path>
                    <path
                      fill-rule="evenodd"
                      d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>

                  <div>Card</div>
                </div>

                <div className="item item-break--small">
                  <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>

                  <div>USSD</div>
                </div>

                <div className="item item-break--small">
                  <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>

                  <div>Wallet</div>
                </div>

                <div className="item item-break--small">
                  <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z"></path>
                    <path
                      fill-rule="evenodd"
                      d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>

                  <div>Payment Gateway</div>
                </div>
              </div>
            </>

            {/* <div className="flex-container justify-content--end align-items--end btn-container">
              <button className="done--btn" onClick={props.closeModal}>
                Done
              </button>
            </div> */}
          </>
        )}
      </ModalContainer>
    </div>
  );
};
