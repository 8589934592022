import { createContext } from "react";

import { IActiveCurrencyContext } from "../interfaces-and-types";

export const ActiveCurrencyContext = createContext<IActiveCurrencyContext>({
  activeCurrency: null,
  setActiveCurrency: () => {},

  // remote data
  isSuccess: false,
  isError: false,
  isLoading: false,
  data: [],
  refreshRemoteData: () => {},
});
