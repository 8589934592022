import { Link } from "react-router-dom";

import { HookFormCheckbox, SubmitButton, TextInput } from "../../hook-form";
import {
  FormContainer,
  SwitchAppButton,
  SwitchAppLegalAndContactFooter,
} from "../../components";
import { IHookForm, ILoginRequest } from "../../interfaces-and-types";

import switchAppLogo from "../../assets/images/switchapp-logo.svg";
import { appConstants } from "../../constants";

export const View = ({
  formEmailValue,
  emailNotConfirmed,
  resendVerificationLink,
  errors: errors,
  handleSubmit: handleSubmit,
  register: register,
}: IHookForm<ILoginRequest> & {
  formEmailValue: string;
  emailNotConfirmed: boolean;
  resendVerificationLink: () => void;
}) => {
  return (
    <div className="auth-page--container flex-container col justify-content--center align-items--center">
      <div className="">
        <div className="logo-container flex-container justify-content--start">
          <img src={switchAppLogo} alt="logo" className="switchapp-logo" />
        </div>

        <FormContainer>
          <form onSubmit={handleSubmit}>
            <div className="form-container flex-container col">
              <div className="form-container__header font-ctm--bold">
                <h3>
                  SwitchApp <span className="text-teal">ADMIN</span> Sign-in
                </h3>
              </div>

              <div className="form-container__body">
                <div className="form-container__body__input-container">
                  <TextInput
                    name="email"
                    label="Email"
                    type="email"
                    autoComplete="email"
                    register={register}
                    errors={errors}
                  />

                  <div className="password-box--container flex-container col">
                    <div className="align-self--end forgot-password--link">
                      <Link to={`/forgot-password?email=${formEmailValue}`}>
                        Forgot Password?
                      </Link>
                    </div>

                    <div className="flex-box">
                      <TextInput
                        name="password"
                        label="Password"
                        type="password"
                        register={register}
                        errors={errors}
                      />
                    </div>
                  </div>

                  <HookFormCheckbox
                    name="remember_me"
                    label="Stay signed in"
                    register={register}
                    errors={errors}
                  />
                </div>

                <div className="flex-container buttons-container">
                  <SubmitButton className="flex-box switchapp-themed">
                    Sign In
                  </SubmitButton>
                </div>
              </div>
            </div>

            {emailNotConfirmed ? (
              <div className="flex-container align-items--center mt-5">
                <span className="text-danger font-ctm--semibold">
                  Didn't get the email?
                </span>
                <SwitchAppButton
                  onClick={resendVerificationLink}
                  kind="success"
                  size="sm"
                  className="ml-5"
                >
                  Resend confirmation email
                </SwitchAppButton>
              </div>
            ) : null}
          </form>
        </FormContainer>
      </div>

      <SwitchAppLegalAndContactFooter
        // className="align-self--end"
        sections={[
          {
            linkComponent: "© SwitchApp",
            linkUrl: appConstants.appWebsiteUrl,
          },
          {
            linkComponent: "Contact",
            linkUrl: appConstants.appContactUrl,
          },
          {
            linkComponent: "Privacy & Terms",
            linkUrl: appConstants.appPrivacyUrl,
          },
        ]}
      />
    </div>
  );
};
