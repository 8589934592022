import { InfoIcon, SwitchAppIconButton } from "../../../../components";
import { baseChargeThresholdAction } from "../../../../enums";
import {
  HookFormSelect,
  HookFormToggle,
  TextInput,
} from "../../../../hook-form";
import { IManageSpecialPricingFormViewProps } from "./ManageSpecialPricing";

import refreshIcon from "../../../../assets/icons/refresh-3.png";
import { IEditGatewayPricingPerCountry } from "../../../../interfaces-and-types";
import { splitCamelCaseString } from "../../../../utils";

interface IEditPricingOptionFormProps
  extends Pick<
    IManageSpecialPricingFormViewProps,
    "formValues" | "setValue" | "errors" | "handleSubmit" | "register"
  > {
  pricingOptionType: "PAYMENT" | "TOP-UP" | "TRANSFER" | "PAYOUT";
  defaultPricingOptionKey: keyof IEditGatewayPricingPerCountry &
    (
      | "payment_default_pricing_option"
      | "payout_default_pricing_option"
      | "top_up_default_pricing_option"
      | "transfer_default_pricing_option"
    );
  isVolumePricingEnabledKey: keyof IEditGatewayPricingPerCountry &
    (
      | "is_payment_volume_pricing_enabled"
      | "is_payout_volume_pricing_enabled"
      | "is_top_up_volume_pricing_enabled"
      | "is_transfer_volume_pricing_enabled"
    );
}

export const EditPricingOptionForm = ({
  // props
  pricingOptionType,
  defaultPricingOptionKey,
  isVolumePricingEnabledKey,

  //  form mgmt
  formValues,
  setValue,

  errors,
  handleSubmit,
  register,
}: IEditPricingOptionFormProps) => {
  return (
    <div className="default-pricing--subsection">
      <h5 className="pricing-subsection--header font-ctm--semibold pt-5">
        <span>
          DEFAULT {pricingOptionType}S PRICING
          <InfoIcon
            message={
              <>
                This is the default pricing option that will be used for
                calculating charges for {pricingOptionType.toLowerCase()}{" "}
                transactions for this merchant.
                <br />
                <span className="text-danger">NOTE</span>: When volume pricing
                options are available, if the amount to charge is less than the
                minimum volume pricing option{" "}
                <span className="text-success">AMOUNT/COUNT</span>, the default
                pricing option is used as the fallback charging option.
              </>
            }
            align="bottom"
          />
        </span>

        <div className="close-button--container">
          <SwitchAppIconButton
            size="sm"
            kind="tertiary"
            className=""
            onClick={() => {}}
          >
            <img
              src={refreshIcon}
              alt="refresh icon"
              title="Refresh Data"
              className="refresh-icon"
            />
          </SwitchAppIconButton>
        </div>
      </h5>

      <div
        className={
          "pricing-subsection--body flex-container pb-5 justify-content--between align-items--end"
        }
      >
        <div className="flex-container col align-items--end mr-10 ">
          <HookFormToggle
            name={`${defaultPricingOptionKey}.is_flat_rate_enabled`}
            label="Use Flat Rate?"
            // labelOff="No"
            labelOff={<>No&nbsp;</>}
            labelOn="Yes"
            size="sm"
            containerClassName="settings-toggle"
            toggled={formValues[defaultPricingOptionKey].is_flat_rate_enabled}
            onToggle={() =>
              setValue(
                `${defaultPricingOptionKey}.is_flat_rate_enabled`,
                !formValues[defaultPricingOptionKey].is_flat_rate_enabled
              )
            }
            infoIconText={
              <>
                A flat-rate takes precedence over all other settings. Exact
                stated flat-rate amount is always charged as long as the pricing
                is active.
              </>
            }
            register={register}
            errors={errors}
          />

          <HookFormToggle
            name={`${defaultPricingOptionKey}.is_charge_cap_enabled`}
            label="Is Capped?"
            // labelOff="No"
            labelOff={<>No&nbsp;</>}
            labelOn="Yes"
            size="sm"
            containerClassName="settings-toggle"
            toggled={formValues[defaultPricingOptionKey].is_charge_cap_enabled}
            onToggle={() =>
              setValue(
                `${defaultPricingOptionKey}.is_charge_cap_enabled`,
                !formValues[defaultPricingOptionKey].is_charge_cap_enabled
              )
            }
            infoIconText={
              <>
                If enabled, will set this value as the max charge if the
                calculated value exceeds it.
              </>
            }
            disabled={formValues[defaultPricingOptionKey].is_flat_rate_enabled}
            register={register}
            errors={errors}
          />

          <HookFormToggle
            name={isVolumePricingEnabledKey}
            label="Enable Volume Pricing?"
            // labelOff="No"
            labelOff={<>No&nbsp;</>}
            labelOn="Yes"
            size="sm"
            containerClassName="settings-toggle"
            toggled={formValues[isVolumePricingEnabledKey]}
            onToggle={() =>
              setValue(
                isVolumePricingEnabledKey,
                !formValues[isVolumePricingEnabledKey]
              )
            }
            infoIconText={
              <>
                If enabled, will compare the transaction parameters to the list
                of volume pricing entries below (if any) to determine the
                pricing to apply
              </>
            }
            register={register}
            errors={errors}
          />
        </div>

        <div
          className={`flex-container pb-5 align-content--start align-items--end`}
        >
          <TextInput
            name={`${defaultPricingOptionKey}.flat_rate`}
            label="Flat Rate"
            infoIconText={
              <>
                A flat rate takes precedence over all other settings. It charges
                the exact amount, always; as long as the pricing is active based
                on volume pricing settings.
              </>
            }
            containerClassName="mr-5"
            type="text" // type="number"
            disabled={!formValues[defaultPricingOptionKey].is_flat_rate_enabled}
            register={register}
            errors={errors}
          />

          <TextInput
            name={`${defaultPricingOptionKey}.base_charge`}
            label="Base Charge"
            infoIconText={
              <>
                A base charge is the root amount before any percentages are
                added.
              </>
            }
            containerClassName="mr-5"
            type="text" // type="number"
            disabled={formValues[defaultPricingOptionKey].is_flat_rate_enabled}
            register={register}
            errors={errors}
          />

          <TextInput
            name={`${defaultPricingOptionKey}.base_charge_threshold_amount`}
            label={
              <>
                Base Charge <br /> Threshold Amount
              </>
            }
            infoIconText={
              <>
                If the amount to calculate pricing reaches this amount, an
                action is taken on the base charge eg apply base charge, remove
                base charge, etc
              </>
            }
            containerClassName="mr-5"
            type="text" // type="number"
            disabled={formValues[defaultPricingOptionKey].is_flat_rate_enabled}
            register={register}
            errors={errors}
          />

          <HookFormSelect
            name={`${defaultPricingOptionKey}.base_charge_threshold_action`}
            label={
              <>
                Action on Base Charge When <br /> Amont = Threshold Amount
              </>
            }
            infoIconText="This is the action that will be taken on the base charge once the amount to calculate pricing reaches the threshold amount."
            placeholder="Choose action"
            selectItems={Object.values(baseChargeThresholdAction).map(
              (val) => ({
                text: splitCamelCaseString(val),
                // ?.concat(
                //   val === baseChargeThresholdAction.noAction
                //     ? ""
                //     : " Than Threshold"
                // )
                value: val,
              })
            )}
            containerClassName="mr-5"
            disabled={formValues[defaultPricingOptionKey].is_flat_rate_enabled}
            register={register}
            errors={errors}
          />

          <TextInput
            name={`${defaultPricingOptionKey}.percentage_charge`}
            label="Percentage"
            infoIconText={
              <>
                A % that is added to the base charge. This has no effect if
                flat-rate is enabled.
              </>
            }
            containerClassName="mr-5"
            type="text" // type="number"
            disabled={formValues[defaultPricingOptionKey].is_flat_rate_enabled}
            register={register}
            errors={errors}
          />

          <TextInput
            name={`${defaultPricingOptionKey}.charge_cap`}
            label="Charge Cap"
            infoIconText={
              <>
                The max amount permissible as charge. This is effected as a
                ceiling amount after charges have been calculated ie if the
                charges exceeds this value, it is set to this value
              </>
            }
            containerClassName="mr-5"
            type="text" // type="number"
            disabled={
              formValues[defaultPricingOptionKey].is_flat_rate_enabled ||
              !formValues[defaultPricingOptionKey].is_charge_cap_enabled
            }
            register={register}
            errors={errors}
          />
        </div>
      </div>
    </div>
  );
};
