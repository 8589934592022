import moment from "moment";
import { ModalTopSection } from "../../../components/DetailsModal/ModalTopSection";
import { TopDownFlexedContainer } from "../../../components/DetailsModal/TopDownFlexedContainer";
import {
  DataLoadingView,
  ErrorOccuredView,
  ModalContainer,
  SectionSubheader,
} from "../../../components";
import { ModalSectionTitle } from "../../../components";
import { LeftRightFlexedContainer } from "../../../components";
import { CurrencyDisplay } from "../../../components";
import { Badge } from "../../../components";
import { ITopUpDetailsModalViewProps } from "./TopUpDetailsModal";
import { getPaymentStatusIndicator } from "../../../utils";
import { appConfig } from "../../../configs";
import { Link } from "react-router-dom";

export const View = (props: ITopUpDetailsModalViewProps) => {
  const statusIndicator = props.modalData
    ? getPaymentStatusIndicator(props.modalData)
    : null;

  return (
    <div className="payment-details-container">
      <ModalContainer
        isModalOpen={props.isModalOpen}
        closeModal={props.closeModal}
        className="payment-modal-container"
        modalHeader={
          !props.modalData ? (
            <SectionSubheader title="PAYMENT DETAILS" />
          ) : (
            <ModalTopSection
              header="TOP-UP DETAILS"
              subHeader={
                <div className="sub-header flex-container justify-content--start mt-5">
                  <div>
                    <CurrencyDisplay
                      currencyOrSymbol={props.modalData.currency}
                      amount={props.modalData.amount}
                    />
                  </div>

                  <Badge
                    text={statusIndicator!.statusText}
                    statusColor={statusIndicator!.statusType}
                  />
                </div>
              }
              copyText="Transaction ID"
              copyContent={props.modalData.id}
              closeModal={props.closeModal}
            />
          )
        }
      >
        {!props.modalData ? (
          <>
            {props.isLoading ? (
              <DataLoadingView message={"Loading transaction details"} />
            ) : props.isError || !props.modalData ? (
              <ErrorOccuredView
                message={
                  "An error occured while loading the transaction. Please retry..."
                }
              />
            ) : null}
          </>
        ) : (
          <>
            <div className="sections flex-container flex-break--small">
              <TopDownFlexedContainer
                key_id="Date"
                value={
                  <span className="date--oneline">
                    {moment(props.modalData.created_at).format(
                      appConfig.dateTimeFormat
                    )}
                  </span>
                }
              />
              {/* 
              <div className="flex-divider--vertical" />

              <TopDownFlexedContainer
                key_id="Merchant"
                value={props.modalData.merchant.merchant_name}
              /> */}

              <div className="flex-divider--vertical" />

              <TopDownFlexedContainer
                key_id="Payment summary"
                value={
                  <div className="flex-container row">
                    <p>gateway: </p>
                    <img
                      src={`${process.env.PUBLIC_URL}/gateway-logos/${props.modalData.gateway_code}.svg`}
                      alt="gateway logo"
                      className="gateway-image"
                      style={{
                        width: "50%",
                        marginLeft: "5px",
                      }}
                    />
                  </div>
                }
              />
            </div>

            <div>
              <ModalSectionTitle
                key_id="Payment Details"
                value={
                  <Link
                    to={`/payments?id=${props.modalData.id}`}
                    className="switchapp-link"
                  >
                    View all
                  </Link>
                }
              />

              <LeftRightFlexedContainer
                key_id="Amount"
                value={
                  <>
                    <CurrencyDisplay
                      currencyOrSymbol={props.modalData.currency}
                      amount={props.modalData.amount}
                      amountFontSize={16}
                      symbolFontSize={12}
                    />{" "}
                    ({props.modalData.currency})
                  </>
                }
              />
              <LeftRightFlexedContainer
                key_id="Status"
                value={props.modalData.status}
              />
              <LeftRightFlexedContainer
                key_id="Description"
                value={props.modalData.description}
              />
            </div>

            <div>
              <ModalSectionTitle key_id="Payment Method" value={""} />

              <LeftRightFlexedContainer
                key_id="Transaction ID"
                value={props.modalData.id}
              />
              <LeftRightFlexedContainer
                key_id="Payment Method"
                value="Gateway"
              />
              <LeftRightFlexedContainer
                key_id="Payment Gateway"
                value={
                  <img
                    src={`${process.env.PUBLIC_URL}/gateway-logos/${props.modalData.gateway_code}.svg`}
                    alt="gateway logo"
                    className="gateway-logo-full"
                  />
                }
              />
            </div>
          </>
        )}
      </ModalContainer>
    </div>
  );
};
