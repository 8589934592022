import { ReactNode } from "react";
import { InfoIcon } from "../../../components";

export interface ITableHeaderForPricingOption {
  renderAfter?: ReactNode;
  renderBefore?: ReactNode;
}

export const TableHeaderForPricingOption = (
  props: ITableHeaderForPricingOption
) => {
  return (
    <div className="cds--structured-list-row cds--structured-list-row--header-row">
      <div className="cds--structured-list-th font-ctm--semibold text--center">
        ##
      </div>

      {props.renderBefore}

      <div className="cds--structured-list-th text--right">
        Flat Rate
        <InfoIcon
          message={
            <>
              This is the flat-rate that will be used when this pricing option
              is active based on amount/count
            </>
          }
        />
      </div>

      <div className="cds--structured-list-th font-ctm--semibold text--right">
        % Charge
        <InfoIcon
          message={
            <>
              This is the base charge that will be used when this pricing option
              is active based on amount/count
            </>
          }
        />
      </div>

      <div className="cds--structured-list-th font-ctm--semibold text--right">
        Base Charge
        <InfoIcon
          message={
            <>
              This is the base charge that will be used when this pricing option
              is active based on amount/count
            </>
          }
        />
      </div>
      <div className="cds--structured-list-th font-ctm--semibold text--right">
        Base Charge <br /> Threshold Amt
        <InfoIcon
          message={
            <>
              This is the base-charge threshold amount within the limits of this
              volume pricing that will be used when this pricing option is
              active based on amount/count
            </>
          }
          align="left"
        />
      </div>

      <div className="cds--structured-list-th font-ctm--semibold text--right">
        Charge Cap
        <InfoIcon
          message={
            <>
              This is the maximum charge that will be applied when this pricing
              option is active based on amount/count
            </>
          }
          align="left"
        />
      </div>

      {props.renderAfter}
    </div>
  );
};
