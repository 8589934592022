import { View } from "./View";

export interface IToastNotificationProps {
  title: string;
  subtitle?: string;
  message: string;
  ariaLabel?: string;
  timeout?: number;
  statusIconDescription?: "notification" | "error" | "success";
  role?: "status" | "alert" | "log";
  kind?:
    | "success"
    | "warning"
    | "warning-alt"
    | "error"
    | "info"
    | "info-square";
  onClose?: () => void;
  onCloseButtonClick?: () => void;
}

export const ToastNotification = (props: IToastNotificationProps) => {
  const { ariaLabel = "notification" } = props;

  return <View ariaLabel={ariaLabel} {...props} />;
};
