import { Route, Routes } from "react-router-dom";
import {
  ActiveCurrencyProvider,
  ActiveTenantProvider,
  GeneralSearchProvider,
  RemoteDataProviders,
} from "../../contexts";
import { useAuth } from "../../hooks";

import { ProtectedRouter } from "../ProtectedRouter";
import { AuthLayout } from "./AuthLayout";
import { View } from "./View";

export const AppLayout = () => {
  const { is_logged_in } = useAuth();

  return (
    <ProtectedRouter
      isAuthenticated={is_logged_in}
      unprotectedRoutes={<Route path="*" element={<AuthLayout />} />}
      protectedRoutes={
        // NOTE: <RemoteDataProviders /> was added here b/c auth is required for accessing the data
        <Route
          path="*"
          element={
            <RemoteDataProviders>
              <ActiveTenantProvider>
                <ActiveCurrencyProvider>
                  <GeneralSearchProvider>
                    <Routes>
                      <Route path="*" element={<View />} />
                    </Routes>
                  </GeneralSearchProvider>
                </ActiveCurrencyProvider>
              </ActiveTenantProvider>
            </RemoteDataProviders>
          }
        />
      }
    />
  );
};
