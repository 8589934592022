import { ToastNotification as CarbonToastNotification } from "@carbon/react";

export const View = ({
  ariaLabel,
  kind = "info",
  message,
  role = "status",
  statusIconDescription = "notification",
  ...rest
}) => {
  return (
    <div className="toast-notification--container">
      <CarbonToastNotification
        {...rest}
        aria-label={ariaLabel}
        caption={message}
        statusIconDescription={statusIconDescription}
        role={role}
        kind={kind}
      />
    </div>
  );
};
