import { AxiosRequestConfig } from "axios";

import { adminApiBaseUrl } from "../constants";

export const axiosBaseConfig: AxiosRequestConfig = {
  // baseURL: apiBaseUrl, // NOTE: configured in react query
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Origin": "*",
  },

  // /* `timeout` specifies the number of milliseconds before the request times out.
  //  * If the request takes longer than `timeout`, the request will be aborted.
  // */
  timeout: 120000,

  // /* `auth` indicates that HTTP Basic auth should be used, and supplies credentials.
  //  * This will set an `Authorization` header, overwriting any existing
  //  * `Authorization` custom headers you have set using `headers`.
  // */
  // auth: {
  //   username: 'janedoe',
  //   password: 's00pers3cret'
  // },

  // /** `params` are the URL parameters to be sent with the request
  //  * Must be a plain object or a URLSearchParams object
  // */
  // params: {
  //   ID: 12345
  // },

  // /* `data` is the data to be sent as the request body
  //  * Only applicable for request methods 'PUT', 'POST', and 'PATCH'
  //  * When no `transformRequest` is set, must be of one of the following types:
  //  * - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
  //  * - Browser only: FormData, File, Blob
  //  * - Node only: Stream, Buffer
  // */
  // data: {
  //   firstName: 'Fred'
  // },

  // transformRequest: [function (data, headers) {
  //   // Do whatever you want to transform the data

  //   return data;
  // }],

  // /* `transformResponse` allows changes to the response data to be made before
  // // it is passed to then/catch
  // */
  // transformResponse: [function (data) {
  //   // Do whatever you want to transform the data

  //   return data;
  // }],

  // /* `paramsSerializer` is an optional function in charge of serializing `params`
  //  * (e.g. https://www.npmjs.com/package/qs, http://api.jquery.com/jquery.param/)
  // */
  // paramsSerializer: function(params) {
  //   return Qs.stringify(params, {arrayFormat: 'brackets'})
  // },

  // /* `withCredentials` indicates whether or not cross-site Access-Control requests
  //  * should be made using credentials
  //  */
  // withCredentials: false, // default

  // /* `responseType` indicates the type of data that the server will respond with
  //  * options are 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
  // */
  // responseType: 'json', // default

  // /* `xsrfCookieName` is the name of the cookie to use as a value for xsrf token */
  // xsrfCookieName: 'XSRF-TOKEN', // default

  // /* `xsrfHeaderName` is the name of the http header that carries the xsrf token value */
  // xsrfHeaderName: 'X-XSRF-TOKEN', // default

  // /* `onUploadProgress` allows handling of progress events for uploads */
  // onUploadProgress: function (progressEvent) {
  //   // Do whatever you want with the native progress event
  // },

  // /* `onDownloadProgress` allows handling of progress events for downloads */
  // onDownloadProgress: function (progressEvent) {
  //   // Do whatever you want with the native progress event
  // },

  // /* `maxContentLength` defines the max size of the http response content allowed */
  // maxContentLength: 2000,

  // /* `validateStatus` defines whether to resolve or reject the promise for a given
  //  * HTTP response status code. If `validateStatus` returns `true` (or is set to `null`
  //  * or `undefined`), the promise will be resolved; otherwise, the promise will be
  //  * rejected.
  // */
  // validateStatus: function (status) {
  //   return status >= 200 && status < 300; // default
  // },

  // /* `maxRedirects` defines the maximum number of redirects to follow in node.js.
  //  * If set to 0, no redirects will be followed.
  // */
  // maxRedirects: 5, // default

  /* `httpAgent` and `httpsAgent` define a custom agent to be used when performing http
   * and https requests, respectively, in node.js. This allows options to be added like
   * `keepAlive` that are not enabled by default.
   */
  // httpAgent: new https.Agent({
  //   keepAlive: true,
  //   rejectUnauthorized: false,
  // }),
  // httpsAgent: new https.Agent({
  //   // keepAlive: true,
  //   ca: fs.readFileSync("./SelfCertificateSSL"),
  // }),

  // /* 'proxy' defines the hostname and port of the proxy server
  //  * Use `false` to disable proxies, ignoring environment variables.
  //  * `auth` indicates that HTTP Basic auth should be used to connect to the proxy, and
  //  * supplies credentials.
  //  * This will set an `Proxy-Authorization` header, overwriting any existing
  //  * `Proxy-Authorization` custom headers you have set using `headers`.
  // */
  // proxy: {
  //   host: '127.0.0.1',
  //   port: 9000,
  //   auth: {
  //     username: 'mikeymike',
  //     password: 'rapunz3l'
  //   }
  // },

  // /* `cancelToken` specifies a cancel token that can be used to cancel the request
  //  * (see Cancellation section below for details)
  // */
  // cancelToken: new CancelToken(function (cancel) {
  // })
};
