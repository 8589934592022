import { InfoIcon } from "../../../components";
import { formatAsCurrency, formatNumberWithCommas } from "../../../utils";
import {
  ITableHeaderForPricingOption,
  TableHeaderForPricingOption,
} from "./TableHeaderForPricingOption";

export const TableHeaderForVolumePricingOption = (
  props: ITableHeaderForPricingOption
) => {
  return (
    <TableHeaderForPricingOption
      renderBefore={
        <>
          <div className="cds--structured-list-th text--right">
            Volume Pricing
            <br /> By Amount
            <InfoIcon
              message={
                <>
                  This will use the value of the amount being charged for to
                  compare to the list of volume pricing options of that type eg
                  paymentVolumePricing, transferVolumePricing, etc. If the
                  transaction is less than the minimum amount, then the pricing
                  will fallback to the default pricing option
                  <br />
                  <span className="text-danger">NOTE</span>: The biggest volume
                  threshold/min amount will take effect eg if amount to
                  calculate charge for is{" "}
                  <span className="text-success">
                    ${formatAsCurrency(30000)}
                  </span>{" "}
                  and 3 volume pricing options of{" "}
                  <span className="text-success">
                    ${formatAsCurrency(4000)}
                  </span>
                  ,{" "}
                  <span className="text-success">
                    ${formatAsCurrency(10000)}
                  </span>{" "}
                  and{" "}
                  <span className="text-success">
                    ${formatAsCurrency(25000)}
                  </span>{" "}
                  are availalbe, the volume pricing option with min amount of{" "}
                  <span className="text-success">
                    ${formatAsCurrency(25000)}
                  </span>{" "}
                  will be active for that pricing.
                </>
              }
            />
          </div>
          <div className="cds--structured-list-th text--right">
            Volume Pricing
            <br /> By Count
            <InfoIcon
              message={
                <>
                  This will use the total number of transactions of a particular
                  type eg payment, transfer, topup, etc to determine the
                  charges.
                  <br />
                  <span className="text-danger">NOTE</span>: The biggest
                  threshold count will take effect eg if{" "}
                  <span className="text-success">600</span> transactions of a
                  particular type have been done for the specified period and
                  there are 4 volume pricing by count options of{" "}
                  <span className="text-success">50</span>,{" "}
                  <span className="text-success">250</span>,{" "}
                  <span className="text-success">
                    {formatNumberWithCommas(1000)}
                  </span>{" "}
                  and{" "}
                  <span className="text-success">
                    {formatNumberWithCommas(25000)}
                  </span>{" "}
                  availalbe, the volume pricing option with min amount of{" "}
                  <span className="text-success">
                    {formatNumberWithCommas(250)}
                  </span>{" "}
                  will be active for that pricing.
                </>
              }
            />
          </div>
        </>
      }
      renderAfter={
        <div className="cds--structured-list-th text--left">
          {/* <span className="font-ctm--bold">Action</span> */}
        </div>
      }
    />
  );
};
