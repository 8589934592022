// import { refresh } from "@carbon/icons-react"

import {
  Badge,
  CurrencyDisplay,
  ErrorOccuredView,
  SkeletonLoader,
  SwitchAppIconButton,
} from "../../../components";
import refreshIcon from "../../../assets/icons/refresh-3.png";
import { SummaryPeriodSwitcher } from "../SummaryPeriodSwitcher";

import { IPayoutsSummaryViewProps } from "./PayoutsSummary";

export const View = ({
  activeCurrency,
  isLoadingSummary,
  summaryToDisplay,
  summaryPeriod,
  setSummaryPeriod,
}: IPayoutsSummaryViewProps) => {
  return (
    <div className="summary-section payouts-summary--container flex-box flex-container col">
      <div className="flex-container summary-section--header">
        <h5 className="font-ctm--semibold flex-box">Payouts Summary</h5>
        <div className="close-button--container">
          <SwitchAppIconButton
            size="sm"
            kind="tertiary"
            className=""
            onClick={() => {}}
          >
            <img
              src={refreshIcon}
              alt="refresh icon"
              title="Refresh Data"
              className="refresh-icon"
            />
          </SwitchAppIconButton>
        </div>
      </div>

      <SummaryPeriodSwitcher
        setSummaryPeriod={setSummaryPeriod}
        summaryPeriod={summaryPeriod}
      />

      <div className=" summary-section--body">
        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            Total Payouts
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {summaryToDisplay?.payout_count}
          </div>
        </div>

        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            Failed Payouts
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {summaryToDisplay?.failed_payouts_count}
            {summaryToDisplay && summaryToDisplay?.failed_payouts_count > 0 ? (
              <Badge
                statusColor="danger"
                className="ml-5"
                text="attention required"
              />
            ) : null}
          </div>
        </div>

        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            Completed Payouts
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {summaryToDisplay?.successful_payouts_count}
          </div>
        </div>

        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            Pending Payouts
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {!summaryToDisplay
              ? null
              : summaryToDisplay.payout_count -
                summaryToDisplay.failed_payouts_count -
                summaryToDisplay.successful_payouts_count}
          </div>
        </div>

        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            Volume
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {isLoadingSummary ? (
              <SkeletonLoader type="text" />
            ) : !summaryToDisplay ? (
              <ErrorOccuredView noPadding message="Error fetching data..." />
            ) : (
              <CurrencyDisplay
                amount={summaryToDisplay?.volume}
                currencyOrSymbol={activeCurrency}
              />
            )}
          </div>
        </div>

        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            SwitchApp Fees
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {isLoadingSummary ? (
              <SkeletonLoader type="text" />
            ) : !summaryToDisplay ? (
              <ErrorOccuredView noPadding message="Error fetching data..." />
            ) : (
              <CurrencyDisplay
                amount={summaryToDisplay?.total_payout_app_fees}
                currencyOrSymbol={activeCurrency}
              />
            )}
          </div>
        </div>

        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            Gateway Fees (Trsf)
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {isLoadingSummary ? (
              <SkeletonLoader type="text" />
            ) : !summaryToDisplay ? (
              <ErrorOccuredView noPadding message="Error fetching data..." />
            ) : (
              <CurrencyDisplay
                amount={summaryToDisplay?.total_gateway_payout_fees}
                currencyOrSymbol={activeCurrency}
              />
            )}
          </div>
        </div>

        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            Profit
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {isLoadingSummary ? (
              <SkeletonLoader type="text" />
            ) : !summaryToDisplay ? (
              <ErrorOccuredView noPadding message="Error fetching data..." />
            ) : (
              <CurrencyDisplay
                amount={summaryToDisplay?.profit}
                currencyOrSymbol={activeCurrency}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
