import { ReactNode } from "react";

import { View } from "./View";

interface ISwitchAppPopover {
  isOpen: boolean;
  children: ReactNode;
  className?: string;
  popoverContent: ReactNode;
  popoverContentClassName?: string;
  highContrast?: boolean;
}

export const SwitchAppPopover = (props: ISwitchAppPopover) => {
  return <View {...props} />;
};
