import Modal from "react-modal";

import { SwitchAppInlineLoadingIcon } from "../SwitchAppInlineLoadingIcon";
import { IActivityBlockerViewProps } from "./ActivityBlocker";

import switchappLogo from "../../assets/images/switchapp-icon.svg";

export const View = ({ isBlocked, messages }: IActivityBlockerViewProps) => {
  if (!isBlocked || !messages.length) return null;

  return (
    <Modal
      isOpen={isBlocked}
      className="activity-blocker--container"
      style={{
        overlay: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#0008",
        },
      }}
    >
      <div className="activity-blocker--container flex-container justify-content--center align-items--center">
        <div>
          <div className="flex-container justify-content--center align-items--center">
            <img
              src={switchappLogo}
              alt="SwitchApp logo"
              className="switchapp-logo--loading"
            />

            <SwitchAppInlineLoadingIcon />
          </div>

          <div className="flex-box mt-5 white-text">
            {messages.map((msg) => (
              <span className="activity-blocker--message">{msg}</span>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};
