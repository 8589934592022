import { useEffect } from "react";
import { IPageAndSearchMeta } from "../interfaces-and-types";
import { useApiPagedMeta } from "./useApiPagedMeta";

export function useApiPagedMetaSync(pageMeta: IPageAndSearchMeta | undefined) {
  const { setApiPageMeta } = useApiPagedMeta();

  useEffect(() => {
    pageMeta && setApiPageMeta(pageMeta);
  }, [
    pageMeta?.page,
    pageMeta?.page_size,
    pageMeta?.page_count,
    pageMeta?.item_count,
    pageMeta?.search,
  ]);
}
