import { useCopyToClipboard } from "../../hooks";
import { View } from "./View";

export interface ICopyButtonProps {
  className?: string;
  iconDescription?: string;
  feedbackInfo?: string;
  stringToCopy: string;
  copyIcon?: string;
}
export const CopyButton = (props: ICopyButtonProps) => {
  const { copyToClipboard, displayAlertText, alertTextDisplayed } =
    useCopyToClipboard(props.stringToCopy, props.feedbackInfo || "");

  return (
    <View
      {...props}
      isPopoverOpen={displayAlertText}
      handleCopy={copyToClipboard}
    />
  );
};
