import { Component, ReactNode } from "react";
// import { useNavigate } from "react-router-dom";
import { View } from "./View";

interface IErrorBoundaryState {
  hasError: boolean;
}

export interface IErrorBoundaryViewProps {
  reloadWindow: () => void;
}

export class ErrorBoundary extends Component<
  { children: ReactNode },
  IErrorBoundaryState
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true }); // Display fallback UI

    // You can also log the error to an error reporting service
    //   logErrorToService(error, info);
  }

  _reloadWindow = () => {
    window.location.reload();
  };

  render() {
    return this.state.hasError ? (
      <View reloadWindow={this._reloadWindow} />
    ) : (
      this.props.children
    );
  }
}
