import { Route, Routes, useParams } from "react-router-dom";

import {
  useAdminApiMutation,
  useAdminApiQueryWithParams,
  useReactRouterLink,
} from "../../../hooks";
import {
  IModalFormProps,
  IViewGatewayPricingPerCountry,
  IViewMerchant,
} from "../../../interfaces-and-types";

import { View } from "./View";
import { SpecialPricing } from "../SpecialPricing";
export interface IMerchantDetailsModalViewProps extends IModalFormProps {
  merchant: IViewMerchant | null;
  isLoadingAddSpecialPricing: boolean;
  handleEnableSpecialPricing: () => void;
}

export const MerchantDetailsModal = () => {
  const { location, navigateToRoutePreserveParams } = useReactRouterLink();

  const closeModal = () => {
    navigateToRoutePreserveParams(
      location.pathname.replace(`/${id}/details`, "")
    );
  };

  const { id } = useParams();

  const {
    isLoading,
    // isSuccess,
    isError,
    data: merchant,
  } = useAdminApiQueryWithParams<{ id: string }, IViewMerchant>(
    [
      "getMerchantById",
      {
        id: id!, // NOTE: used to enforce react query cache with correct queryKey
      },
    ],
    {
      enabled: !!id,
    },
    (urlTemplate) => urlTemplate.replace("id", id!)
  );

  const {
    // isSuccess: isSuccessAddSpecialPricing,
    isLoading: isLoadingAddSpecialPricing,
    mutate: addSpecialPricing,
  } = useAdminApiMutation<void, IViewGatewayPricingPerCountry>(
    "addSpecialMerchantPricing",
    ["getMerchantById", "getMerchants"],
    {
      /* axios config */
      params: {
        country: merchant?.country,
      },
    },
    (urlTemplate) => urlTemplate.replace("{merchant_id}", id!)
  ).usePostMutation;

  // call API and go to special pricing details if successful
  const handleEnableSpecialPricing = () => {
    addSpecialPricing();
  };

  return (
    <Routes>
      <Route
        index
        element={
          <View
            isLoadingModalData={isLoading}
            isErrorModalData={isError}
            isModalOpen={!!id}
            closeModal={closeModal}
            merchant={merchant || null}
            isLoadingAddSpecialPricing={isLoadingAddSpecialPricing}
            handleEnableSpecialPricing={handleEnableSpecialPricing}
          />
        }
      />

      <Route
        path="special-pricing/:pricing_id/*"
        element={<SpecialPricing merchant={merchant || null} />}
      />
    </Routes>
  );
};
