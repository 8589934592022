import { useQueryClient } from "@tanstack/react-query";

import { filterConstants } from "../../../constants";
import {
  useGetPageQueryParams,
  useAdminApiQueryWithParams,
  useReactRouterLink,
} from "../../../hooks";
import {
  IPageAndSearchRequest,
  IQueryStringNavLinks,
  ITableHeader,
  IViewCustomer,
  PagedMeta,
} from "../../../interfaces-and-types";
import { View } from "./View";

export interface ICustomerViewProps {
  data: IViewCustomer[];
  headers: ITableHeader[];
  isLoading: boolean;
  isError: boolean;
  totalCount: number;
  queryStringTabs: IQueryStringNavLinks;

  // modals
  openCreateNewModal: () => void;
  openDetailsModal: (data: any) => void;
  refreshTable: () => void;

  // extra buttons
  onExport: () => void;
}

export const Customers = () => {
  const queryClient = useQueryClient();
  // const query = useQueryString();
  // const status = query.get(filterConstants.statusKey);
  const { page, page_size, search } = useGetPageQueryParams();

  const { navigateToRoutePreserveParams } = useReactRouterLink();

  const {
    isLoading: isLoadingCustomers,
    isError: isErrorCustomers,
    // isSuccess: isSuccessCustomers,
    data: customers,
  } = useAdminApiQueryWithParams<
    IPageAndSearchRequest,
    IViewCustomer[],
    PagedMeta
  >([
    "getCustomers",
    {
      page: (page && Number(page)) || 1,
      page_size: (page_size && Number(page_size)) || 10,
      search,
      // status: status || "active",
    },
  ]);

  const headers: ITableHeader[] = [
    {
      key: "email",
      header: "Email",
    },
    {
      key: "full_name",
      header: "Name",
    },
    {
      key: "phone_number",
      header: "Phone No",
    },
    {
      key: "created_at",
      header: "Date",
    },
    {
      key: "more",
      header: "",
    },
  ];

  const queryStringTabs: IQueryStringNavLinks = {
    query: filterConstants.statusKey,
    options: [
      {
        key: "All",
        value: "",
      },
      // {
      //   key: "Blacklisted",
      //   value: "blacklisted",
      // },
    ],
  };

  const onExport = () => {
    // export data via api and download
    alert("exporting data...");
  };

  const refreshTable = () => {
    queryClient.invalidateQueries([
      "getCustomers",
      {
        page: (page && Number(page)) || 1,
        page_size: (page_size && Number(page_size)) || 10,
        search,
        // status: status || "active",
      },
    ]);
  };

  // insert the functions to open edit/delete modals
  const customersWithLinks = customers?.map((p) => ({
    ...p,
    openEditModal: () => navigateToRoutePreserveParams(`${p.id}/edit`),
    openDeleteModal: () => navigateToRoutePreserveParams(`${p.id}/delete`),
  }));

  const openDetailsModal = (id: any) =>
    navigateToRoutePreserveParams(`${id}/details`);

  const openCreateNewModal = () => {
    navigateToRoutePreserveParams(`create`);
  };

  return (
    <View
      data={customersWithLinks || []}
      headers={headers}
      isLoading={isLoadingCustomers}
      isError={isErrorCustomers}
      totalCount={customers?.length || 0}
      queryStringTabs={queryStringTabs}
      onExport={onExport}
      openCreateNewModal={openCreateNewModal}
      openDetailsModal={openDetailsModal}
      refreshTable={refreshTable}
    />
  );
};
