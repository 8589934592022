import { Tab, TabList, Tabs } from "@carbon/react";

// import { IQuickTableFilterTabsViewProps } from './QuickTableFilterTabs';

export const View = ({
  containerClassName = "",
  defaultSelectedIndex,
  selectedIndex,
  onChange,
  queryStringTabNavLinks,
  setQueryStringFilter,
  // tabListType,
}) => {
  return (
    <Tabs
      defaultSelectedIndex={defaultSelectedIndex}
      selectedIndex={selectedIndex}
      onChange={onChange}
    >
      <TabList
        activation="manual"
        // tabListType={tabListType}
        aria-label="List of tabs"
        className={`${containerClassName} quicktable-filter-tabs`}
      >
        {queryStringTabNavLinks.options.map((qst) => (
          <Tab
            key={qst.value}
            onClick={() =>
              setQueryStringFilter(queryStringTabNavLinks.query, qst.value)
            }
          >
            {qst.key}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
};
