import { useMemo, useState } from "react";

import {
  IActiveTenantContext,
  IPageAndSearchRequest,
  IViewMerchantList,
  PagedMeta,
} from "../interfaces-and-types";
import { ActiveTenantContext } from "./ActiveTenantContext";
import { useAdminApiQueryWithParams, useDebounce } from "../hooks";
import { useQueryClient } from "@tanstack/react-query";

export function ActiveTenantProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [activeTenant, setActiveTenantContext] =
    useState<IViewMerchantList | null>(null);
  const [filterTenantsSearchTerm, setFilterTenantsSearchTerm] = useState<
    string | null
  >(null);

  const debouncePeriodElapsed = useDebounce(100);

  const queryClient = useQueryClient();

  const {
    isSuccess,
    isError,
    isLoading,
    data,
    // meta: merchantsMeta,
  } = useAdminApiQueryWithParams<
    IPageAndSearchRequest,
    IViewMerchantList[],
    PagedMeta
  >(
    [
      "getMerchants",
      {
        page: 1,
        page_size: 10,
        search: filterTenantsSearchTerm,
      },
    ],
    {
      enabled:
        debouncePeriodElapsed &&
        !!filterTenantsSearchTerm &&
        filterTenantsSearchTerm.length > 2,
    }
  );

  const refreshRemoteData = () => {};

  const setActiveTenant = (tenant: IViewMerchantList) => {
    setActiveTenantContext(tenant);
    // clear all queries and refetch
    queryClient.clear();
    queryClient.invalidateQueries();
  };

  // const memoedActiveTenantContext = useMemo(() => {
  const unMemoedActiveTenantContext: IActiveTenantContext = {
    activeTenant,
    setActiveTenant,

    filterTenantsSearchTerm,
    setFilterTenantsSearchTerm,

    // remote context
    isSuccess,
    isError,
    isLoading,
    data: data || [],
    refreshRemoteData,
  };

  //   return unMemoedActiveTenantContext;
  // }, [activeTenant, isSuccess, isError, isLoading]);

  return (
    <ActiveTenantContext.Provider value={unMemoedActiveTenantContext}>
      {children}
    </ActiveTenantContext.Provider>
  );
}
