import { ReactNode } from "react";

import { IViewVolumePricingOption } from "../../../interfaces-and-types";
import { Badge, FeatureEnabledIcon } from "../../../components";
import { baseChargeThresholdAction } from "../../../enums";
import { formatAsCurrency, formatNumberWithCommas } from "../../../utils";
import { PricingOptionDisplay } from "./PricingOptionDisplay";

export const VolumePricingOptionDisplay = (props: {
  index?: number;
  volumePricingOption: IViewVolumePricingOption;
  renderAfter?: ReactNode;
}) => {
  const { volumePricingOption, ...rest } = props;

  return (
    <PricingOptionDisplay
      {...rest}
      pricingOption={props.volumePricingOption}
      renderBefore={
        <>
          <div className="cds--structured-list-td font-ctm--semibold text-primary">
            <div className="flex-container row justify-content--end">
              <span className="text--center mr-5">
                {formatAsCurrency(volumePricingOption.minimum_amount)}{" "}
              </span>
              <FeatureEnabledIcon
                isEnabled={
                  volumePricingOption.is_amount_based_threshold_enabled
                }
              />
            </div>
          </div>

          <div className="cds--structured-list-td font-ctm--semibold text-primary">
            <div className="flex-container row justify-content--end">
              <span className="text--center mr-5">
                {formatNumberWithCommas(volumePricingOption.minimum_count)}{" "}
              </span>
              <FeatureEnabledIcon
                isEnabled={volumePricingOption.is_count_based_threshold_enabled}
              />
            </div>
          </div>
        </>
      }
    />
  );
};
