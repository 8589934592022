import warningIcon from "../../assets/icons/warning.svg";
import { IErrorOccuredViewProps } from "./ErrorOccuredView";

export const View = (props: IErrorOccuredViewProps) => {
  return (
    <div
      className={`flex-box flex-container justify-content--center align-items--center ${
        props.className
      } ${props.noPadding ? "" : "pt-5 pb-5"}`}
    >
      <div className="mr-5">
        <img src={warningIcon} alt="warning icon" className="warning--icon" />
      </div>
      <span className="switchapp-small">{props.message}</span>
    </div>
  );
};
