import { Fragment } from "react";
import {
  Column,
  DataTable,
  DataTableSkeleton,
  Grid,
  Pagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableExpandHeader,
  TableHeader,
  TableBody,
  TableExpandRow,
  TableCell,
  TableExpandedRow,
  TableSelectAll,
  TableSelectRow,
  Theme,
} from "@carbon/react";

import { quickTableConfig } from "../../configs";
import { QuickTableToolbar } from "./QuickTableToolbar";
import { EmptyTableDisplay } from "./EmptyTableDisplay";
import { ErrorOccuredTableDisplay } from "./ErrorOccuredTableDisplay";

export const View = (props) => {
  const {
    // identity
    id,

    // title
    tableTitle = "",
    tableSubtitle = "",

    // data
    data,
    headers,
    isLoading,
    isError,
    itemCount,

    // styling
    tableClassName = "",

    // render details
    renderDetail,

    // pagination
    currentPageSize,
    availablePageSizes = quickTableConfig.availablePageSizes,
    setCurrentPage,
    setCurrentPageSize,
    setFirstRowIndex,
    openDetailsModal,
    detailsModalKeySelector,
    rowTitleSelector,

    disableClickOnColumns = [],
    disableClickOnTargetClassNames = [],
  } = props;

  const LoadingSkeleton =
    // memo(
    () => {
      return (
        <Grid className={`${tableClassName}`}>
          <Column lg={16} md={8} sm={4}>
            <DataTableSkeleton
              columnCount={headers.length + 1}
              rowCount={10}
              headers={[
                {
                  key: "TableSelectRow",
                  header: "",
                },
                ...headers,
              ]}
              showHeader={false}
              showToolbar={false}
            />
          </Column>
        </Grid>
      );
    };
  // );

  return (
    <div className="table-container">
      <div>
        <Theme theme="white">
          <DataTable
            rows={data}
            headers={headers}
            isSortable
            // overflowMenuOnHover
            render={({
              rows,
              headers,
              getHeaderProps,
              getRowProps,
              getSelectionProps,
              getTableProps,
            }) => {
              // perform actions before render

              return (
                <TableContainer
                  id={`${id}_container`}
                  title={tableTitle}
                  description={tableSubtitle}
                >
                  <QuickTableToolbar {...props} />

                  {isLoading ? (
                    <LoadingSkeleton />
                  ) : (
                    <Table id={id} {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          {renderDetail && <TableExpandHeader />}
                          <TableSelectAll {...getSelectionProps()} />
                          {headers.map((header) => (
                            <TableHeader {...getHeaderProps({ header })}>
                              {header.header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {itemCount === 0 ? (
                          <TableRow>
                            <TableCell
                              colSpan={headers.length + 2}
                              style={{ padding: 0 }}
                            >
                              {isError ? (
                                <ErrorOccuredTableDisplay />
                              ) : (
                                <EmptyTableDisplay />
                              )}
                            </TableCell>
                          </TableRow>
                        ) : (
                          rows.map((row) => {
                            const rowItemFromList = data.find(
                              (item) => item.id === row.id
                            );

                            return (
                              <Fragment key={row.id}>
                                {/* render table cells */}
                                {!renderDetail ? (
                                  <TableRow
                                    key={row.id}
                                    {...getRowProps({ row })}
                                  >
                                    <TableSelectRow
                                      {...getSelectionProps({ row })}
                                      onSelect={(evt) => {
                                        getSelectionProps({ row }).onSelect(
                                          evt
                                        );
                                      }}
                                    />
                                    {row.cells.map((cell) => {
                                      const cellIsExcludedOnClick =
                                        // rowIsExcludedOnClick ||
                                        disableClickOnColumns.find(
                                          (colName) =>
                                            colName === cell.info?.header
                                        ) !== undefined;

                                      return (
                                        <TableCell
                                          onClick={(e) => {
                                            console.log(
                                              "clicked on target with event",
                                              e
                                            );

                                            const targetIsExcludedOnClick =
                                              // rowIsExcludedOnClick ||
                                              disableClickOnTargetClassNames.find(
                                                (className) => {
                                                  // console.log(
                                                  //   "checking target",
                                                  //   className
                                                  // );

                                                  return (
                                                    e.target.classList.contains(
                                                      className
                                                    ) ||
                                                    e.target.parentNode.classList.contains(
                                                      className
                                                    )
                                                  );
                                                }
                                              ) !== undefined;

                                            !cellIsExcludedOnClick &&
                                              !targetIsExcludedOnClick &&
                                              openDetailsModal(
                                                detailsModalKeySelector
                                                  ? detailsModalKeySelector(
                                                      rowItemFromList
                                                    )
                                                  : row.id
                                              );
                                            // openDetailsModal(
                                            //   detailsModalKey : row.id // detailsModalKey computed by displayFormatter??
                                            // );
                                          }}
                                          key={cell.id}
                                          className={
                                            openDetailsModal &&
                                            !cellIsExcludedOnClick
                                              ? "clickable-cell"
                                              : ""
                                          }
                                          title={
                                            rowTitleSelector
                                              ? rowTitleSelector(
                                                  rowItemFromList
                                                )
                                              : null
                                          }
                                        >
                                          {cell.value}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                ) : (
                                  // with row detail expansion
                                  <TableExpandRow {...getRowProps({ row })}>
                                    <TableSelectRow
                                      {...getSelectionProps({ row })}
                                      onSelect={(evt) => {
                                        getSelectionProps({ row }).onSelect(
                                          evt
                                        );
                                      }}
                                    />

                                    {row.cells.map((cell) => (
                                      <TableCell key={cell.id}>
                                        {cell.value}
                                      </TableCell>
                                    ))}
                                  </TableExpandRow>
                                )}

                                {/* with master detail */}
                                {!renderDetail ? (
                                  <></>
                                ) : (
                                  <TableExpandedRow
                                    colSpan={headers.length + 2}
                                  >
                                    {renderDetail(row)}
                                  </TableExpandedRow>
                                )}
                              </Fragment>
                            );
                          })
                        )}
                      </TableBody>
                    </Table>
                  )}

                  <Pagination
                    totalItems={itemCount ? itemCount : rows.length}
                    backwardText="Previous"
                    forwardText="Next"
                    pageSize={currentPageSize}
                    pageSizes={availablePageSizes}
                    itemsPerPageText="Items per page"
                    onChange={({ page, pageSize }) => {
                      setCurrentPage(page);
                      setFirstRowIndex(pageSize * (page - 1));

                      if (pageSize !== currentPageSize) {
                        setCurrentPageSize(pageSize);
                      }
                    }}
                    size="lg"
                  />
                </TableContainer>
              );
            }}
          />
        </Theme>
      </div>
    </div>
  );
};
