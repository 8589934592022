import { IPageTabNavLink } from "../interfaces-and-types";

import linkColoredIcon from "../assets/icons/link-colored.svg";

interface IMainTabNavLink extends IPageTabNavLink {
  icon?: string;
  isShowMore: boolean;
}

export const mainNavLinks: IMainTabNavLink[] = [
  {
    name: "Home",
    route: "/",
    isShowMore: false,
  },
  {
    name: "Merchants",
    route: "/merchants",
    isShowMore: false,
  },
  {
    name: "Transactions",
    route: "/payments",
    isShowMore: false,
  },
  // {
  //   name: "Balances",
  //   route: "/balances",
  //   isShowMore: false,
  // },
  // {
  //   name: "Balances",
  //   route: "/balances",
  //   isShowMore: false,
  // },
  // {
  //   name: "Transfers",
  //   route: "/transfers",
  //   // icon: transactionIcon,
  //   isShowMore: false,
  // },
  // {
  //   name: "Feedback",
  //   route: "/feedback",
  //   // icon: feedbackIcon,
  //   isShowMore: false,
  // },
  // {
  //   name: "Settings",
  //   route: "/settings",
  //   // icon: transactionIcon,
  //   isShowMore: false,
  // },
];
