import { Checkbox as CarbonCheckbox } from "@carbon/react";

import { InfoIcon } from "../../components";

export const View = (props) => {
  const {
    control,
    errorMessage,
    containerClassName,
    infoIconText,
    register,
    ...rest
  } = props;

  return (
    <div className={`input-container ${containerClassName}`}>
      <CarbonCheckbox
        {...register(props.name)}
        // helperText={props.helperText}
        // placeholder={props.placeholder}
        {...rest}
        id={props.name}
        labelText={
          !props.label && !infoIconText ? (
            ""
          ) : (
            <div className="flex-container">
              <div className="flex-box">{props.label} </div>
              {infoIconText ? (
                <div className="ml-3">
                  <InfoIcon message={infoIconText} />{" "}
                </div>
              ) : null}
            </div>
          )
        }
        // invalid={!!errorMessage}
        // invalidText={errorMessage}
        className={`${props.className} switchapp-themed`}
      />
      {errorMessage ? (
        <div className="cds--form-requirement">{errorMessage}</div>
      ) : null}
    </div>
  );
};
