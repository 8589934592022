import moment from "moment";
import { ModalTopSection } from "../../../components/DetailsModal/ModalTopSection";
import {
  Badge,
  DataLoadingView,
  ErrorOccuredView,
  FeatureEnabledIcon,
  InfoIcon,
  ModalContainer,
  SectionSubheader,
  SwitchAppButton,
} from "../../../components";
import { ModalSectionTitle } from "../../../components";
import { LeftRightFlexedContainer } from "../../../components";
import { IMerchantDetailsModalViewProps } from "./MerchantDetailsModal";
import { StructuredList } from "../../../components";
import { appConfig } from "../../../configs";
import { truncateStringWithEllipsis } from "../../../utils";
import { Link } from "react-router-dom";
import { PricingOptionDisplay } from "../SpecialPricing/PricingOptionDisplay";
import { Fragment } from "react";
import { TableHeaderForPricingOption } from "../SpecialPricing/TableHeaderForPricingOption";
import { SubmitButton } from "../../../hook-form";

// const VolumePricingStatusIndicator = ({ status }: { status: boolean }) => (
//   <Badge
//     text={status ? "YES" : "NO"}
//     statusColor={status ? "success" : "danger"}
//     indicatorColor={status ? "success" : "danger"}
//   />
// );

export const View = (props: IMerchantDetailsModalViewProps) => {
  return (
    <div className="merchant-details--container">
      <ModalContainer
        isModalOpen={props.isModalOpen}
        closeModal={props.closeModal}
        className="payment-modal-container"
        modalHeader={
          !props.merchant ? (
            <SectionSubheader title="MERCHANT DETAILS" />
          ) : (
            <ModalTopSection
              header={`${truncateStringWithEllipsis(
                props.merchant.merchant_name,
                25
              )} (${props.merchant.id})`}
              subHeader={
                <div className="name-sub-header flex-container align-items--end justify-content--start">
                  {props.merchant.email}
                </div>
              }
              copyText="Merchant ID"
              copyContent={props.merchant.id}
              closeModal={props.closeModal}
            />
          )
        }
      >
        {!props.merchant ? (
          <>
            {props.isLoadingModalData ? (
              <DataLoadingView message={"Loading merchant details"} />
            ) : (
              <ErrorOccuredView
                message={
                  "An error occured while loading the merchant details. Please retry..."
                }
              />
            )}
          </>
        ) : (
          <>
            <>
              {/* <ModalSectionTitle key_id="Account Details" value="" /> */}

              <LeftRightFlexedContainer
                key_id="Merchant Name"
                value={props.merchant.merchant_name || "..."}
              />
              <LeftRightFlexedContainer
                key_id="Email"
                value={props.merchant.email}
              />
              <LeftRightFlexedContainer
                key_id="Date Added"
                value={moment(props.merchant.created_at).format(
                  appConfig.dateTimeFormat
                )}
              />
              {!props.merchant.phone_number ? null : (
                <LeftRightFlexedContainer
                  key_id="Phone Number"
                  value={props.merchant.phone_number}
                />
              )}
              {
                <LeftRightFlexedContainer
                  key_id="Special Pricing"
                  value={
                    props.merchant.is_special_merchant_pricing_enabled ? (
                      <Badge
                        text="Enabled"
                        statusColor="success"
                        hideIndicator
                      />
                    ) : (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          props.handleEnableSpecialPricing();
                        }}
                      >
                        <SubmitButton size="sm" showChildrenWithLoadingIcon>
                          Enable Special Pricing
                        </SubmitButton>

                        {/* <SwitchAppButton
                        onClick={props.handleEnableSpecialPricing}
                        size="sm"
                        disabled={props.isLoadingAddSpecialPricing}
                      >
                        Enable Special Pricing
                      </SwitchAppButton> */}
                      </form>
                    )
                  }
                />
              }
            </>

            {!props.merchant.is_special_merchant_pricing_enabled ? (
              <></>
            ) : (
              <>
                <ModalSectionTitle
                  key_id={
                    <>
                      <span className="text-primary mr-5">
                        SPECIAL MERCHANT PRICING
                      </span>
                      <small className="text-secondary switchapp-small">
                        [Click{" "}
                        <Badge
                          text="Details"
                          statusColor="dark"
                          hideIndicator
                        />{" "}
                        for a special pricing to view VOLUME PRICING details]
                      </small>
                    </>
                  }
                  value={null}
                />

                <StructuredList
                  headers={
                    <TableHeaderForPricingOption
                      renderBefore={
                        <>
                          <div className="cds--structured-list-th text--right">
                            Code
                            <InfoIcon
                              message={<>Country this pricing is active for</>}
                            />
                          </div>
                          <div className="cds--structured-list-th text--right">
                            Type
                            <InfoIcon
                              message={
                                <>
                                  Options are PAYMENT, TOP-UP, TRANSFER &amp;
                                  PAYOUT
                                </>
                              }
                            />
                          </div>
                          <div className="cds--structured-list-th text--right">
                            Kind
                            <InfoIcon
                              message={<>Default or Volume Pricing Option</>}
                            />
                          </div>
                          <div className="cds--structured-list-th text--center">
                            Vol <br /> Pricing?
                          </div>
                        </>
                      }
                    />
                  }
                  structuredListBodyClassName="text--right font-ctm--semibold"
                  rows={props.merchant.swapp_special_merchant_pricing.map(
                    (specialPricing, index) => (
                      <Fragment key={specialPricing.id}>
                        {/* Payment Default */}
                        <PricingOptionDisplay
                          index={index}
                          pricingOption={
                            specialPricing.payment_default_pricing_option
                          }
                          renderBefore={
                            <>
                              <div className="cds--structured-list-td font-ctm--semibold">
                                {specialPricing.country}
                              </div>

                              <div className="cds--structured-list-td font-ctm--semibold">
                                <Badge
                                  text="PAYMENT"
                                  statusColor="primary"
                                  hideIndicator
                                />
                              </div>

                              <div className="cds--structured-list-td font-ctm--semibold">
                                <Badge
                                  text="DEFAULT"
                                  statusColor="secondary"
                                  hideIndicator
                                />
                              </div>

                              <div className="cds--structured-list-td font-ctm--semibold">
                                <FeatureEnabledIcon
                                  isEnabled={
                                    specialPricing.is_payment_volume_pricing_enabled
                                  }
                                />
                              </div>
                            </>
                          }
                          renderAfter={
                            <div className="cds--structured-list-td font-ctm--semibold text-primary">
                              <div className="flex-container row">
                                <Link
                                  to={`special-pricing/${specialPricing.id}`}
                                  className="switchapp-themed  cds--btn cds--btn--sm cds--btn--secondary mr-5"
                                >
                                  Details
                                </Link>

                                <Link
                                  to={`special-pricing/${specialPricing.id}/manage`}
                                  className="switchapp-themed  cds--btn cds--btn--sm cds--btn--primary"
                                >
                                  Manage
                                </Link>
                              </div>
                            </div>
                          }
                        />
                        {/* {specialPricing?.payment_volume_pricing_options.map(
                      (volOption, index) => (
                        <VolumePricingOptionDisplay
                          index={index + 1}
                          volumePricingOption={volOption}
                        />
                      )
                    )} */}

                        {/* Top-Ups Default */}
                        <PricingOptionDisplay
                          pricingOption={
                            specialPricing.top_up_default_pricing_option
                          }
                          renderBefore={
                            <>
                              <div className="cds--structured-list-td font-ctm--semibold"></div>

                              <div className="cds--structured-list-td font-ctm--semibold">
                                <Badge
                                  text="TOP-UP"
                                  statusColor="notification"
                                  hideIndicator
                                />
                              </div>

                              <div className="cds--structured-list-td font-ctm--semibold">
                                <Badge
                                  text="DEFAULT"
                                  statusColor="secondary"
                                  hideIndicator
                                />
                              </div>

                              <div className="cds--structured-list-td font-ctm--semibold">
                                <FeatureEnabledIcon
                                  isEnabled={
                                    specialPricing.is_top_up_volume_pricing_enabled
                                  }
                                />
                              </div>
                            </>
                          }
                        />

                        {/* Transfers Default */}
                        <PricingOptionDisplay
                          pricingOption={
                            specialPricing.transfer_default_pricing_option
                          }
                          renderBefore={
                            <>
                              <div className="cds--structured-list-td font-ctm--semibold"></div>

                              <div className="cds--structured-list-td font-ctm--semibold">
                                <Badge
                                  text="TRANSFER"
                                  statusColor="success"
                                  hideIndicator
                                />
                              </div>

                              <div className="cds--structured-list-td font-ctm--semibold">
                                <Badge
                                  text="DEFAULT"
                                  statusColor="secondary"
                                  hideIndicator
                                />
                              </div>

                              <div className="cds--structured-list-td font-ctm--semibold">
                                <FeatureEnabledIcon
                                  isEnabled={
                                    specialPricing.is_transfer_volume_pricing_enabled
                                  }
                                  // tickColor="red"
                                />
                              </div>
                            </>
                          }
                        />

                        {/* Payouts Default */}
                        <PricingOptionDisplay
                          pricingOption={
                            specialPricing.payout_default_pricing_option
                          }
                          renderBefore={
                            <>
                              <div className="cds--structured-list-td font-ctm--semibold"></div>

                              <div className="cds--structured-list-td font-ctm--semibold">
                                <Badge
                                  text="PAYOUT"
                                  statusColor="danger"
                                  hideIndicator
                                />
                              </div>

                              <div className="cds--structured-list-td font-ctm--semibold">
                                <Badge
                                  text="DEFAULT"
                                  statusColor="secondary"
                                  hideIndicator
                                />
                              </div>

                              <div className="cds--structured-list-td font-ctm--semibold">
                                <FeatureEnabledIcon
                                  isEnabled={
                                    specialPricing.is_payout_volume_pricing_enabled
                                  }
                                />
                              </div>
                            </>
                          }
                        />
                      </Fragment>
                    )
                  )}
                />
              </>
            )}
          </>
        )}
      </ModalContainer>
    </div>
  );
};
