import { ReactNode, RefObject, useEffect, useRef, useState } from "react";
import { View } from "./View";
import { useClickOutside } from "../../hooks";

// interface IHeaderMenuItem<TData> {
//   title: string;
//   items: TData[];
//   onSelect: (item: TData) => void;
// }

export interface IHeaderMenuDropDownProps<TData> {
  // items: IHeaderMenuItem<TData>[];
  headerMenuDropdownButton: boolean;
  headerMenuDropdownContentItemClass: string;
  headerContainerClass: string;
  headerDropdownContentClass: string;
  // defaultSelectedIndex: number;

  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;

  // TODO: clean up this clutter
  renderInButton: React.ReactNode; // render inside the button that opens the drop dodwn
  renderNonListOnOpen: React.ReactNode; // item to render inside the dropdown when it is open if item not a list
  items: TData[];
  ignoreClickOutside?: boolean; // dropdown will not close when click is outside its boundary
  onSelect: (item: TData) => void;
  renderItem: (item: TData) => React.ReactNode;
  renderSelectedItem: (item: TData) => React.ReactNode;
}

export interface IHeaderMenuDropDownViewProps<TData>
  extends IHeaderMenuDropDownProps<TData> {
  // ref: RefObject<HTMLDivElement>;
  className?: string;
  open: boolean; // tracks initial state of useClickOUtside
  toggleOpen: () => void;

  closeOnClickOutside: () => void;

  renderBefore?: ReactNode;
  renderAfter?: ReactNode;
}

export function HeaderMenuDropDown<TData>(
  props: IHeaderMenuDropDownProps<TData>
) {
  const [openInternal, setOpenInternal] = useState(false);

  const open = props.open || openInternal;
  const setOpen = props.setOpen || setOpenInternal;

  const toggleOpen = () => setOpen((open) => !open);
  const closeOnClickOutside = () => setOpen(false);

  // useEffect(() => {
  //   return () => console.log("HeaderMenuDropDown unmounting...");
  // });

  // const ref = useRef<HTMLDivElement>(null);
  // useClickOutside(ref, open, closeOnClickOutside);

  return (
    <View
      {...props}
      // ref={ref}
      open={open}
      toggleOpen={toggleOpen}
      closeOnClickOutside={closeOnClickOutside}
    />
  );
}
