import { useState } from "react";

import { useAdminApiQueryWithParams } from "../../../hooks";
import {
  ICustomersSummary,
  ICustomersOverview,
} from "../../../interfaces-and-types";
import { TSummaryPeriod } from "../../../constants";
import { ISummarySectionProps, ISummarySectionViewProps } from "../Home";

import { View } from "./View";

export interface ICustomersSummaryViewProps
  extends ISummarySectionViewProps<ICustomersOverview> {}

export const CustomersSummary = ({ activeCurrency }: ISummarySectionProps) => {
  const {
    isLoading: isLoadingCustomersSummary,
    // isSuccess: isSuccessCustomersSummary,
    // isError: isErrorCustomersSummary,
    data: customersSummary,
  } = useAdminApiQueryWithParams<{ currency: string }, ICustomersSummary>(
    [
      "getCustomersSummary",
      {
        currency: activeCurrency!,
      },
    ],
    {
      enabled: !!activeCurrency,
    }
  );

  const isLoadingCustomPeriodSummary = false; // update after adding fetch logic for custom

  const [summaryPeriod, setSummaryPeriod] = useState<TSummaryPeriod>("today");

  const summaryToDisplay =
    summaryPeriod === "today"
      ? customersSummary?.today
      : summaryPeriod === "past week"
      ? customersSummary?.past_week
      : summaryPeriod === "past month"
      ? customersSummary?.past_month
      : // summaryPeriod === "custom" ? customPeriodCustomersSummary :
        customersSummary?.all_time;

  const isLoadingSummary =
    summaryPeriod === "custom"
      ? isLoadingCustomPeriodSummary
      : isLoadingCustomersSummary;

  return (
    <View
      activeCurrency={activeCurrency}
      isLoadingSummary={isLoadingSummary}
      summaryToDisplay={summaryToDisplay || null}
      summaryPeriod={summaryPeriod}
      setSummaryPeriod={setSummaryPeriod}
    />
  );
};
