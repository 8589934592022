import { InfoIcon } from "../InfoIcon";
import { ISectionSubheaderProps } from "./SectionSubheader";

export const View = ({
  title,
  subtitle,
  infoIconDescription,
  className,
}: ISectionSubheaderProps) => {
  return (
    <div className={`section-subheader--container ${className}`}>
      <h5 className="font-ctm--bold">
        {title}
        {infoIconDescription ? (
          <InfoIcon message={infoIconDescription} className="ml-3" />
        ) : null}
      </h5>
      {subtitle ? (
        <p>
          <span>{subtitle}</span>
        </p>
      ) : null}
    </div>
  );
};
