import { ReactNode } from "react";
import { View } from "./View";

export interface NoDataToSeeContainerProps {
  errorMessage: string;
}

export const NoDataToSeeContainer = (props: NoDataToSeeContainerProps) => {
  return <View {...props} />;
};
