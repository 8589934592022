import { Route, Routes } from "react-router-dom";
import { TopUps } from "../../pages";

export const BalancesLayout = () => {
  return (
    <Routes>
      <Route index element={<TopUps />} />
      <Route path="/top-ups/*" element={<TopUps />} />
      {/* <Route path="/payouts/*" element={<Payouts />} /> */}
    </Routes>
  );
};
