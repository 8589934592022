import { IViewMerchantList } from "../../interfaces-and-types";
import departmentIcon from "../../assets/icons/shop.svg";

export const DepartmentView = (props: IViewMerchantList) => {
  return (
    <div className="department-view--container flex-container no-wrap align-items--start">
      <img
        src={props.merchant_logo || departmentIcon}
        alt="logo"
        className="department-view--icon align-self--center"
      />
      <div className="dept-name--container flex-box flex-container col align-items--start">
        <span
          title={props.merchant_name}
          className="department-name font-ctm--bold"
        >
          {props.merchant_name}
        </span>
        <span className="department-id truncate-text--ellipsis font-ctm--light">
          {props.id}
        </span>
      </div>
    </div>
  );
};
