import { LinkIcon, LinkWithIcon, SectionHeader } from "../../components";

import linkColoredIcon from "../../assets/icons/link-colored.svg";
import cardSwipeIcon from "../../assets/icons/cardSwipe.svg";
import listPadIcon from "../../assets/icons/list-pad.svg";
import mailIcon from "../../assets/icons/mail.svg";
import recurringDarkIcon from "../../assets/icons/recurring-dark.svg";
import personDangerIcon from "../../assets/icons/person-danger.svg";
import houseBlueIcon from "../../assets/icons/house-blue.svg";
import pageLinkIcon from "../../assets/icons/page-link.svg";
import houseIcon from "../../assets/icons/house.svg";
import maskIcon from "../../assets/icons/mask.svg";
import documentTextIcon from "../../assets/icons/document-text.svg";
import peopleIcon from "../../assets/icons/people.svg";
import shieldTickIcon from "../../assets/icons/shield-tick.svg";

export const View = () => {
  return (
    <>
      <div className="flex-container flex-break--small settings-container">
        <div className="flex-box section-container">
          <SectionHeader
            title="Payment Settings"
            subtitle="Add payment methods, create and manage subscriptions and invoices."
          />

          <LinkWithIcon
            iconLeft={<LinkIcon mainIconSource={linkColoredIcon} iconLeft />}
            title="Checkout and Payment Links"
            subtitle="Configure payment links for your customers"
            containerClassName="settings-link"
            urlTarget="checkout-and-payment-links"
          />

          <LinkWithIcon
            iconLeft={<LinkIcon mainIconSource={cardSwipeIcon} iconLeft />}
            title="Payment methods"
            subtitle="Configure your gateways and payment options."
            containerClassName="settings-link"
            urlTarget="payment-methods"
          />

          <LinkWithIcon
            iconLeft={<LinkIcon mainIconSource={listPadIcon} iconLeft />}
            title="Invoice template"
            subtitle="Create an invoice template and send to your customers"
            containerClassName="settings-link"
            urlTarget="invoice-template"
          />

          <LinkWithIcon
            iconLeft={
              <LinkIcon
                mainIconSource={mailIcon}
                subscriptIconSource={recurringDarkIcon}
                iconLeft
              />
            }
            title="Subscription and emails"
            subtitle="Configure the notifications you and your team receive from us."
            containerClassName="settings-link"
            urlTarget="subscriptions-and-emails"
          />
        </div>

        <div className="flex-divider--vertical" />

        <div className="flex-box section-container">
          <SectionHeader
            title="Business Settings"
            subtitle="Adjust your account details, add banks account for payout and top-ups."
          />

          <LinkWithIcon
            iconLeft={<LinkIcon mainIconSource={personDangerIcon} iconLeft />}
            title="Account Details"
            subtitle="Details about the account ownership."
            containerClassName="settings-link"
            urlTarget="account-details"
          />

          <LinkWithIcon
            iconLeft={<LinkIcon mainIconSource={houseBlueIcon} iconLeft />}
            title="Business details"
            subtitle="Details about your business that your customers can see."
            containerClassName="settings-link"
            urlTarget="business-details"
          />

          {/* <LinkWithIcon
            iconLeft={
              <LinkIcon
                mainIconSource={personDangerIcon}
                subscriptIconSource={pageLinkIcon}
                iconLeft
              />
            }
            title="Linked external accounts"
            subtitle="Accounts from other software that you have connected to this one."
            containerClassName="settings-link"
            urlTarget="linked-accounts"
          /> */}

          <LinkWithIcon
            iconLeft={<LinkIcon mainIconSource={houseIcon} iconLeft />}
            title="Payouts, bank accounts and currencies"
            subtitle="Add withdrawal bank accounts for different currencies"
            containerClassName="settings-link"
            urlTarget="accounts-and-currencies"
          />

          <LinkWithIcon
            iconLeft={<LinkIcon mainIconSource={maskIcon} iconLeft />}
            title="Branding"
            subtitle="Customize the experience of your customers when using our application"
            containerClassName="settings-link"
            urlTarget="branding/reciept"
          />

          {/* <LinkWithIcon
            iconLeft={<LinkIcon mainIconSource={documentTextIcon} iconLeft />}
            title="Reporting and documents"
            subtitle="Configure the format of your report documents."
            containerClassName="settings-link"
            urlTarget="reporting"
          />

          <LinkWithIcon
            iconLeft={
              <LinkIcon
                mainIconSource={peopleIcon}
                subscriptIconSource={shieldTickIcon}
                iconLeft
              />
            }
            title="Team and security"
            subtitle="Manage your staff and team members"
            containerClassName="settings-link"
            urlTarget="team-members"
          /> */}
        </div>
      </div>
    </>
  );
};
