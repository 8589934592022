// import { refresh } from "@carbon/icons-react"

import {
  CurrencyDisplay,
  ErrorOccuredView,
  SkeletonLoader,
  SwitchAppIconButton,
} from "../../../components";
import { SummaryPeriodSwitcher } from "../SummaryPeriodSwitcher";
import { IPaymentsSummaryViewProps } from "./PaymentsSummary";

import refreshIcon from "../../../assets/icons/refresh-3.png";

export const View = ({
  activeCurrency,
  isLoadingSummary,
  summaryToDisplay,
  summaryPeriod,
  setSummaryPeriod,
}: IPaymentsSummaryViewProps) => {
  return (
    <div className="summary-section payments-summary--container flex-box flex-container col">
      <div className="flex-container summary-section--header">
        <h5 className="font-ctm--semibold flex-box">Payments Summary</h5>
        <div className="close-button--container">
          <SwitchAppIconButton
            size="sm"
            kind="tertiary"
            className=""
            onClick={() => {}}
          >
            <img
              src={refreshIcon}
              alt="refresh icon"
              title="Refresh Data"
              className="refresh-icon"
            />
          </SwitchAppIconButton>
        </div>
      </div>

      <SummaryPeriodSwitcher
        setSummaryPeriod={setSummaryPeriod}
        summaryPeriod={summaryPeriod}
      />

      <div className="flex-box summary-section--body">
        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            Initialized Txs
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {summaryToDisplay?.tx_count}
          </div>
        </div>

        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            Completed Txs
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {summaryToDisplay?.successful_tx_count}
          </div>
        </div>

        {/* <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            Failed Txs
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {summaryToDisplay?.failed}
          </div>
        </div> */}

        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            Abandoned Txs
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {summaryToDisplay?.abandoned_tx_count}
          </div>
        </div>

        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            Volume
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {isLoadingSummary ? (
              <SkeletonLoader type="text" />
            ) : !summaryToDisplay ? (
              <ErrorOccuredView noPadding message="Error fetching data..." />
            ) : (
              <CurrencyDisplay
                amount={summaryToDisplay?.volume}
                currencyOrSymbol={activeCurrency}
              />
            )}
          </div>
        </div>

        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            SwitchApp Fees
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {isLoadingSummary ? (
              <SkeletonLoader type="text" />
            ) : !summaryToDisplay ? (
              <ErrorOccuredView noPadding message="Error fetching data..." />
            ) : (
              <CurrencyDisplay
                amount={summaryToDisplay?.total_tx_app_fees}
                currencyOrSymbol={activeCurrency}
              />
            )}
          </div>
        </div>

        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            Gateway Fees
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {isLoadingSummary ? (
              <SkeletonLoader type="text" />
            ) : !summaryToDisplay ? (
              <ErrorOccuredView noPadding message="Error fetching data..." />
            ) : (
              <CurrencyDisplay
                amount={summaryToDisplay?.total_gateway_tx_fees}
                currencyOrSymbol={activeCurrency}
              />
            )}
          </div>
        </div>

        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            Payout Fees
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {isLoadingSummary ? (
              <SkeletonLoader type="text" />
            ) : !summaryToDisplay ? (
              <ErrorOccuredView noPadding message="Error fetching data..." />
            ) : (
              <CurrencyDisplay
                amount={summaryToDisplay?.payout_fees}
                currencyOrSymbol={activeCurrency}
              />
            )}
          </div>
        </div>

        <div className="summary-field flex-container mb-5">
          <div className="flex-box font-ctm--semibold text-secondary">
            Profit
          </div>
          <div className="flex-box flex-1_5 font-ctm--semibold">
            {isLoadingSummary ? (
              <SkeletonLoader type="text" />
            ) : !summaryToDisplay ? (
              <ErrorOccuredView noPadding message="Error fetching data..." />
            ) : (
              <CurrencyDisplay
                amount={summaryToDisplay?.profit}
                currencyOrSymbol={activeCurrency}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
