import { Route, Routes } from "react-router-dom";

import { InvitationCodes, Merchants } from "../../pages";

export const MerchantsLayout = () => {
  return (
    <Routes>
      <Route path="/*" element={<Merchants />} />
      <Route path="/invitation-codes/*" element={<InvitationCodes />} />
    </Routes>
  );
};
