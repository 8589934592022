import { useRehydrateEncryptedStorageData } from "../hooks";
import { AuthContext } from "./AuthContext";

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const memoedAuthContext = useRehydrateEncryptedStorageData();

  return (
    <AuthContext.Provider value={memoedAuthContext}>
      {children}
    </AuthContext.Provider>
  );
}
