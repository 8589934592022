import { IAccountSettingsApi } from "../../interfaces-and-types";

type TAccountSettingsUrls = {
  [key in keyof IAccountSettingsApi]: string;
};

const rootAccountSettingsUrl = "/account-settings";

export const accountSettingsUrls: TAccountSettingsUrls = {
  getAccountSettings: `${rootAccountSettingsUrl}`,
  switchAccountMode: `${rootAccountSettingsUrl}/switch-account-mode`,

  // developer settings
  getDeveloperSettings: `${rootAccountSettingsUrl}/developers`,
  saveDeveloperSettings: `${rootAccountSettingsUrl}/developers`,
  clearApiKeys: `${rootAccountSettingsUrl}/clear-api-keys`,
  generateApiKeys: `${rootAccountSettingsUrl}/generate-api-keys`,

  // settings pages
  saveAccountDetailsSettings: `${rootAccountSettingsUrl}/account-details`,
  saveBusinessDetailsSettings: `${rootAccountSettingsUrl}/business-details`,

  saveBrandingSettings: `${rootAccountSettingsUrl}/branding`,
  saveCheckoutAndPaymentLinksSettings: `${rootAccountSettingsUrl}/checkout-and-links`,
  saveInvoiceSettings: `${rootAccountSettingsUrl}/invoices`,
  toggleGatewaySetting: `${rootAccountSettingsUrl}/toggle-provider`,
  togglePaymentMethodSetting: `${rootAccountSettingsUrl}/toggle-payment-method`,
  saveSubscriptionAndEmailSettings: `${rootAccountSettingsUrl}/subscriptions-and-emails`,
  savePayoutSettings: `${rootAccountSettingsUrl}/payouts`,
};
