import { View } from "./View";

export interface ILinkUrlCopyDisplay {
  link: string;
  securityRequired?: boolean;
  wrapperClassName?: string;
}
export const LinkUrlCopyDisplay = (props: ILinkUrlCopyDisplay) => {
  return <View {...props} />;
};
