import { ISectionHeaderProps } from "./SectionHeader";

export const View = ({ title, subtitle, renderRight }: ISectionHeaderProps) => {
  return (
    <div className="section-header--container flex-container">
      <div className="flex-box flex-container col">
        <h3 className="font-ctm--bold">{title}</h3>
        <p>
          <span>{subtitle}</span>
        </p>
      </div>

      <div>{renderRight}</div>
    </div>
  );
};
