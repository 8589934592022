import { useEffect, useState } from "react";
import {
  Button,
  Column,
  Grid,
  // Select,
  // SelectItem,
  TableToolbar,
  // TableToolbarAction,
  TableToolbarContent,
  TableToolbarSearch,
  // TableToolbarMenu,
} from "@carbon/react";
import { Add } from "@carbon/react/icons";
import moment from "moment";
import { appConfig } from "../../configs";
import { filterConstants } from "../../constants";
import { useQueryString, useQueryStringFilter } from "../../hooks";

import { PeriodFilter, DatePopOver } from "../../components";

export const QuickTableToolbar = ({
  onToolbarSearchInputChange,
  defaultSearchValue,
  toolbarTabs,

  // extra buttons
  extraButtons,
  onExport,
  openCreateNewModal,
  createNewButtonText,
}) => {
  const query = useQueryString();
  const startDate = query.get(filterConstants.startDateKey);
  const endDate = query.get(filterConstants.endDateKey);

  const { seturlQueryStringFilter } = useQueryStringFilter([
    [
      filterConstants.startDateKey,
      moment(startDate).format(appConfig.dateFormat1),
    ],
    [filterConstants.endDateKey, moment(endDate).format(appConfig.dateFormat2)],
  ]);

  const [startChartPeriod, setStartChartPeriod] = useState(
    moment(startDate) ||
      moment().subtract(1, "month").format(appConfig.dateFormat1)
  );

  const [endChartPeriod, setEndChartPeriod] = useState(
    moment(endDate) || moment().format(appConfig.dateFormat1)
  );

  useEffect(() => {
    if (startChartPeriod !== null) {
      seturlQueryStringFilter(
        {
          [filterConstants.startDateKey]: startChartPeriod,
        },
        true
      );
    }
    if (endChartPeriod !== null) {
      seturlQueryStringFilter(
        {
          [filterConstants.endDateKey]: endChartPeriod,
        },
        true
      );
    }
  }, [startChartPeriod, endChartPeriod]);

  // useEffect to set the correct start and end chart periods when the tab is changed
  useEffect(() => {
    if (startDate !== null) {
      setStartChartPeriod(startDate);
      setEndChartPeriod(moment().format(appConfig.dateFormat1));
    }
  }, [startDate, endDate]);

  const setStartChartPeriodFromDatePicker = (
    startDateToSet //: string | number | Date
  ) => {
    const formattedDateToSet = moment(startDateToSet).format(
      appConfig.dateFormat1
    );
    setStartChartPeriod(formattedDateToSet);
  };

  const setEndChartPeriodFromDatePicker = (
    endDateToSet //: string | number | Date
  ) => {
    const formattedDateToSet = moment(endDateToSet).format(
      appConfig.dateFormat1
    );
    setEndChartPeriod(formattedDateToSet);
  };

  return (
    <Grid>
      <Column sm={0} md={8} lg={16}>
        <TableToolbar>
          <TableToolbarContent>
            {toolbarTabs && toolbarTabs}

            <TableToolbarSearch
              defaultExpanded
              defaultValue={defaultSearchValue}
              onChange={(evt) =>
                evt && onToolbarSearchInputChange(evt.target.value)
              }
              size="sm"
            />

            {/* <span className="filter-text align-self--end pl-5 pr-5">
              Period:
            </span>

            <div className="flex-container">
              <div className="flex-container justify-content--end align-items--end no-wrap">
                <div
                  className="quicktable-period-filter"
                  style={{ width: "90px" }}
                >
                  <PeriodFilter />
                </div>

                <div
                  className="quicktable-date-popover"
                  style={{ width: "200px" }}
                >
                  <DatePopOver
                    name="period-select-datepicker"
                    label=""
                    startDate={startChartPeriod}
                    endDate={endChartPeriod}
                    setStartDate={setStartChartPeriodFromDatePicker}
                    setEndDate={setEndChartPeriodFromDatePicker}
                    className="quicktable-datepicker"
                  />
                </div>
              </div>
            </div> */}

            {extraButtons && extraButtons}

            {!onExport ? (
              <></>
            ) : (
              <></>
              // <Button
              //   onClick={onExport}
              //   size="sm"
              //   kind="tertiary"
              //   className="switchapp-themed export-button"
              //   iconDescription="Export"
              // >
              //   <ArrowUpRight size={18} className="export-button--icon" />
              //   Export
              // </Button>
            )}

            {!openCreateNewModal ? (
              <></>
            ) : (
              <Button
                onClick={openCreateNewModal}
                size="sm"
                className="switchapp-themed create-new-button"
                iconDescription="Create a new item"
              >
                <Add size={18} />
                {createNewButtonText || "Create New"}
              </Button>
            )}
          </TableToolbarContent>
        </TableToolbar>
      </Column>
    </Grid>
  );
};
