import { IPageTabNavLink } from "../interfaces-and-types";

export const merchantsNavLinks: IPageTabNavLink[] = [
  {
    name: "Merchants",
    route: "",
  },
  {
    name: "Invitations",
    route: "invitation-codes",
  },
];
