import { Route, Routes } from "react-router-dom";

import { QuickTable, QuickTableFilterTabs } from "../../components";
import { topUpsDisplayFormatter } from "./topUpsDisplayFormatter";
import { ITopUpViewProps } from "./TopUps";
import { TopUpDetailsModal } from "./TopUpDetailsModal";

export const View = (props: ITopUpViewProps) => {
  return (
    <>
      <Routes>
        <Route
          index
          element={
            <QuickTable
              id="topUpsTable"
              data={props.data}
              headers={props.headers}
              isLoading={props.isLoading}
              isError={props.isError}
              itemCount={props.totalCount}
              // renderDetail={(item) => <TopUpListDetail tx={item} />}
              toolbarTabs={
                <QuickTableFilterTabs
                  queryStringTabNavLinks={props.queryStringTabs}
                />
              }
              displayFormatter={topUpsDisplayFormatter}
              onExport={props.onExport}
              openDetailsModal={props.openDetailsModal}
            />
          }
        />
        <Route path=":id/details" element={<TopUpDetailsModal />} />
      </Routes>
    </>
  );
};
