import moment from "moment";
import { ModalTopSection } from "../../../components/DetailsModal/ModalTopSection";
import { TopDownFlexedContainer } from "../../../components/DetailsModal/TopDownFlexedContainer";
import {
  CopyButton,
  DataLoadingView,
  ErrorOccuredView,
  ModalContainer,
  SectionSubheader,
} from "../../../components";
import { ModalSectionTitle } from "../../../components";
import { LeftRightFlexedContainer } from "../../../components";
import { CurrencyDisplay } from "../../../components";
import { NoDataToSeeContainer } from "../../../components";
import { Badge } from "../../../components";
import { IPaymentDetailsModalViewProps } from "./PaymentDetailsModal";
import {
  getPaymentStatusIndicator,
  truncateStringWithEllipsis,
} from "../../../utils";
import { appConfig } from "../../../configs";
import { Link } from "react-router-dom";

export const View = (props: IPaymentDetailsModalViewProps) => {
  const statusIndicator = props.modalData
    ? getPaymentStatusIndicator(props.modalData)
    : null;

  return (
    <div className="payment-details-container">
      <ModalContainer
        isModalOpen={props.isModalOpen}
        closeModal={props.closeModal}
        className="payment-modal-container"
        modalHeader={
          !props.modalData ? (
            <SectionSubheader title="PAYMENT DETAILS" />
          ) : (
            <ModalTopSection
              header="PAYMENT DETAILS"
              subHeader={
                <div className="sub-header flex-container justify-content--start mt-5">
                  <div>
                    <CurrencyDisplay
                      currencyOrSymbol={props.modalData.currency}
                      amount={props.modalData.amount}
                    />
                  </div>

                  <Badge
                    text={statusIndicator!.statusText}
                    statusColor={statusIndicator!.statusType}
                  />
                </div>
              }
              copyText="Transaction ID"
              copyContent={props.modalData.id}
              closeModal={props.closeModal}
            />
          )
        }
      >
        {!props.modalData ? (
          <>
            {props.isLoading ? (
              <DataLoadingView message={"Loading transaction details"} />
            ) : props.isError || !props.modalData ? (
              <ErrorOccuredView
                message={
                  "An error occured while loading the transaction. Please retry..."
                }
              />
            ) : null}
          </>
        ) : (
          <>
            <div className="sections flex-container flex-break--small">
              <TopDownFlexedContainer
                key_id="Date"
                value={
                  <span className="date--oneline">
                    {moment(props.modalData.created_at).format(
                      appConfig.dateTimeFormat
                    )}
                  </span>
                }
              />

              <div className="flex-divider--vertical" />

              <TopDownFlexedContainer
                key_id="Customer"
                value={props.modalData.customer.email}
              />

              <div className="flex-divider--vertical" />

              <TopDownFlexedContainer
                key_id="Payment summary"
                value={
                  <div className="flex-container row">
                    <p>gateway: </p>
                    {!props.modalData.gateway_code ? (
                      "-"
                    ) : (
                      <img
                        src={`${process.env.PUBLIC_URL}/gateway-logos/${props.modalData.gateway_code}.svg`}
                        alt="gateway logo"
                        className="gateway-image"
                        style={{
                          width: "50%",
                          marginLeft: "5px",
                        }}
                        title={props.modalData.gateway_code}
                      />
                    )}
                  </div>
                }
              />

              <div className="flex-divider--vertical" />

              <TopDownFlexedContainer key_id="Risk evaluation" value="##" />
            </div>

            <div>
              <ModalSectionTitle
                key_id="Payment Details"
                value={
                  <Link
                    to={`/payments?id=${props.modalData.id}`}
                    className="switchapp-link"
                  >
                    View all
                  </Link>
                }
              />

              <LeftRightFlexedContainer
                key_id="Transaction ID"
                value={
                  <div className="flex-container justify-content--start align-items--center">
                    <div className="transaction-id-text modal-header--title">
                      <span title={props.modalData.id}>
                        {truncateStringWithEllipsis(props.modalData.id, 25)}
                      </span>
                    </div>
                    <div className="copy-button--container ml-5">
                      <CopyButton
                        stringToCopy={props.modalData.id}
                        iconDescription="copy ID"
                        className="copy-btn"
                      />
                    </div>
                  </div>
                }
              />

              <LeftRightFlexedContainer
                key_id="Reference"
                value={
                  <div className="flex-container justify-content--start align-items--center">
                    <div className="transaction-id-text modal-header--title">
                      {props.modalData.tx_ref}
                    </div>
                    <div className="copy-button--container ml-5">
                      <CopyButton
                        stringToCopy={props.modalData.tx_ref}
                        iconDescription="copy reference"
                        className="copy-btn disable-show-details"
                      />
                    </div>
                  </div>
                }
              />

              <LeftRightFlexedContainer
                key_id="Amount"
                value={
                  <>
                    <CurrencyDisplay
                      currencyOrSymbol={props.modalData.currency}
                      amount={props.modalData.amount}
                      amountFontSize={16}
                      symbolFontSize={12}
                    />{" "}
                    ({props.modalData.currency})
                  </>
                }
              />

              {/* <LeftRightFlexedContainer
                key_id="Amount Paid"
                value={
                  <>
                    <CurrencyDisplay
                      currencyOrSymbol={props.modalData.currency}
                      amount={props.modalData.amount_paid}
                      amountFontSize={16}
                      symbolFontSize={12}
                    />{" "}
                    ({props.modalData.currency})
                  </>
                }
              /> */}

              <LeftRightFlexedContainer
                key_id="App Fee(s)"
                value={
                  <>
                    <CurrencyDisplay
                      currencyOrSymbol={props.modalData.currency}
                      amount={props.modalData.app_fee}
                      amountFontSize={16}
                      symbolFontSize={12}
                    />{" "}
                    ({props.modalData.currency})
                  </>
                }
              />

              <LeftRightFlexedContainer
                key_id="Amount Charged"
                value={
                  <>
                    <CurrencyDisplay
                      currencyOrSymbol={props.modalData.currency}
                      amount={props.modalData.charged_amount}
                      amountFontSize={16}
                      symbolFontSize={12}
                    />{" "}
                    ({props.modalData.currency})
                  </>
                }
              />

              <LeftRightFlexedContainer
                key_id="Status"
                value={
                  <Badge
                    text={statusIndicator!.statusText}
                    statusColor={statusIndicator!.statusType}
                  />
                }
              />
              <LeftRightFlexedContainer
                key_id="Description"
                value={props.modalData.description || "..."}
              />
              <LeftRightFlexedContainer
                key_id="Message"
                value={props.modalData.narration || "..."}
              />
            </div>

            <div>
              <ModalSectionTitle key_id="Payment Method" value={""} />

              <LeftRightFlexedContainer
                key_id="Payment Method"
                value={
                  <span className="font-ctm--bold">
                    {props.modalData.payment_type || "..."}
                  </span>
                }
              />
              <LeftRightFlexedContainer
                key_id="Payment Gateway"
                value={
                  !props.modalData.gateway_code ? (
                    "-"
                  ) : (
                    <img
                      src={`${process.env.PUBLIC_URL}/gateway-logos/${props.modalData.gateway_code}.svg`}
                      alt="gateway logo"
                      className="gateway-logo-full"
                      title={props.modalData.gateway_code}
                    />
                  )
                }
              />
            </div>

            <div>
              <ModalSectionTitle key_id="Customer Details" value={""} />

              <LeftRightFlexedContainer
                key_id="Customer name"
                value={props.modalData.customer.full_name || "(Anonymous)"}
              />
              <LeftRightFlexedContainer
                key_id="Customer email"
                value={
                  <div className="flex-container justify-content--start align-items--center no-wrap">
                    <div className="transaction-id-text modal-header--title">
                      <span title={props.modalData.customer.email}>
                        {props.modalData.customer.email}
                      </span>
                    </div>
                    <div className="copy-button--container ml-3">
                      <CopyButton
                        // name="copy-customer-button"
                        stringToCopy={props.modalData.customer.email}
                        iconDescription="copy customer email"
                        className="copy-btn"
                      />
                    </div>
                  </div>
                }
              />
              <LeftRightFlexedContainer
                key_id="Date added"
                value={
                  <span className="date--oneline">
                    {moment(props.modalData.created_at).format(
                      appConfig.dateTimeFormat
                    )}
                  </span>
                }
              />
            </div>

            {/* <div className="flex-container justify-content--end align-items--end btn-container">
              <button className="done--btn" onClick={props.closeModal}>
                Done
              </button>
            </div> */}
          </>
        )}
      </ModalContainer>
    </div>
  );
};
