import { IBreadCrumbElement, IPageTabNavLink } from "../interfaces-and-types";

export const settingsNavLinks: IPageTabNavLink[] = [
  {
    name: "Settings",
    route: "",
  },
];

export const settingsRoutes: IBreadCrumbElement[] = [
  {
    display: "Settings",
    relativeUrl: "/settings",
    children: [
      {
        display: "Payment Methods",
        relativeUrl: "/payment-methods",
      },
      {
        display: "Checkout & Payment Links",
        relativeUrl: "/checkout-and-payment-links",
      },
    ],
  },
  // {
  //   index: "",
  // paymentMethdos: "payment-methods"
  // }
];
