import { createContext } from "react";
import { IAuthState, IAuthContext } from "../interfaces-and-types";

export const loggedOutAuthState: IAuthState = {
  // authentication
  is_logged_in: false,
  token: null,
  session_id: null,

  // user profile
  user: null,
  user_settings: {
    show_balance: false,
    dark_mode_setting: "auto",
    hide_account_confirmation_nagger: false,
    allow_notifications: true,
    allow_newsletter_email_notifications: true,
    allow_newsletter_push_notifications: true,
  },
};

export const AuthContext = createContext<IAuthContext>({
  ...loggedOutAuthState,
  signIn: () => {},
  signOut: () => {},

  setAuthenticationState: () => {},
  setToken: () => {},
  setUser: () => {},
  setUserSettings: () => {},
});
