import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useReactRouterLink = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname, search } = location;

  const navigateToRoutePreserveParams = (
    relativeOrAbsolutePath: string,
    appendCurrentSearchParams: boolean = true,
    searchParams: string | null = null
  ) =>
    navigate({
      pathname: relativeOrAbsolutePath,
      search: `${appendCurrentSearchParams ? search : ""}${
        searchParams ? `?${searchParams}` : ""
      }`,
    });

  return {
    location,
    navigate,
    navigateToRoutePreserveParams: useMemo(
      () => navigateToRoutePreserveParams,
      [pathname, search]
    ),
  };
};
