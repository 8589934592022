import { memo, useMemo, useState } from "react";
import { Portal } from "../components";
import {
  IToastNotificationProps,
  ToastNotification,
} from "../components/ToastNotification/ToastNotification";

import { INotificationsContext } from "../interfaces-and-types";
import { NotificationsContext } from "./NotificationsContext";

export function NotificationsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [notification, setNotification] =
    useState<IToastNotificationProps | null>(null);

  const showNotification = (notification: IToastNotificationProps) =>
    setNotification(notification);

  const clearNotifications = () => {
    setNotification(null);
  };

  const memoedNotificationsContext = useMemo(() => {
    const unMemoedNotificationsContext: INotificationsContext = {
      notification,
      showNotification,
      clearNotifications,
    };

    return unMemoedNotificationsContext;
  }, [notification?.message]);

  const NotificationToDisplay = memo(
    ({ notification }: { notification: IToastNotificationProps }) => (
      <ToastNotification {...notification} onClose={clearNotifications} />
    )
  );

  // const DummyToastNotification = () => (
  //   <ToastNotification
  //     title="DUMMY"
  //     message="This is a dummy notification to try and set zIndex of Toast Notification"
  //   />
  // );

  return (
    <NotificationsContext.Provider value={memoedNotificationsContext}>
      {/* <NotificationsContext.Provider value={unMemoedNotificationsContext}> */}
      {children}

      <Portal
        portalRoot="notifications--root"
        className="notifications-container"
      >
        {/* <DummyToastNotification /> */}
        {!notification ? null : (
          <NotificationToDisplay notification={notification} />
        )}
      </Portal>
    </NotificationsContext.Provider>
  );
}
