import { View } from "./View";
import { IViewTopUp } from "../../../interfaces-and-types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAdminApiQuery, useAdminApiQueryWithParams } from "../../../hooks";

export interface ITopUpDetailsModalViewProps {
  isLoading: boolean;
  isError: boolean;
  isModalOpen: boolean;
  closeModal: () => void;
  modalData: IViewTopUp | null;
}

export const TopUpDetailsModal = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const closeModal = () => {
    // simply go back if already on the payments page
    // if (location.pathname.indexOf("payments") >= -1) navigate(-1);
    // else navigate("/payments");

    navigate(-1);
  };

  const { id } = useParams();

  const {
    isLoading,
    // isSuccess,
    isError,
    data: transaction,
  } = useAdminApiQueryWithParams<{ id: string }, IViewTopUp>(
    [
      "getTopUpById",
      {
        id: id!, // NOTE: use this to ensure that react query caches with the correct queryKey
      },
    ],
    {
      enabled: !!id,
    },
    (urlTemplate) => urlTemplate.replace("id", id!)
  );

  return (
    <View
      isLoading={isLoading}
      isError={isError}
      isModalOpen={!!id}
      closeModal={closeModal}
      modalData={transaction || null}
    />
  );
};
