import { ReactNode } from "react";
import { View } from "./View";

export interface ISectionHeaderProps {
  title: ReactNode;
  subtitle?: ReactNode;
  renderRight?: ReactNode;
}

export const SectionHeader = (props: ISectionHeaderProps) => {
  return <View {...props} />;
};
