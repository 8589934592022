import { ReactNode } from "react";

import { IViewPricingOption } from "../../../interfaces-and-types";
import { FeatureEnabledIcon, Badge } from "../../../components";
import { baseChargeThresholdAction } from "../../../enums";
import {
  formatAsCurrency,
  formatNumberWithCommas,
  splitCamelCaseString,
} from "../../../utils";

export const PricingOptionDisplay = (props: {
  index?: number;
  renderBefore?: ReactNode;
  renderAfter?: ReactNode;
  pricingOption: IViewPricingOption;
}) => {
  const { index, renderBefore, renderAfter, pricingOption } = props;

  const hasNoBaseChargeAction =
    pricingOption.base_charge_threshold_action ===
    baseChargeThresholdAction.noAction;

  return (
    <div
      className="cds--structured-list-row cds--structured-list-row--header-row volume-pricing--row"
      key={pricingOption.id}
    >
      <div className="cds--structured-list-td font-ctm--semibold text--center">
        {index === undefined ? null : index + 1}
      </div>

      {renderBefore}

      <div className="cds--structured-list-td font-ctm--semibold text-danger">
        <div className="flex-container row justify-content--end">
          <span className="text--center mr-5">
            {formatAsCurrency(pricingOption.flat_rate)}{" "}
          </span>
          <FeatureEnabledIcon isEnabled={pricingOption.is_flat_rate_enabled} />
        </div>
      </div>

      <div className="cds--structured-list-td font-ctm--semibold">
        <div className="flex-container row justify-content--end">
          <span className="text--center mr-5">
            {formatNumberWithCommas(pricingOption.percentage_charge)}{" "}
          </span>
          <FeatureEnabledIcon isEnabled={!!pricingOption.percentage_charge} />
        </div>
      </div>

      <div className="cds--structured-list-td font-ctm--semibold text-teal">
        <div className="flex-container row justify-content--end">
          <span className="text--center mr-5">
            {formatAsCurrency(pricingOption.base_charge)}{" "}
          </span>
          <FeatureEnabledIcon isEnabled={!!pricingOption.base_charge} />
        </div>
      </div>

      <div
        className={`cds--structured-list-td font-ctm--semibold ${
          hasNoBaseChargeAction ? "" : "text-xxxx"
        }`}
      >
        <div className="">
          {formatAsCurrency(pricingOption.base_charge_threshold_amount)} <br />
          <Badge
            text={
              splitCamelCaseString(
                pricingOption.base_charge_threshold_action
              )?.replace("Charge ", "") || ""
            }
            statusColor={hasNoBaseChargeAction ? "secondary" : "success"}
            hideIndicator
          />
        </div>
      </div>

      <div className="cds--structured-list-td font-ctm--bold">
        <div className="flex-container row justify-content--end">
          <span className="text--center mr-5">
            {formatNumberWithCommas(pricingOption.charge_cap)}{" "}
          </span>
          <FeatureEnabledIcon isEnabled={pricingOption.is_charge_cap_enabled} />
        </div>
      </div>

      {renderAfter}
    </div>
  );
};
