import { useMemo, useState } from "react";

import { IActivityBlockerContext } from "../interfaces-and-types";
import { ActivityBlockerContext } from "./ActivityBlockerContext";

export function ActivityBlockerProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isBlockedCount, setIsBlockedCount] = useState(0);
  const [messages, setMessages] = useState<string[]>([]);

  const setBlocked = (message?: string) => {
    message && setMessages([...messages, message]);

    setIsBlockedCount((currentBlockCount) => currentBlockCount + 1);
  };

  const clearBlocked = (clearMessage?: boolean, messageToClear?: string) => {
    setTimeout(() => {
      setIsBlockedCount((currentBlockCount) =>
        currentBlockCount ? currentBlockCount - 1 : 0
      );

      clearMessage &&
        messageToClear &&
        setMessages(messages.filter((msg) => msg !== messageToClear));
    }, 500);
  };

  const memoedActivityBlockerContext = useMemo(() => {
    const unMemoedActivityBlockerContext: IActivityBlockerContext = {
      isBlocked: isBlockedCount > 0,
      messages,
      setBlocked,
      clearBlocked,
    };

    return unMemoedActivityBlockerContext;
  }, [isBlockedCount]);

  return (
    <ActivityBlockerContext.Provider value={memoedActivityBlockerContext}>
      {children}
    </ActivityBlockerContext.Provider>
  );
}
