import { useState } from "react";
import { useActiveCurrency, useActiveTenant, useAuth } from "../../hooks";
import { View } from "./View";
import { useNavigate } from "react-router-dom";

export const AppHeader = () => {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const navigateToProfile = () => navigate("/settings");

  const {
    isLoading: isLoadingSupportedCurrencies,
    isError: isErrorSupportedCurrencies,
    data: supportedCurrencies,
    setActiveCurrency,
    activeCurrency,
  } = useActiveCurrency();

  const {
    isLoading: isLoadingMerchants,
    isError: isErrorMerchants,
    data: merchants,
    activeTenant,
    setActiveTenant,
    filterTenantsSearchTerm,
    setFilterTenantsSearchTerm,
  } = useActiveTenant();

  const [openTenantsDropDown, setOpenTenantsDropDown] = useState(false);

  return (
    <View
      signOut={signOut}
      navigateToProfile={navigateToProfile}
      // currencies
      isLoadingSupportedCurrencies={isLoadingSupportedCurrencies}
      isErrorSupportedCurrencies={isErrorSupportedCurrencies}
      supportedCurrencies={supportedCurrencies || []}
      setActiveCurrency={setActiveCurrency}
      activeCurrency={activeCurrency}
      //={
      //}selected merchant account
      isLoadingMerchants={isLoadingMerchants}
      isErrorMerchants={isErrorMerchants}
      merchants={merchants || []}
      activeTenant={activeTenant}
      setActiveTenant={setActiveTenant}
      filterTenantsSearchTerm={filterTenantsSearchTerm}
      setFilterTenantsSearchTerm={setFilterTenantsSearchTerm}
      openTenantsDropDown={openTenantsDropDown}
      setOpenTenantsDropDown={setOpenTenantsDropDown}
    />
  );
};
