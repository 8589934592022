import {
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  Path,
  UseFormRegister,
} from "react-hook-form";
import { ISelectProps } from "../../components/Select/Select";
import { useApiErrors } from "../../hooks";
import {
  IKeyValuePair,
  ISelectGroupedItemsProps,
  ISelectOptionProps,
  TCarbonComponentSizeProps,
} from "../../interfaces-and-types";
import { getApiErrorsByFieldName } from "../../utils";

import { View } from "./View";

export interface IHookFormSelectProps<TFormValues extends FieldValues>
  extends ISelectProps {
  // name: string;
  // arrayFieldName?: string;
  // arrayFieldIndex?: string;
  // label: string;
  // helperText?: string;
  // infoIconText?: string;
  // className?: string;
  // disabled?: boolean;
  // size?: TCarbonComponentSizeProps;
  // defaultValue?: string;
  // showSkeleton?: boolean;
  // hideSkeletonLabel?: boolean;
  // selectItems: ISelectOptionProps[];
  // selectGroupedItems?: Array<ISelectGroupedItemsProps>;
  // placeholder?: string;
  register: UseFormRegister<TFormValues>;
  errors: FieldErrorsImpl<DeepRequired<TFormValues>>;
}

export function HookFormSelect<TFormValues extends FieldValues>(
  props: IHookFormSelectProps<TFormValues>
) {
  const { arrayFieldName, arrayFieldIndex, name, errors, ...rest } = props;

  const { apiErrors } = useApiErrors();

  const controlName =
    arrayFieldName && arrayFieldIndex
      ? `${arrayFieldName}.${arrayFieldIndex}.${name}`
      : name;

  const apiErrorMessage =
    // arrayFieldName && arrayFieldIndex
    //   ? getApiErrorsByArrayFieldName(props.name, apiErrors) :
    getApiErrorsByFieldName(props.name, apiErrors);

  // const hookFormErrorMessage =
  //   arrayFieldName && arrayFieldIndex
  //     ? errors[arrayFieldName] &&
  //       (errors[`${arrayFieldName}`] as any)?.[arrayFieldIndex]?.email?.message
  //     : errors[props.name] && errors[`${props.name}`]?.["message"];

  const hookFormErrorMessage =
    arrayFieldName && arrayFieldIndex
      ? errors[arrayFieldName] &&
        (errors[`${arrayFieldName}`] as any)[arrayFieldIndex]?.email?.message
      : errors[props.name] && errors[`${props.name}`]?.["message"];

  // console.log("form errors in Select: ", errors);
  // arrayFieldName &&
  //   arrayFieldIndex &&
  //   console.log(
  //     "field error messages: ",
  //     (errors[`${arrayFieldName}`] as any)[arrayFieldIndex]?.email?.message
  //   );

  const combinedErrors = hookFormErrorMessage || apiErrorMessage || "";

  const hookFormProps = props.register(controlName as Path<TFormValues>);
  const { onChange: onChangeHookForm, ...restHookFormProps } = hookFormProps;

  // const onChange = (e: any) => {
  //   onChange && onChange(e);
  //   onChangeHookForm(e);
  // };

  return <View name={controlName} errorMessage={combinedErrors} {...rest} />;
}
